import {NavigateFunction} from "react-router-dom";
import {getUserTokenOrRedirect} from "./auth.service";
import {ExternalCompanyDto} from "../dtos/company.dto";

export async function getCompany(navigate: NavigateFunction): Promise<ExternalCompanyDto> {
  const token = await getUserTokenOrRedirect(navigate);
  const company = await fetch(
    `${process.env.REACT_APP_ACCUSITE_API_BASE_URL}/api/v1/companies/me`,
    {headers: {Authorization: `Bearer ${token}`}})
    .then(async response => {
      if(!response.ok) {
        throw new Error(`Failed to fetch company: ${response.statusText}`);
      }
      return await response.json() as ExternalCompanyDto
    });
  return company;
}

export async function getCustomerFacingCompany(companyId: number): Promise<ExternalCompanyDto> {
  const company = await fetch(
    `${process.env.REACT_APP_ACCUSITE_API_BASE_URL}/api/v1/companies/external/${companyId}`)
    .then(async response => {
      if (!response.ok) {
        throw new Error(`Failed to fetch company: ${response.statusText}`);
      }
      return await response.json() as ExternalCompanyDto
    });
  return company;
}