import React, { useEffect } from 'react';
import {logInfo} from "../../shared/services/logger.service";

const LogoutPage = () => {
  useEffect(() => {
    logInfo('Logging out');
    localStorage.removeItem('idToken');
  }, []);

  return (
    <div>
      Successfully logged out
    </div>
  );
};

export default LogoutPage;