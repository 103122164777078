import React, {useEffect, useState} from 'react';
import {
  Box, Button,
  LinearProgress,
  Paper,
} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {EstimateWithClientAndCostDto} from "../../shared/dtos/estimate.dto";
import {requireAuth} from "../../shared/services/auth.service";
import {enqueueSnackbar} from "notistack";
import { datadogLogs } from '@datadog/browser-logs'
import {logError, logInfo} from "../../shared/services/logger.service";
import {GridColDef, GridRowParams} from '@mui/x-data-grid';
import {useToolbar} from "../../components/ToolbarContext";
import {UrlFilterAwareDataGrid} from "../../shared/components/UrlFilterAwareDataGrid";
import {createInvoiceAndSend, getWorkOrders} from "../work-order/work-order.service";

function CreateNewInvoiceListPage() {
  const navigate = useNavigate();
  const toolbarContext = useToolbar();

  const [completedWordOrders, setCompletedWordOrders] = useState<EstimateWithClientAndCostDto[]>([]);
  const [completedWorkOrdersAreLoading, setCompletedWorkOrdersAreLoading] = useState(false);
  const [selectedWorkOrderIds, setSelectedWorkOrderIds] = useState<number[]>([]);

  useEffect(() => {
    toolbarContext.initToolbar('CreateNewInvoiceListPage', 'Create New Invoices');
    requireAuth(navigate)
      .then(() => {
        logInfo('User navigated to create new invoices');
        return fetchCompletedWorkOrders();
      })
      .catch(e => {
        logError('Error requiring auth', {}, e);
        enqueueSnackbar('Error requiring auth', {variant: 'error', autoHideDuration: 5000});
      })
  }, []);

  async function fetchCompletedWorkOrders() {
    setCompletedWorkOrdersAreLoading(true);
    await getWorkOrders(navigate, "completed")
      .then(workOrders => setCompletedWordOrders(workOrders))
      .catch(e => {
        logError('Error fetching invoices', {}, e)
        enqueueSnackbar(`Error fetching invoices: ${e}`, {variant: 'error', autoHideDuration: 5000});
      })
      .finally(() =>
      {
        setCompletedWorkOrdersAreLoading(false)
      });
  }

  const handleSelectedRowsChange = (selectedRowIds: number[]) => {
    setSelectedWorkOrderIds(selectedRowIds);
  }

  const sendSelectedEnabled = () => {
    return selectedWorkOrderIds.length > 0;
  }

  const handleSendSelectedClick = async () => {
    const promises = selectedWorkOrderIds.map(id => createInvoiceAndSend(navigate, id));
    await Promise.all(promises)
      .then(() => {
        enqueueSnackbar(`${promises.length === 1 ? 'Invoice' : 'Invoices'} sent to ${promises.length === 1 ? 'client' : 'clients'}`, {variant: 'success', autoHideDuration: 5000});
        return fetchCompletedWorkOrders()
      })
      .catch(e => {
        logError('Error sending invoices', {}, e)
        enqueueSnackbar(`Error sending invoices: ${e}`, {variant: 'error', autoHideDuration: 5000});
      })
  }

  const invoiceColumns: GridColDef[] = [
    { field: 'client', headerName: 'Client', width: 350 },
    { field: 'address', headerName: 'Address', width: 300 },
    { field: 'city', headerName: 'City', width: 150 },
    { field: 'state', headerName: 'State', width: 100 },
    { field: 'status', headerName: 'Status', width: 150 },
    { field: 'amount', headerName: 'Amount', width: 100 },
    { field: 'notes', headerName: 'Notes', width: 200 },
    { field: 'updatedAt', headerName: 'Updated At', width: 200 },
  ];

  const rowGenerator = (estimate: EstimateWithClientAndCostDto) => {
    return {
      id: estimate.id,
      client: `${estimate.clientName} (${estimate.email})`,
      address: estimate.estimateAddress,
      city: estimate.estimateCity,
      state: estimate.estimateState,
      status: estimate.status,
      amount: `$${estimate.estimatedCost}`,
      notes: estimate.estimateNotes,
      updatedAt: estimate.updatedAt,
    }
  };

  const handleRowClick = (params: GridRowParams) => {
    navigate(`/estimates/${params.id}/itemized`)
  };

  return (
    <div style={{height: '100%'}}>
      <Paper sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', padding: 3}}>
        <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBottom: 2, gap: 2}}>
          <Button variant="contained" disabled={!sendSelectedEnabled()} onClick={handleSendSelectedClick}>Create And Send Invoices To Clients</Button>
        </Box>
        <UrlFilterAwareDataGrid
          columns={invoiceColumns}
          rowBuilder={rowGenerator}
          data={completedWordOrders}
          loading={completedWorkOrdersAreLoading}
          onRowClick={handleRowClick}
          showCheckboxes={true}
          onCheckboxSelectionChange={ids => handleSelectedRowsChange(ids as number[])}
        />
      </Paper>
    </div>
  );
}

export default CreateNewInvoiceListPage;
