import { datadogLogs, StatusType } from '@datadog/browser-logs'
import {Context} from "node:vm";
import {getLoggedInUser, getLoggedInUserOrEmpty} from "./auth.service";

export const logGeneric = (message: string, messageContext?: object, status?: StatusType, error?: Error): void => {
  const user = getLoggedInUserOrEmpty();
  datadogLogs.logger.log(message, {user, ...messageContext}, status, error);
  console.log(message, messageContext, status, error);
};

export const logDebug = (message: string, messageContext?: object, error?: Error): void => {
  const user = getLoggedInUserOrEmpty();
  datadogLogs.logger.debug(message, {user, ...messageContext}, error);
  console.debug(message, messageContext, error);
};

export const logInfo = (message: string, messageContext?: object, error?: Error): void => {
  const user = getLoggedInUserOrEmpty();
  datadogLogs.logger.info(message, {user, ...messageContext}, error);
  console.info(message, messageContext, error);
};

export const logWarn = (message: string, messageContext?: object, error?: Error): void => {
  const user = getLoggedInUserOrEmpty();
  datadogLogs.logger.warn(message, {user, ...messageContext}, error);
  console.warn(message, messageContext, error);
};

export const logError = (message: string, messageContext?: object, error?: Error): void => {
  const user = getLoggedInUserOrEmpty();
  datadogLogs.logger.error(message, {user, ...messageContext}, error);
  console.error(message, messageContext, error);
};

export const setLogContext = (context: object): void => {
  datadogLogs.logger.setContext(context);
};

export const getLogContext = (): Context => {
  return datadogLogs.logger.getContext();
};
