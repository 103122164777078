import {EstimateWithComponentsDto} from "../../shared/dtos/estimate-with-components.dto";
import {LatLngDto, UpdateEstimateDragPathDto} from "../../shared/dtos/estimate-drag-path.dto";

// Only pure functions should be in this file, this also makes these function safe to call in listener callbacks where state updates don't exist

const METERS_IN_FOOT = 0.3048;

export const getPathDistanceFeet = (pathPoints: LatLngDto[]) => {
  let totalDistance = 0;
  for(let i = 0; i < pathPoints.length - 1; i++) {
    const distance = google.maps.geometry.spherical.computeDistanceBetween(new google.maps.LatLng(pathPoints[i].lat, pathPoints[i].lng), new google.maps.LatLng(pathPoints[i + 1].lat, pathPoints[i + 1].lng));
    totalDistance += (distance / METERS_IN_FOOT);
  }
  return totalDistance;
}

export const findNearestChipperTruckWithDistance = (estimate: EstimateWithComponentsDto, lat: number, lng: number, excludeChipperId?: number) => {
  return estimate?.additionalComponents
    .filter(ac => ac.type === 'chipper' && ac.id !== excludeChipperId)
    .map(chipperTruck => ({
      chipperTruck,
      distance: google.maps.geometry.spherical.computeDistanceBetween(new google.maps.LatLng(chipperTruck.lat, chipperTruck.lng), new google.maps.LatLng(lat, lng))
    }))
    .sort((a, b) => a.distance - b.distance)[0];
}

export const getDragPathUpdate = (estimate: EstimateWithComponentsDto, path: google.maps.MVCArray<google.maps.LatLng>, pathId: number, changedPointIndex: number): UpdateEstimateDragPathDto => {
  const points = path.getArray().map(p => ({lat: p.lat(), lng: p.lng()}));
  const changedPoint = points[changedPointIndex];
  const dragPathDto = estimate.dragPaths.find(d => d.id === pathId);
  const nearestDifferentChipperTruck = findNearestChipperTruckWithDistance(estimate, changedPoint.lat, changedPoint.lng, dragPathDto?.chipperTruckId);

  if(nearestDifferentChipperTruck && nearestDifferentChipperTruck.distance < 5) {
    const pointsToKeep = points.slice(0, changedPointIndex);
    const newPoints = [...pointsToKeep, {lat: nearestDifferentChipperTruck.chipperTruck.lat, lng: nearestDifferentChipperTruck.chipperTruck.lng}]
    const distanceFeet = getPathDistanceFeet(newPoints);
    return {
      points: newPoints,
      distanceFeet,
      chipperTruckId: nearestDifferentChipperTruck.chipperTruck.id
    }
  } else {
    return { points, distanceFeet: getPathDistanceFeet(points) }
  }
}

