import {deleteAdditionalComponent} from "./estimate-additional-component.service";
import {useNavigate} from "react-router-dom";
import React, {useState} from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader
} from "@mui/material";
import {enqueueSnackbar} from "notistack";
import {CenteredCircularSpinner} from "../../shared/components/CenteredCircularSpinner";
import {EstimateAdditionalComponentDto} from "../../shared/dtos/estimate-additional-component.dto";

export interface EstimateChipperTruckDetailComponentProps {
  chipperTruck: EstimateAdditionalComponentDto;
  onDelete: () => void;
  onCancel: () => void;
}

const EstimateChipperTruckDetailComponent: React.FC<EstimateChipperTruckDetailComponentProps> = React.forwardRef((props: EstimateChipperTruckDetailComponentProps, ref) => {
  const navigate = useNavigate();

  const [isDeleting, setIsDeleting] = useState(false);

  const handleCancelClick = () => {
    props.onCancel();
  }

  const handleDeleteClick = () => {
    deleteAdditionalComponent(navigate, props.chipperTruck.estimateId, props.chipperTruck.id)
      .then(() => {
        props.onDelete();
      })
      .catch(e => {
        console.error(`Failed to delete chipper truck: ${e}`);
        enqueueSnackbar(`Failed to delete chipper truck: ${e}`, {variant: 'error'});
      })
      .finally(() => {
        setIsDeleting(false);
      });
  }

  return (
    <Card
      sx={{ maxWidth: 400, maxHeight: '90vh', mx: 'auto', my: 4, overflowY: 'auto' }}>
      <CardContent>
        <CardHeader title={`Chipper Truck`}/>
        <Box display="flex" justifyContent="space-between">
          {isDeleting ? <CenteredCircularSpinner/> :
            <>
              <Button variant="outlined" color="primary" onClick={handleCancelClick}>
                Cancel
              </Button>
              <Button variant="contained" color="error" onClick={handleDeleteClick}>
                Delete
              </Button>
            </>}
        </Box>
      </CardContent>
    </Card>

  );
});

export default EstimateChipperTruckDetailComponent;