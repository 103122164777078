export interface EstimateDto {
  id: number;
  companyId: number;
  clientId: number;
  name: string;
  lat: number;
  lng: number;
  address: string;
  city: string;
  state: string;
  postalCode: string;
  countTrees: number;
  status: EstimateStatusType;
  crewSize: number | null;
  equipment: string | null;
  notes: string | null;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
}

export interface EstimateWithClientAndCostDto {
  id: number;
  companyId: number;
  estimateName: string;
  lat: number;
  lng: number;
  estimateAddress: string;
  estimateCity: string;
  estimateState: string;
  estimatePostalCode: string;
  status: EstimateStatusType;
  crewSize?: number;
  equipment?: string;
  estimateNotes?: string;
  createdBy: number;
  createdAt: Date;
  updatedAt: Date;
  clientId: number;
  clientName: string;
  clientAddress: string;
  clientCity: string;
  clientState: string;
  clientPostalCode: string;
  email?: string;
  phoneNumber?: string;
  estimatedCost: number;
  countTrees: number;
}

export const EstimateStatus = {
  EstimateNotStarted: 'EstimateNotStarted',
  EstimateInProgress: 'EstimateInProgress',
  EstimateCompleted: 'EstimateCompleted',
  EstimatePendingApproval: 'EstimatePendingApproval',
  EstimateApproved: 'EstimateApproved',
  EstimateCancelled: 'EstimateCancelled',
  WorkOrderPending: 'WorkOrderPending',
  WorkOrderInProgress: 'WorkOrderInProgress',
  WorkOrderCompleted: 'WorkOrderCompleted',
  WorkOrderCancelled: 'WorkOrderCancelled',
  InvoiceCreated: 'InvoiceCreated',
  InvoiceSent: 'InvoiceSent',
  InvoiceOverdue: 'InvoiceOverdue',
  InvoicePaid: 'InvoicePaid',
  ReceiptSent: 'ReceiptSent'
} as const;

export type EstimateStatusType = typeof EstimateStatus[keyof typeof EstimateStatus];

