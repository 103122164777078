import React, {useEffect, useState} from 'react';
import {
  Box,
  Button, Modal,
  Paper,
} from "@mui/material";
import {emailEstimateToCustomer, updateEstimate, getExtendedEstimates} from "../estimate/estimate.service";
import {useNavigate, useSearchParams} from "react-router-dom";
import { EstimateStatus, EstimateWithClientAndCostDto} from "../../shared/dtos/estimate.dto";
import {enqueueSnackbar} from "notistack";
import {logError} from "../../shared/services/logger.service";
import {DataGrid, gridClasses, GridColDef, GridRowParams, GridToolbar} from '@mui/x-data-grid';
import {useToolbar} from "../../components/ToolbarContext";
import {UrlFilterAwareDataGrid} from "../../shared/components/UrlFilterAwareDataGrid";
import {Filter} from "../appointment/AppointmentListPage";
import ClientDetailComponent from "../client/ClientDetailComponent";
import EstimateInfoComponent from "./EstimateInfoComponent";

const inProgressFilter: Filter<EstimateWithClientAndCostDto> = {
  displayName: 'In Progress',
  urlParam: 'inProgress',
  filterFunction: (values: EstimateWithClientAndCostDto[]) => {
    return values.filter(estimate => estimate.status === EstimateStatus.EstimateInProgress);
  }
}

const completedFilter: Filter<EstimateWithClientAndCostDto> = {
  displayName: 'Completed',
  urlParam: 'completed',
  filterFunction: (values: EstimateWithClientAndCostDto[]) => {
    return values.filter(estimate => estimate.status === EstimateStatus.EstimateCompleted);
  }
}

const pendingApprovalFilter: Filter<EstimateWithClientAndCostDto> = {
  displayName: 'Pending Approval',
  urlParam: 'pendingApproval',
  filterFunction: (values: EstimateWithClientAndCostDto[]) => {
    return values.filter(estimate => estimate.status === EstimateStatus.EstimatePendingApproval);
  }
}

const cancelledFilter: Filter<EstimateWithClientAndCostDto> = {
  displayName: 'Cancelled',
  urlParam: 'cancelled',
  filterFunction: (values: EstimateWithClientAndCostDto[]) => {
    return values.filter(estimate => estimate.status === EstimateStatus.EstimateCancelled);
  }
}

const showAllFilter: Filter<EstimateWithClientAndCostDto> = {
  displayName: 'All',
  urlParam: 'showAll',
  filterFunction: (values: EstimateWithClientAndCostDto[]) => values
}

const allFilters = [showAllFilter, inProgressFilter, completedFilter, pendingApprovalFilter, cancelledFilter];

const parseParamsEstimateId = (paramsClientId: string | null): number | 'NEW' | undefined => {
  if(!paramsClientId) return undefined;
  if(paramsClientId.toLowerCase() == "new") return 'NEW';
  const numericValue = parseInt(paramsClientId)
  return isNaN(numericValue) ? undefined : numericValue;
}

function EstimateListPage() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams()
  const toolbarContext = useToolbar();
  const initialEstimateId = parseParamsEstimateId(searchParams.get("estimateId"))

  const [estimates, setEstimates] = useState<EstimateWithClientAndCostDto[]>([]);
  const [estimatesAreLoading, setEstimatesAreLoading] = useState(false);
  const [checkBoxSelectedEstimateIds, setCheckBoxSelectedEstimateIds] = useState<number[]>([]);
  const [selectedEstimateId, setSelectedEstimateId] = useState<number | 'NEW' | undefined>(initialEstimateId);

  async function fetchEstimates() {
    setEstimatesAreLoading(true);
    await getExtendedEstimates(navigate)
      .then(estimates => setEstimates(estimates))
      .catch(e => {
        logError('Error fetching estimates', {}, e)
        enqueueSnackbar(`Error fetching estimates: ${e}`, {variant: 'error', autoHideDuration: 5000});
      })
      .finally(() =>
      {
        setEstimatesAreLoading(false)
      });
  }

  useEffect(() => {
    toolbarContext.initToolbar('EstimateListPage', 'Estimates');
    fetchEstimates()
  }, []);


  const estimateColumns: GridColDef[] = [
    { field: 'clientName', headerName: 'Client', width: 200 },
    { field: 'clientEmail', headerName: 'Client Email', width: 250 },
    { field: 'clientPhoneNumber', headerName: 'Client Phone #', width: 150 },
    { field: 'address', headerName: 'Address', width: 300 },
    { field: 'city', headerName: 'City', width: 150 },
    { field: 'state', headerName: 'State', width: 100 },
    { field: 'status', headerName: 'Status', width: 200 },
    { field: 'countTrees', headerName: 'Count Trees', width: 150 },
    { field: 'estimatedCost', headerName: 'Amount', width: 100 },
    { field: 'updatedAt', headerName: 'Updated At', width: 200 },
  ];

  const rowBuilder = (estimate: EstimateWithClientAndCostDto) => {
    return {
      id: estimate.id,
      clientName: estimate.clientName,
      clientEmail: estimate.email,
      clientPhoneNumber: estimate.phoneNumber,
      address: estimate.estimateAddress,
      city: estimate.estimateCity,
      state: estimate.estimateState,
      status: estimate.status,
      countTrees: estimate.countTrees,
      estimatedCost: `$${estimate.estimatedCost}`,
      updatedAt: estimate.updatedAt,
    }
  };

  const approveSelectedEnabled = () => {
    if(checkBoxSelectedEstimateIds.length == 0) return false;
    const allMatchingEstimates = estimates.filter(e => checkBoxSelectedEstimateIds.includes(e.id));
    return allMatchingEstimates.every(e => e.status === EstimateStatus.EstimatePendingApproval);
  }
  const sendSelectedEnabled = () => {
    if(checkBoxSelectedEstimateIds.length == 0) return false;
    const allMatchingEstimates = estimates.filter(e => checkBoxSelectedEstimateIds.includes(e.id));
    return allMatchingEstimates.every(e => e.status === EstimateStatus.EstimateCompleted);
  }

  const rowIsSelectable = (row: EstimateWithClientAndCostDto) => {
    return row.status === EstimateStatus.EstimatePendingApproval || row.status === EstimateStatus.EstimateCompleted;
  }

  const handleSelectedRowsChange = (selectedRowIds: number[]) => {
    setCheckBoxSelectedEstimateIds(selectedRowIds);
  }

  const handleApproveSelectedClick = async () => {
    const promises = checkBoxSelectedEstimateIds.map(id =>
      updateEstimate(navigate, id, {status: EstimateStatus.EstimateApproved})
    )
    await Promise.allSettled(promises).then(() => fetchEstimates());
    setCheckBoxSelectedEstimateIds([]);
  }

  const handleSendSelectedClick = async () => {
    const promises = checkBoxSelectedEstimateIds.map(async id => {
      const matchingEstimate = estimates.find(e => e.id === id);
      if(matchingEstimate?.status === EstimateStatus.EstimateCompleted) {
        await emailEstimateToCustomer(navigate, id);
      }
    })
    await Promise.allSettled(promises)
      .then(() => fetchEstimates())
      .catch(e => {
        logError('Error sending estimates to customers', {}, e)
        enqueueSnackbar(`Error sending estimates to customers: ${e}`, {variant: 'error', autoHideDuration: 5000})
      })
    enqueueSnackbar('Estimates sent to customers', {variant: 'success', autoHideDuration: 5000})
    setCheckBoxSelectedEstimateIds([]);
  }

  const handleAddNewEstimateClick = () => {
    setSelectedEstimateId('NEW');
  }

  const handleRowClick = (params: GridRowParams) => {
    navigate(`/estimates/${params.id}`)
  };

  const handleCloseEstimateInfo = () => {
    setSelectedEstimateId(undefined);
    searchParams.delete("estimateId")
    setSearchParams(searchParams);
  }

  const handleEstimateDetailSaved = async () => {
    setSelectedEstimateId(undefined);
    await fetchEstimates();
  }

  return (
    <div style={{height: '100%'}}>
      <Paper sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', padding: 3}}>
        <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBottom: 2, gap: 2}}>
          <Button variant="contained" onClick={handleAddNewEstimateClick}>Add New Estimate</Button>
          <Button variant="contained" disabled={!approveSelectedEnabled()} onClick={handleApproveSelectedClick}>Approve Selected</Button>
          <Button variant="contained" disabled={!sendSelectedEnabled()} onClick={handleSendSelectedClick}>Send Selected To Customer</Button>
        </Box>
        <UrlFilterAwareDataGrid
          columns={estimateColumns}
          data={estimates}
          rowBuilder={rowBuilder}
          loading={estimatesAreLoading}
          onRowClick={handleRowClick}
          allFilters={allFilters}
          defaultFilters={[showAllFilter]}
          showCheckboxes={true}
          onCheckboxSelectionChange={ids => handleSelectedRowsChange(ids as number[])}
          isRowCheckboxEnabled={rowIsSelectable}
        ></UrlFilterAwareDataGrid>
      </Paper>
      <Modal
        open={!!selectedEstimateId}
        onClose={() => handleCloseEstimateInfo()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <EstimateInfoComponent estimateId={selectedEstimateId === 'NEW' ? undefined : selectedEstimateId} onClose={() => handleCloseEstimateInfo()} onSave={() => handleEstimateDetailSaved()}/>
      </Modal>
    </div>
  );
}

export default EstimateListPage;
