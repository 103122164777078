import {NavigateFunction} from "react-router-dom";
import {getUserTokenOrRedirect} from "../../shared/services/auth.service";
import {AddEquipmentDto, EquipmentDto, EquipmentType, UpdateEquipmentDto} from "./dtos/equipment.dto";

export interface EquipmentDetailSchema {
  name: string,
  displayName?: string,
  dataType: 'text' | 'number';
  required: boolean;
}

export async function getAllEquipment(navigate: NavigateFunction): Promise<EquipmentDto[]> {
  const token = await getUserTokenOrRedirect(navigate)
  const equipment = await fetch(
    `${process.env.REACT_APP_ACCUSITE_API_BASE_URL}/api/v1/equipment`,
    {headers: {Authorization: `Bearer ${token}`}})
    .then(async response => {
      if (!response.ok) {
        throw new Error(`Failed to fetch equipment: ${response.statusText}`);
      }
      return await response.json() as EquipmentDto[]
    });
  return equipment;
}

export async function getEquipment(navigate: NavigateFunction, equipmentId: number): Promise<EquipmentDto> {
  const token = await getUserTokenOrRedirect(navigate)
  const equipment = await fetch(
    `${process.env.REACT_APP_ACCUSITE_API_BASE_URL}/api/v1/equipment/${equipmentId}`,
    {headers: {Authorization: `Bearer ${token}`}})
    .then(async response => {
      if (!response.ok) {
        throw new Error(`Failed to fetch equipment: ${response.statusText}`);
      }
      return await response.json() as EquipmentDto
    });
  return equipment;
}

export async function createEquipment(navigate: NavigateFunction, equipment: AddEquipmentDto): Promise<EquipmentDto> {
  const token = await getUserTokenOrRedirect(navigate)
  const createdEquipment = await fetch(
    `${process.env.REACT_APP_ACCUSITE_API_BASE_URL}/api/v1/equipment`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(equipment)
    }).then(async response => {
    if (!response.ok) {
      throw new Error(`Failed to create equipment: ${response.statusText}`);
    }
      return await response.json() as EquipmentDto
  });
  return createdEquipment;
}

export async function updateEquipment(navigate: NavigateFunction, equipmentId: number, client: UpdateEquipmentDto): Promise<EquipmentDto> {
  const token = await getUserTokenOrRedirect(navigate)
  const response = await fetch(
    `${process.env.REACT_APP_ACCUSITE_API_BASE_URL}/api/v1/equipment/${equipmentId}`,
    {
      method: 'PATCH',
      body: JSON.stringify(client),
      headers:
        {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
    })
    .then(async r => r.ok ? (await r.json()) as EquipmentDto : Promise.reject(r));

  return response;
}

export async function deleteEquipment(navigate: NavigateFunction, equipmentId: number): Promise<void> {
  const token = await getUserTokenOrRedirect(navigate)
  const response = await fetch(
    `${process.env.REACT_APP_ACCUSITE_API_BASE_URL}/api/v1/equipment/${equipmentId}`,
    {
      method: 'DELETE',
      headers:
        {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
    });
  if(!response.ok) {
    throw new Error(`Failed to delete equipment: ${response.statusText}`);
  }
}

export const EquipmentTypeSchemas: Record<EquipmentType, EquipmentDetailSchema[]> = {
  'Bucket Truck': [
    { name: 'make', displayName: 'Make', dataType: 'text', required: false },
    { name: 'model', displayName: 'Model', dataType: 'text', required: false },
    { name: 'year', displayName: 'Year', dataType: 'number', required: false },
    { name: 'vin', displayName: 'VIN', dataType: 'text', required: false },
    { name: 'maxWorkingHeightInches', displayName: 'Maximum Working Height', dataType: 'number', required: true },
    { name: 'maxSideReachInches', displayName: 'Maximum Side Reach', dataType: 'number', required: true },
    { name: 'maxChipBoxCapacityCubicYards', displayName: 'Maximum Chip Box Capacity (cubic yards)', dataType: 'number', required: true },
  ],
  'Chip Truck': [
    { name: 'make', displayName: 'Make', dataType: 'text', required: false },
    { name: 'model', displayName: 'Model', dataType: 'text', required: false },
    { name: 'year', displayName: 'Year', dataType: 'number', required: false },
    { name: 'vin', displayName: 'VIN', dataType: 'text', required: false },
    { name: 'maxChipBoxCapacityCubicYards', displayName: 'Maximum Chip Box Capacity (cubic yards)', dataType: 'number', required: true },
  ],
  'Brush Chipper': [
    { name: 'make', displayName: 'Make', dataType: 'text', required: false },
    { name: 'model', displayName: 'Model', dataType: 'text', required: false },
    { name: 'year', displayName: 'Year', dataType: 'number', required: false },
    { name: 'vin', displayName: 'VIN', dataType: 'text', required: false },
    { name: 'maxChippingDiameterInches', displayName: 'Maximum Chipping Diameter', dataType: 'number', required: true },
  ],
  'Aerial Lift': [
    { name: 'make', displayName: 'Make', dataType: 'text', required: false },
    { name: 'model', displayName: 'Model', dataType: 'text', required: false },
    { name: 'year', displayName: 'Year', dataType: 'number', required: false },
    { name: 'vin', displayName: 'VIN', dataType: 'text', required: false },
    { name: 'widthInches', displayName: 'Width', dataType: 'number', required: true },
    { name: 'lengthInches', displayName: 'Length', dataType: 'number', required: true },
    { name: 'heightInches', displayName: 'Height', dataType: 'number', required: true },
    { name: 'weightPounds', displayName: 'Weight', dataType: 'number', required: false },
    { name: 'maxWorkingHeightInches', displayName: 'Maximum Working Height', dataType: 'number', required: true },
    { name: 'maxSideReachInches', displayName: 'Maximum Side Reach', dataType: 'number', required: true },
    { name: 'maxSetupWidthInches', displayName: 'Maximum Setup Width', dataType: 'number', required: true },
  ],
  'Stump Grinder': [
    { name: 'make', displayName: 'Make', dataType: 'text', required: false },
    { name: 'model', displayName: 'Model', dataType: 'text', required: false },
    { name: 'year', displayName: 'Year', dataType: 'number', required: false },
    { name: 'vin', displayName: 'VIN', dataType: 'text', required: false },
    { name: 'widthInches', displayName: 'Width', dataType: 'number', required: true },
    { name: 'lengthInches', displayName: 'Length', dataType: 'number', required: true },
    { name: 'weightPounds', displayName: 'Weight', dataType: 'number', required: false },
    { name: 'maxGrindingDepthInches', displayName: 'Maximum Grinding Depth', dataType: 'number', required: true },
    { name: 'category', displayName: 'Category', dataType: 'text', required: true },
  ],
  'Compact Loader': [
    { name: 'make', displayName: 'Make', dataType: 'text', required: false },
    { name: 'model', displayName: 'Model', dataType: 'text', required: false },
    { name: 'year', displayName: 'Year', dataType: 'number', required: false },
    { name: 'vin', displayName: 'VIN', dataType: 'text', required: false },
    { name: 'widthInches', displayName: 'Width', dataType: 'number', required: true },
    { name: 'lengthInches', displayName: 'Length', dataType: 'number', required: true },
    { name: 'heightInches', displayName: 'Height', dataType: 'number', required: true },
    { name: 'weightPounds', displayName: 'Weight', dataType: 'number', required: false },
    { name: 'maxLiftCapacityPounds', displayName: 'Maximum Lift Capacity', dataType: 'number', required: true },
    { name: 'category', displayName: 'Category', dataType: 'text', required: true },
  ],
  'Crane': [
    { name: 'make', displayName: 'Make', dataType: 'text', required: false },
    { name: 'model', displayName: 'Model', dataType: 'text', required: false },
    { name: 'year', displayName: 'Year', dataType: 'number', required: false },
    { name: 'vin', displayName: 'VIN', dataType: 'text', required: false },
    { name: 'lengthInches', displayName: 'Length', dataType: 'number', required: true },
    { name: 'heightInches', displayName: 'Height', dataType: 'number', required: true },
    { name: 'weightPounds', displayName: 'Weight', dataType: 'number', required: false },
    { name: 'maxSetupWidthInches', displayName: 'Maximum Setup Width', dataType: 'number', required: true },
    { name: 'maxHeightReachInches', displayName: 'Maximum Height Reach', dataType: 'number', required: true },
    { name: 'sizeTons', displayName: 'Size (Tons)', dataType: 'number', required: true },
  ],
  'Grapple Truck': [
    { name: 'make', displayName: 'Make', dataType: 'text', required: false },
    { name: 'model', displayName: 'Model', dataType: 'text', required: false },
    { name: 'year', displayName: 'Year', dataType: 'number', required: false },
    { name: 'vin', displayName: 'VIN', dataType: 'text', required: false },
    { name: 'lengthInches', displayName: 'Length', dataType: 'number', required: true },
    { name: 'heightInches', displayName: 'Height', dataType: 'number', required: true },
    { name: 'weightPounds', displayName: 'Weight', dataType: 'number', required: false },
    { name: 'maxSetupWidthInches', displayName: 'Maximum Setup Width', dataType: 'number', required: true },
  ],
  'Other': [
    { name: 'type', displayName: 'Type', dataType: 'text', required: true },
    { name: 'make', displayName: 'Make', dataType: 'text', required: false },
    { name: 'model', displayName: 'Model', dataType: 'text', required: false },
    { name: 'year', displayName: 'Year', dataType: 'number', required: false },
    { name: 'vin', displayName: 'VIN', dataType: 'text', required: false },
  ],
}

