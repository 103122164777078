import {Box, Button, Typography} from "@mui/material";
import React, {useEffect} from "react";
import {useLocationPermissions} from "./LocationPermissionsContext";

const PermissionsMissingComponent = () => {
  const { checkLocationPermissions, requestLocationPermissions } = useLocationPermissions();

  useEffect(() => {
    requestLocationPermissions();
  }, []);

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100vh">
      <Typography variant="h6" gutterBottom>
        Location permissions are required to use this app.
      </Typography>
      <Button variant="contained" color="primary" onClick={checkLocationPermissions}>
        Reload
      </Button>
    </Box>
  )
}

export default PermissionsMissingComponent;