// src/context/ToolbarContext.tsx
import React, {createContext, useState, useContext, ReactNode, useEffect, useCallback} from 'react';

interface ToolbarContextType<TPageData extends Record<string, unknown> = Record<string, unknown>> {
  initToolbar: (page: string, title: string, data?: TPageData, actions?: ((pageToolbarData: TPageData) => ReactNode)) => void;
  page: string;
  setPage: (page: string) => void;
  toolbarData: TPageData;
  setToolbarData: (toolbarData: TPageData) => void;
  title: string;
  setTitle: (title: string) => void;
  centeredContent: ReactNode | undefined;
  setCenteredContent: (actions: ReactNode) => void;
  actions: ((pageToolbarData: TPageData) => ReactNode) | undefined;
  setActions: (actions: (pageToolbarData: TPageData) => ReactNode) => void;
}

const ToolbarContext = createContext<ToolbarContextType | undefined>(undefined);

export const ToolbarProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [page, setPage] = useState<string>('Home');
  const [title, setTitle] = useState<string>('Accusite');
  const [actions, setActions] = useState<((pageToolbarData: any) => React.ReactNode) | undefined>(undefined);
  const [allToolbarData, setAllToolbarData] = useState<Record<string, Record<string, unknown>>>({});
  const [centeredContent, setCenteredContent] = useState<React.ReactNode | undefined>(undefined);

  const initToolbar = (page: string, title: string, data?: Record<string, unknown>, actionsBuilder?: ((pageToolbarData: Record<string, unknown>) => ReactNode), centeredContent?: ReactNode) => {
    setPage(page);
    setAllToolbarData(allData => { return {...allData, [page]: data ?? {}} });
    setTitle(title);
    setCenteredContent(centeredContent);
    setActions(() => actionsBuilder);
  }

  const toolbarData = allToolbarData[page] ?? {};
  const setToolbarData = (toolbarData: Record<string, unknown>) => {
    setAllToolbarData(allData => { return {...allData, [page]: toolbarData} });
  }

  return (
    <ToolbarContext.Provider value={{ initToolbar, page, setPage, toolbarData, setToolbarData, title, setTitle, actions, setActions, centeredContent, setCenteredContent}}>
      {children}
    </ToolbarContext.Provider>
  );
};

export function useToolbar<T extends Record<string, unknown>>(): ToolbarContextType<T> {
  const context = useContext(ToolbarContext);
  if (!context) {
    throw new Error('useToolbar must be used within a ToolbarProvider');
  }
  return context as ToolbarContextType<T>;
};