import React, {useCallback, useEffect, useRef, useState} from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  LinearProgress,
  Button,
  Dialog,
  Modal,
  ToggleButtonGroup, ToggleButton
} from '@mui/material';
import genericTreeLogo from '../../assets/generic_tree_logo.png'
import {NavigateFunction, useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useToolbar} from "../../components/ToolbarContext";
import {EstimateWithComponentsDto} from "../../shared/dtos/estimate-with-components.dto";
import {EstimateTreeDto} from "../../shared/dtos/estimate-tree.dto";
import {EstimateTreeOperationDto} from "../../shared/dtos/estimate-tree-operation.dto";
import {requireAuth} from "../../shared/services/auth.service";
import {customerApproveEstimate, getCustomerFacingEstimate, getEstimate, emailEstimateToCustomer} from "../estimate/estimate.service";
import {getTreeOperations} from "../estimate/estimate-tree.service";
import {logError} from "../../shared/services/logger.service";
import {enqueueSnackbar} from "notistack";
import {getClient} from "../client/clients.service";

import {StoredImageDto} from "../../shared/dtos/stored-image.dto";
import {emailInvoiceToCustomer, getCustomerFacingInvoice} from "../invoice/invoice.service";
import {EstimateStatusUpdateMenu} from "../../shared/components/EstimateStatusUpdateMenu";
import {DrawableImage} from "../estimate/DrawableImage";
import PaymentInformation from "./PaymentInformation";
import {EstimateStatus, EstimateStatusType} from "../../shared/dtos/estimate.dto";
import ConfirmEstimate from "./ConfirmEstimate";
import generatePDF from 'react-to-pdf';
import {ExternalCompanyDto} from "../../shared/dtos/company.dto";
import {getCompany, getCustomerFacingCompany} from "../../shared/services/company.service";
import {EstimateItemizedDisplayMode, EstimateItemizedDisplayType, EstimateItemizedStateless} from "./EstimateItemizedStateless";
import {EstimateGateDetailsDto} from "../../shared/dtos/estimate-gate.dto";
import {ItemizedEstimateDto} from "../../shared/dtos/itemized-estimate.dto";
import {CenteredCircularSpinner} from "../../shared/components/CenteredCircularSpinner";
import {emailReceiptToCustomer, getCustomerFacingReceipt} from "../receipt/receipt.service";


export type TreeWithOperation = EstimateTreeDto & {operations: EstimateTreeOperationDto[]};

type EstimateItemizedPageToolbarData = {
  estimateId: number,
  displayType: EstimateItemizedDisplayType,
  displayMode: EstimateItemizedDisplayMode,
}

const EstimateItemizedPage: React.FC = () => {
  const location = useLocation();
  const isExternal = location.pathname.includes('external');

  const invoiceRef = useRef<HTMLDivElement>(null);

  const routeParams = useParams<Record<string, string>>();
  const estimateId = parseInt((routeParams["estimateId"] || routeParams["invoiceId"] || routeParams["receiptId"])!);
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate();
  const toolbarContext = useToolbar<EstimateItemizedPageToolbarData>();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [emailIsSending, setEmailIsSending] = useState<boolean>(false);
  const [estimate, setEstimate] = useState<ItemizedEstimateDto>();
  const [client, setClient] = useState<{name: string, address: string, email?: string, phoneNumber?: string}>();
  const [company, setCompany] = useState<ExternalCompanyDto>();
  const [treesWithOperations, setTreesWithOperations] = useState<TreeWithOperation[]>();
  const [selectedImage, setSelectedImage] = useState<{id: number, s3Url: string} | null>(null);
  const [payModalIsOpen, setPayModalIsOpen] = useState<boolean>(false);
  const [confirmEstimateModalIsOpen, setConfirmEstimateModalIsOpen] = useState<boolean>(false);
  const [displayMode, setDisplayMode] = useState<EstimateItemizedDisplayMode>('admin');
  const [displayType, setDisplayType] = useState<EstimateItemizedDisplayType>('estimate');

  const [updateStatusMenuLastClick, setUpdateStatusMenuLastClick] = useState<React.MouseEvent<HTMLButtonElement> | null>(null);

  const toolbarMapViewClick = (estimateId: number) => {
    navigate(`/estimates/${estimateId}`)
  }

  const toolbarDownloadPdfClick = (estimateId: number, displayType: EstimateItemizedDisplayType, type: string) => {
    generatePDF(invoiceRef, {
      filename: `${type}_${displayType}_${estimateId}.pdf`
    }).catch(e => {
      logError('Error generating PDF', {}, e);
      enqueueSnackbar(`Error generating PDF: ${e}`, {variant: 'error', autoHideDuration: 5000});
    });
  }

  const toolbarActions = (data: EstimateItemizedPageToolbarData) => <div style={{display: 'flex', gap: '10px'}} >
    <Button variant='outlined' onClick={() => toolbarMapViewClick(data.estimateId)}>Map View</Button>
    <Button variant='outlined' onClick={() => toolbarDownloadPdfClick(data.estimateId, data.displayType, data.displayType)}>Download</Button>
    <Button variant='outlined' onClick={setUpdateStatusMenuLastClick}>Update Status</Button>
  </div>

  useEffect(() => {
    toolbarContext.setToolbarData({estimateId, displayType, displayMode});
  }, [estimate?.id, displayType, displayMode]);

  const getDisplayType = (status: EstimateStatusType): EstimateItemizedDisplayType => {
    switch (status) {
      case EstimateStatus.InvoiceCreated:
      case EstimateStatus.InvoiceSent:
      case EstimateStatus.InvoiceOverdue:
        return 'invoice';
      case EstimateStatus.InvoicePaid:
      case EstimateStatus.ReceiptSent:
        return 'receipt';
      default:
        return 'estimate';
    }
  }

  const internalFetchData = async (navigate: NavigateFunction, estimateId: number) => {
    return requireAuth(navigate)
      .then(() => getEstimate(navigate, estimateId))
      .then(async e => {
        const client = await getClient(navigate, e.clientId)
        const company = await getCompany(navigate);
        setEstimate(e);
        setClient(client);
        setCompany(company);
        setDisplayMode('admin')
        setDisplayType(getDisplayType(e.status));
        const toolbarData = {estimateId, displayType: getDisplayType(e.status), displayMode: 'admin' as const};
        toolbarContext.initToolbar('EstimateItemizedPage', `${e.address}`, toolbarData, toolbarActions);
        const treesWithOperationsPromises = e.trees.map(async tree => {
          const operations = await getTreeOperations(navigate, e.id, tree.id);
          return {...tree, operations};
        });
        return await Promise.all(treesWithOperationsPromises);
      })
      .then(treesWithOps => {
        setTreesWithOperations(treesWithOps.sort((a, b) => a.id - b.id));
      })
      .catch(e => {
        logError('Error fetching estimate', {}, e)
        enqueueSnackbar(`Error fetching estimate: ${e}`, {variant: 'error', autoHideDuration: 5000});
      })
  }

  const getExternalEstimateDataImplementation = () => {
    if(location.pathname.includes('estimate')) {
      return getCustomerFacingEstimate
    }
    if(location.pathname.includes('invoice')) {
      return getCustomerFacingInvoice
    }
    if(location.pathname.includes('receipt')) {
      return getCustomerFacingReceipt
    }
    throw new Error('Unknown external estimate type')
  }

  const externalFetchData = async (navigate: NavigateFunction, estimateId: number) => {
    const accessToken = searchParams.get('accessToken');
    if(!accessToken) {
      throw new Error('No access token provided');
    }
    const externalGetDataImplementation = getExternalEstimateDataImplementation();

    const invoice = await externalGetDataImplementation(accessToken, estimateId);
    const company = await getCustomerFacingCompany(invoice.companyId);
    setEstimate(invoice);
    setClient({name: invoice.clientName, address: invoice.address, email: invoice.clientEmail, phoneNumber: invoice.clientPhoneNumber});
    setCompany(company);
    setDisplayMode('customer')
    setDisplayType(getDisplayType(invoice.status));
    const toolbarData = {estimateId, displayType: getDisplayType(invoice.status), displayMode: 'customer' as const};
    toolbarContext.initToolbar('EstimateItemizedPage', `${invoice.address}`, toolbarData, toolbarActions);
    setTreesWithOperations(invoice.trees.sort((a, b) => a.id - b.id));
  }

  const getFetchDataImplementation = () => {
    return isExternal ? externalFetchData : internalFetchData;
  }

  const refreshData = () => {
    setIsLoading(true);
    return getFetchDataImplementation()(navigate, estimateId)
      .catch(e => {
        logError('Error fetching estimate', {}, e)
        enqueueSnackbar(`Error fetching estimate: ${e}`, {variant: 'error', autoHideDuration: 5000});
      })
      .finally(() => {
        setIsLoading(false)
      });
  }

  useEffect(() => {
    toolbarContext.initToolbar('EstimateItemizedPage', 'Estimate Summary');
    refreshData();
  }, []);

  useEffect(() => {
    if (!treesWithOperations) return;
    let total = 0;
    treesWithOperations.forEach(tree => {
      tree.operations.forEach(op => {
        total += op.estimatedCost ?? 0;
      });
    });
  }, [treesWithOperations]);

  const canSendEmail = (status: EstimateStatusType) => {
    return ([EstimateStatus.EstimateCompleted, EstimateStatus.InvoiceCreated, EstimateStatus.InvoicePaid] as EstimateStatusType[]).includes(status);
  }

  const handleImageClick = (image: StoredImageDto) => {
    setSelectedImage({id: image.id, s3Url: image.imageUrl});
  }

  const handleImageDialogClose = () => {
    setSelectedImage(null);
  }

  const handlePayClick = () => {
    setPayModalIsOpen(true);
  }

  const handlePayModalClose = () => {
    setPayModalIsOpen(false);
  }

  const handleConfirmClick = () => {
    setConfirmEstimateModalIsOpen(true);
  }

  const handleConfirmEstimateModalClose = () => {
    setConfirmEstimateModalIsOpen(false)
  }

  const handleEstimateConfirmed = (file: File) => {
    const accessToken = searchParams.get('accessToken');
    if(!accessToken) {
      logError('No access token provided', {});
      enqueueSnackbar('No access token provided', {variant: 'error', autoHideDuration: 5000});
      return;
    }
    customerApproveEstimate(accessToken, estimateId, file)
      .then(async () => {
        await refreshData();
        setConfirmEstimateModalIsOpen(false)
        enqueueSnackbar('Estimate confirmed', {variant: 'success', autoHideDuration: 5000});
      })
      .catch(e => {
        logError('Error confirming estimate', {}, e)
        enqueueSnackbar(`Error confirming estimate: ${e}`, {variant: 'error', autoHideDuration: 5000})
      })
  }

  const handleEmailToCustomer = () => {
    setEmailIsSending(true)
    if(displayType === 'invoice') {
      emailInvoiceToCustomer(navigate, estimateId)
        .then(() => enqueueSnackbar('Invoice emailed to customer', {variant: 'success', autoHideDuration: 5000}))
        .catch(e => {
          logError('Error emailing invoice to customer', {}, e)
          enqueueSnackbar(`Error emailing invoice to customer: ${e}`, {variant: 'error', autoHideDuration: 5000});
        })
        .finally(() => setEmailIsSending(false));
    } else if(displayType === 'estimate') {
      emailEstimateToCustomer(navigate, estimateId)
        .then(() => enqueueSnackbar('Estimate emailed to customer', {variant: 'success', autoHideDuration: 5000}))
        .catch(e => {
          logError('Error emailing estimate to customer', {}, e)
          enqueueSnackbar(`Error emailing estimate to customer: ${e}`, {variant: 'error', autoHideDuration: 5000});
        })
        .finally(() => setEmailIsSending(false));
    } else if(displayType === 'receipt') {
      emailReceiptToCustomer(navigate, estimateId)
        .then(() => enqueueSnackbar('Receipt emailed to customer', {variant: 'success', autoHideDuration: 5000}))
        .catch(e => {
          logError('Error emailing receipt to customer', {}, e)
          enqueueSnackbar(`Error emailing receipt to customer: ${e}`, {variant: 'error', autoHideDuration: 5000});
        })
        .finally(() => setEmailIsSending(false));
    } else {
      logError(`Emailing customer not supported for ${displayType}`, {});
      enqueueSnackbar(`Emailing customer not supported for ${displayType}`, {variant: 'error', autoHideDuration: 5000});
      setEmailIsSending(false);
    }
  }

  const handleDisplayTypeChange = (event: React.MouseEvent<HTMLElement>, displayType: string | null) => {
    if(!displayType) return;
    setDisplayMode(displayType as 'admin' | 'customer' | 'contractor')
  }

  return (
    isLoading ? <LinearProgress/> :
    !treesWithOperations ? <Typography variant="h4">No trees found</Typography> :
    !client ? <Typography variant="h4">No client</Typography> :
    <div style={{width: '100%'}}>
      <Paper sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: 3}}>
        <EstimateStatusUpdateMenu estimateId={estimateId} lastButtonClickEvent={updateStatusMenuLastClick}/>
        {!isExternal && <ToggleButtonGroup
          color="primary"
          value={displayMode}
          exclusive
          onChange={handleDisplayTypeChange}
          style={{marginBottom: '10px'}}
        >
          <ToggleButton value="admin">Admin</ToggleButton>
          <ToggleButton value="customer">Customer</ToggleButton>
          <ToggleButton value="contractor">Contractor</ToggleButton>
        </ToggleButtonGroup>}
        <EstimateItemizedStateless ref={invoiceRef} company={company!} client={client} estimate={estimate!} treesWithOperations={treesWithOperations} displayType={displayType} displayMode={displayMode} onImageClick={handleImageClick}/>
        {!isExternal && displayMode !== 'contractor' && canSendEmail(estimate!.status) &&
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px'}}>
          <Button variant='contained' onClick={handleEmailToCustomer}>{emailIsSending ? <CenteredCircularSpinner size={25} color={"secondary"}/> : 'Email To Customer'}</Button>
        </div>}
        {isExternal && displayType === 'invoice' &&
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px'}}>
                <Button variant='contained' onClick={() => {handlePayClick()}}>Pay</Button>
            </div>}
        {isExternal && displayType === 'estimate' &&
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px'}}>
              {estimate?.status === EstimateStatus.EstimatePendingApproval
                ? <Button variant='contained' onClick={() => {handleConfirmClick()}}>Confirm</Button>
                : <Typography>Status: {estimate?.status ?? 'N/A'}</Typography>}
            </div>}
        <Dialog
          open={!!selectedImage}
          onClose={handleImageDialogClose}
          PaperProps={{
            style: { maxWidth: '100%', maxHeight: '100%' },
          }}
        >
          <DrawableImage image={selectedImage!} onClose={handleImageDialogClose} readonly={true}/>
        </Dialog>
        <Modal open={payModalIsOpen} onClose={handlePayModalClose}>
          {!estimate?.companyId ? <></> : <PaymentInformation companyId={estimate?.companyId} />}
        </Modal>
        <Modal open={confirmEstimateModalIsOpen} onClose={handleConfirmEstimateModalClose}>
          {!estimate?.id ? <></> : <ConfirmEstimate estimateId={estimate?.id} onConfirm={handleEstimateConfirmed} onCancel={handleConfirmEstimateModalClose}/>}
        </Modal>
      </Paper>
    </div>
  );
};

export default EstimateItemizedPage;