import {MarkableElement} from "../misc/markable-element";
import {MarkerInfo} from "../misc/marker-info.interface";
import React from "react";
import {logError} from "./logger.service";
import {enqueueSnackbar} from "notistack";
import {renderReactNode} from "./dom-helper.service";

const getMatchingElementOrThrow = <T extends MarkableElement>(elements: T[], elementId: number): T => {
  const element = elements.find(e => e.id === elementId);
  if(!element) {
    logError('Attempted to update a marker without associated element data')
    enqueueSnackbar('Attempted to update a marker without associated element data, please reload the page', {variant: 'error', autoHideDuration: 5000});
    throw new Error('Attempted to update a marker without associated element data');
  }
  return element;
}

const getMarkerId = (elementType: string, elementId: number) => `maps-marker-${elementType}-${elementId}`;

const cleanLostMarkers = (page: Document, elementType: string, markers: MarkerInfo[]) => {
  const lostMarkers = markers.filter(m => {
    const markerId = getMarkerId(elementType, m.elementId);
    return !page.getElementById(markerId);
  });
  lostMarkers.forEach(m => {
    m.markerDomObject.remove();
  });
  return markers.filter(m => !lostMarkers.some(lm => lm.elementId === m.elementId));
}

export const syncMarkers = <T extends MarkableElement>(
  page: Document,
  map: google.maps.Map,
  scale: number | undefined,
  elementType: string,
  elements: T[],
  markers: MarkerInfo[],
  setMarkers: (markers: MarkerInfo[]) => void,
  labelProducer: (element: T) => string,
  pinProducer: (element: T) => React.ReactNode,
  onClick: (element: T) => void,
  onDragEnd: (element: T, event: google.maps.MapMouseEvent) => void,
  markerOptions: Pick<google.maps.marker.AdvancedMarkerElementOptions, "gmpClickable" | "gmpDraggable"> | undefined = undefined)=> {
  // Sometimes google maps seems to lose markers around re-renders. This whole synchronization paradigm relies on the list of markers in state matching the markers on the map, so we need to remove any markers that don't exist on the map.
  const cleanedMarkers = cleanLostMarkers(page, elementType, markers);

  const markersToRemove = cleanedMarkers.filter(m => !elements.some(e => e.id === m.elementId));
  const markersToKeep = cleanedMarkers.filter(m => elements.some(e => e.id === m.elementId));
  const elementsWithoutMarkers = elements.filter(e => !markersToKeep.some(m => m.elementId === e.id));

  markersToRemove.forEach(m => m.markerDomObject.remove());
  markersToKeep.forEach(m => {
    const matchingElement = getMatchingElementOrThrow(elements, m.elementId);
    const markerMoved = m.markerDomObject.position?.lng != matchingElement.lng || m.markerDomObject.position?.lat != matchingElement.lat;
    if(markerMoved) {
      m.markerDomObject.position = {lat: matchingElement.lat, lng: matchingElement.lng};
    }
  });

  const newMarkers: MarkerInfo[] = elementsWithoutMarkers.map((element, i) => {
    const content = renderReactNode(pinProducer(element));
    const options = {
      position: {lat: element.lat, lng: element.lng},
      map: map,
      title: labelProducer(element),
      gmpDraggable: markerOptions?.gmpDraggable != undefined ? markerOptions.gmpDraggable : true,
      gmpClickable: markerOptions?.gmpClickable != undefined ? markerOptions.gmpClickable : true,
      content
    };
    const marker = new google.maps.marker.AdvancedMarkerElement(options);
    marker.id = getMarkerId(elementType, element.id);
    return {
      elementId: element.id,
      markerDomObject: marker,
      previousLabel: undefined,
      clickEventListener: undefined,
      scale
    }
  });
  const finalMarkers = [...markersToKeep, ...newMarkers].sort((a, b) => a.elementId - b.elementId);

  const finalMarkersWithListeners = finalMarkers.map(marker => {
    const element = getMatchingElementOrThrow(elements, marker.elementId);
    // Re-render the marker content if the label or scale has changed TODO: Now that we're doing pinProducer based on element, this doesn't guarantee that all re-renders will be correct
    const markerLabel = labelProducer(element);
    if(marker.previousLabel !== markerLabel || marker.scale !== scale) {
      marker.markerDomObject.content = renderReactNode(pinProducer(element));
      marker.previousLabel = markerLabel;
      marker.scale = scale;
    }
    return markerWithListeners(marker, element, onClick, onDragEnd);
  });

  setMarkers(finalMarkersWithListeners);
}

const markerWithListeners = <T extends MarkableElement>(marker: MarkerInfo, element: T, onClick: (element: T) => void, onDragEnd: (element: T, event: google.maps.MapMouseEvent) => void) => {
  /* Google maps provides a wrapped, simplified API for events, and we're supposed to be able to use those without having to worry about cross browser compatibility.
  There's only one small problem: It's not fully cross browser compatible! Specifically the gmp-click event doesn't work on Safari, and the older click event doesn't work on mobile in general.

  So, instead we use this nasty hack of adding a pointerup listener directly to the underlying dom element, bypassing the google maps event system entirely.
  However, this creates a new problem: The pointerup event also triggers the dragend event from the google maps event system which we also are listening to (and which thankfully does work on all browsers).
  To handle this, we need to check if we're in the middle of dragging, and if we are, we don't trigger the click event.
  This means we have to add a dragstart listener for no other reason that to set a flag when we're dragging, and clear it when we're done dragging.

  But then we get to another exciting browser specific problem, on Chrome mobile only ANY pointer up event also triggers a dragstart event, so on Chrome mobile the isDragging flag will always be true.
  To solve this, we need to check if the pointerup event is in the same position as the dragstart event, and if it is, we don't trigger the click event.
  Since this only needs to work for Chrome mobile, we only need to set the initial drag location for touch events, for any other browser the isDragging flag will be false and the click event will trigger as expected.
  */
  let isDragging = false;
  let initialDragPosition: {pageX: number, pageY: number} | undefined = undefined
  const onDragStartListener = (event: google.maps.MapMouseEvent) =>  {
    // We only need this for chrome mobile, which will be a touch event, everything else we can ignore because they won't trigger spurious dragstarts
    const touches = ((event.domEvent as TouchEvent)?.touches ?? [])[0];
    if(touches) {
      initialDragPosition = {pageX: touches.pageX, pageY: touches.pageY};
    }
    isDragging = true;
  };
  google.maps.event.clearListeners(marker.markerDomObject, 'dragstart');
  marker.markerDomObject.addListener('dragstart', onDragStartListener);

  const onDragEndListener = (event: google.maps.MapMouseEvent) =>  {
    isDragging = false;
    onDragEnd(element, event);
  };
  google.maps.event.clearListeners(marker.markerDomObject, 'dragend');
  marker.markerDomObject.addListener('dragend', onDragEndListener);

  const maximumClickDragDelta = 5;
  const onClickListener = (e: PointerEvent) =>  {
    if(!isDragging || (Math.abs(e.pageX - (initialDragPosition?.pageX ?? 0)) <= maximumClickDragDelta && Math.abs(e.pageY - (initialDragPosition?.pageY ?? 0)) <= maximumClickDragDelta)) {
      onClick(element);
    }
  };

  if(marker.clickEventListener) {
    marker.markerDomObject.element.removeEventListener('pointerup', marker.clickEventListener);
  }
  marker.markerDomObject.element.addEventListener('pointerup', onClickListener);
  marker.markerDomObject.element.style.cursor = 'pointer';
  return {...marker, clickEventListener: onClickListener};
}