import {FormControl, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import React from "react";

export const InputDataType = {
  ShortText: 'shortText',
  LongText: 'longText',
  Number: 'number',
  Percentage: 'percentage',
} as const;
export type InputDataType = typeof InputDataType[keyof typeof InputDataType];

export interface InputSchema {
  name: string;
  displayName?: string;
  dataType: InputDataType;
  options?: string[] | number[];
  unit?: string;
  required: boolean;
}

export const buildInput = (schema: InputSchema, valueProducer: () => (number | string), onChange: (value: string | number) => void) => {
  switch (schema.dataType) {
    case InputDataType.ShortText:
      return schema.options ? buildShortTextSelectInput(schema, valueProducer as () => string, onChange) : buildShortTextInput(schema, valueProducer as () => string, onChange);
    case InputDataType.LongText:
      return buildLongTextInput(schema, valueProducer as () => string, onChange);
    case InputDataType.Number:
      return schema.options ? buildNumberSelectInput(schema, valueProducer as () => number, onChange) : buildNumberInput(schema, valueProducer as () => number, onChange);
    case InputDataType.Percentage:
      return buildPercentageInput(schema, valueProducer as () => number, onChange);
    default:
      throw new Error(`Unknown data type: ${schema.dataType}`);
  }
}

const buildShortTextInput = (schema: InputSchema, valueProducer: () => string, onChange: (value: string) => void) => {
  return (
    <FormControl key={schema.name} fullWidth>
      <TextField
        label={schema.displayName ?? schema.name}
        variant="outlined"
        value={valueProducer()}
        onChange={e => onChange(e.target.value)}
        required={schema.required}
      />
    </FormControl>)
}

const buildShortTextSelectInput = (schema: InputSchema, valueProducer: () => string, onChange: (value: string) => void) => {
  return (
    <FormControl key={schema.name} fullWidth>
      <InputLabel>{schema.displayName ?? schema.name}</InputLabel>
      <Select
        label={schema.displayName ?? schema.name}
        value={valueProducer()}
        onChange={e => onChange(e.target.value as string)}
      >
        {generateMenuItemsFromValues(schema.options as string[], schema.unit ?? '')}
      </Select>
    </FormControl>)
}

const buildLongTextInput = (schema: InputSchema, valueProducer: () => string, onChange: (value: string) => void) => {
  return (
    <FormControl key={schema.name} fullWidth>
      <TextField
        label={schema.displayName ?? schema.name}
        variant="outlined"
        multiline
        rows={2}
        value={valueProducer()}
        onChange={e => onChange(e.target.value)}
        required={schema.required}
      />
    </FormControl>)
}

const buildNumberInput = (schema: InputSchema, valueProducer: () => number, onChange: (value: number) => void) => {
  return (
    <FormControl key={schema.name} fullWidth>
      <TextField
        label={schema.displayName ?? schema.name}
        variant="outlined"
        value={valueProducer()}
        onChange={e => onChange(parseFloat(e.target.value))}
        required={schema.required}
      />
    </FormControl>)
}

const buildNumberSelectInput = (schema: InputSchema, valueProducer: () => number, onChange: (value: number) => void) => {
  return (
    <FormControl key={schema.name} fullWidth>
      <InputLabel>{schema.displayName ?? schema.name}</InputLabel>
      <Select
        label={schema.displayName ?? schema.name}
        value={valueProducer()}
        onChange={e => onChange(e.target.value as number)}
      >
        {generateMenuItemsFromValues(schema.options as number[], ` ${schema.unit}` ?? '')}
      </Select>
    </FormControl>)
}

const buildPercentageInput = (schema: InputSchema, valueProducer: () => number, onChange: (value: number) => void) => {
  return (
  <FormControl key={schema.name} fullWidth >
    <InputLabel>{schema.displayName ?? schema.name}</InputLabel>
    <Select
      label={schema.displayName ?? schema.name}
      value={valueProducer().toString() ?? ''}
      onChange={e => onChange(parseInt(e.target.value))}
    >
      {generateMenuItemsFromValues(Array.from(Array(100).keys()).slice(0, 100), '%')}
    </Select>
  </FormControl>)
}



const generateMenuItemsFromValues = (values: number[] | string[], unit: string) => {
  const empty = <MenuItem value={''} key={'EMPTY'}></MenuItem>
  const items = values.map((value) => {
    return <MenuItem value={value} key={value}>{value}{unit}</MenuItem>
  });
  return [empty, ...items];
}
