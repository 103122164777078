import {jwtDecode, JwtPayload} from "jwt-decode";
import {NavigateFunction} from "react-router-dom";
import {AuthKeysDto} from "../dtos/auth-keys.dto";
import {logError} from "./logger.service";

export interface User {
  email: string;
}

export const getLoggedInUser = () => {
  const token = localStorage.getItem('idToken');
  if(!token) throw new Error("No ID token found in local storage");
  const decoded = jwtDecode<JwtPayload & {email: string}>(token);
  return {
    email: decoded.email
  }
}

export const getLoggedInUserOrEmpty = () => {
  const token = localStorage.getItem('idToken');
  if(!token) return null;
  const decoded = jwtDecode<JwtPayload & {email: string}>(token);
  return {
    email: decoded.email
  }
}

async function fetchKeys(navigate: NavigateFunction): Promise<AuthKeysDto> {
  const token = await getUserTokenOrRedirect(navigate)
  const keys = await fetch(
    `${process.env.REACT_APP_ACCUSITE_API_BASE_URL}/api/v1/auth/keys`,
    {headers: {Authorization: `Bearer ${token}`}})
    .then(async response => {
      if(!response.ok) {
        throw new Error(`Failed to fetch keys: ${response.statusText}`);
      }
      return await response.json() as AuthKeysDto
    });
  return keys;
}

export const requireAuth = async (navigate: NavigateFunction) => {
  await getUserTokenOrRedirect(navigate)
    .catch(e => {
      logError('Error in require auth', {}, e);
    });
}

export const getUserTokenOrRedirect = async (navigate: NavigateFunction) => {
  const token = localStorage.getItem('idToken');
  if (!token) {
    navigate('/login');
  } else {
    const decoded = jwtDecode<JwtPayload>(token);
    if((decoded?.exp ?? 0) * 1000 < Date.now()) {
      navigate('/login');
    }
  }
  return token;
}

export const getUserTokenOrThrow = () => {
  const token = localStorage.getItem('idToken');
  if(!token) {
    throw new Error("No ID token found in local storage");
  }
  return token;
}


export const getApiKeys = async (navigate: NavigateFunction): Promise<AuthKeysDto> => {
  let googleMapsApiKey = localStorage.getItem('googleMapsApiKey');
  let datadogApiKey = localStorage.getItem('datadogApiKey');
  if(googleMapsApiKey && datadogApiKey) {
    return {googleMapsApiKey, datadogApiKey}
  }
  return await fetchKeys(navigate)
    .then(keys => {
      googleMapsApiKey = keys.googleMapsApiKey;
      datadogApiKey = keys.datadogApiKey;
      localStorage.setItem('googleMapsApiKey', googleMapsApiKey);
      localStorage.setItem('datadogApiKey', datadogApiKey);
      return {googleMapsApiKey, datadogApiKey}
    })
    .catch(e => {
      logError('Error fetching keys', {}, e);
      navigate('/login');
      throw new Error("Not authenticated");
    })
}

// This is a temporary solution because of how the map JS loader works
export const getApiKeysSync = (navigate: NavigateFunction): AuthKeysDto => {
  let googleMapsApiKey = localStorage.getItem('googleMapsApiKey');
  let datadogApiKey = localStorage.getItem('datadogApiKey');
  if(googleMapsApiKey && datadogApiKey) {
    return {googleMapsApiKey, datadogApiKey}
  }
  navigate('/login');
  throw new Error("Not authenticated");
}

