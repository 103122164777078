import React, {useEffect, useState} from 'react';
import {Box, Button, FormControl, InputLabel, MenuItem, Paper, Select, Typography, Grid, Slider, SelectChangeEvent} from '@mui/material';
import {getEstimateFollowUpSchedule, saveEstimateFollowUpSchedule} from "../../shared/services/company-settings.service";
import {useNavigate} from "react-router-dom";
import {getEstimateTime} from "../estimate/estimate-time.service";
import {enqueueSnackbar} from "notistack";
import {CenteredCircularSpinner} from "../../shared/components/CenteredCircularSpinner";

const EstimateAutomaticFollowUpsPage = () => {
  const navigate = useNavigate();

  const [followUpCount, setFollowUpCount] = useState<number>(0);
  const [delays, setDelays] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    getEstimateFollowUpSchedule(navigate)
      .then((schedule) => {
        setFollowUpCount(schedule?.value.followUpCount ?? 0);
        setDelays(schedule?.value.delays ?? []);
      })
      .catch((e) => {
        console.error('Error getting follow-up schedule', e);
        enqueueSnackbar(`Error getting follow-up schedule: ${e}`, {variant: 'error', autoHideDuration: 5000});
      })
      .finally(() => setIsLoading(false));
  }, []);

  const handleFollowUpCountChange = (event: SelectChangeEvent) => {
    const count = parseInt(event.target.value);
    setFollowUpCount(count);

    if (count > delays.length) {
      setDelays([...delays, ...Array(count - delays.length).fill(1)]);
    } else {
      setDelays(delays.slice(0, count));
    }
  };

  const handleDelayChange = (index: number) => (event: Event, value: number | number[]) => {
    const newDelays = [...delays];
    newDelays[index] = value as number;
    setDelays(newDelays);
  };

  const handleSaveClick = async () => {
    await saveEstimateFollowUpSchedule(navigate, { followUpCount, delays })
      .then(() => {
        enqueueSnackbar('Follow-up schedule saved', {variant: 'success'});
      })
      .catch((e) => {
        console.error('Error saving follow-up schedule', e);
        enqueueSnackbar(`Error saving follow-up schedule: ${e}`, {variant: 'error', autoHideDuration: 5000});
      });
  };

  return (
    <Paper sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%', padding: 3 }}>
      <Box sx={{ width: '100%', maxWidth: 600, margin: '0 auto', padding: '2rem' }}>
        <Typography variant="h4" component="div" textAlign="center" gutterBottom>
          Automatic Estimate Email Follow-Ups
        </Typography>
        <Typography variant="body1" color="textSecondary" textAlign="center" gutterBottom>
          Note: Changes will only affect future schedules. Existing scheduled follow-ups will not be changed.
        </Typography>
        {isLoading ? <CenteredCircularSpinner/> : <>
          <FormControl fullWidth variant="outlined" margin="normal">
            <InputLabel id="follow-up-count-label">Number of Follow-Ups</InputLabel>
            <Select
              labelId="follow-up-count-label"
              value={followUpCount.toString()}
              onChange={handleFollowUpCountChange}
              label="Number of Follow-Ups"
            >
              {[0, 1, 2, 3, 4, 5].map((count) => (
                <MenuItem key={count} value={count}>
                  {count}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {Array.from({ length: followUpCount }, (_, index) => (
            <Box key={index} sx={{ marginTop: 4 }}>
              <Typography variant="h6">Follow-Up {index + 1}</Typography>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12}>
                  <Typography gutterBottom>Delay (Days): {delays[index]}</Typography>
                  <Slider
                    value={delays[index]}
                    onChange={handleDelayChange(index)}
                    aria-labelledby={`delay-slider-${index}`}
                    valueLabelDisplay="auto"
                    step={1}
                    marks
                    min={1}
                    max={21}
                  />
                </Grid>
              </Grid>
            </Box>
          ))}

          <Box sx={{ marginTop: 4, textAlign: 'center' }}>
            <Button variant="contained" color="primary" onClick={handleSaveClick}>
              Save Schedule
            </Button>
          </Box>
        </>}
      </Box>
    </Paper>
  );
};

export default EstimateAutomaticFollowUpsPage;