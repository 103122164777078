import React, {ChangeEvent, useEffect, useState} from "react";
import {EstimateGateDetailsDto, EstimateGateDto, UpdateEstimateGateDto} from "../../shared/dtos/estimate-gate.dto";
import {
  Button,
  Card,
  CardContent,
  CardHeader, Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  InputLabel, ListItemText, MenuItem, OutlinedInput,
  Select, SelectChangeEvent,
  Stack,
  TextField
} from "@mui/material";
import {ImagePreviews} from "../../shared/components/ImagePreviews";
import {updateImage, uploadImage} from "../../shared/services/image-service";
import {logError} from "../../shared/services/logger.service";
import {enqueueSnackbar} from "notistack";
import {deleteGate, getGate, updateGate} from "./estimate-gate.service";
import {useNavigate} from "react-router-dom";
import {DrawableImage} from "./DrawableImage";
import {CenteredCircularSpinner} from "../../shared/components/CenteredCircularSpinner";
import {EstimateTreeDto} from "../../shared/dtos/estimate-tree.dto";

export interface ImagePreview {
  id: number,
  s3Url: string,
}

export interface EstimateTreeProps {
  estimateId: number,
  estimateTrees: EstimateTreeDto[],
  treeLocalIdMap: {[key: number]: number},
  gateId: number,
  gateLocalId: number,
  onNewTree: (updatedGate: EstimateGateDetailsDto) => void,
  onFinishEstimate: (updatedGate: EstimateGateDetailsDto) => void,
  onGateDelete: () => void
}

export interface BasicAssociatedTree {
  id: number;
  localId: number;
  species: string;
}

const EstimateGateDetail: React.FC<EstimateTreeProps> = React.forwardRef((props, ref) => {
  const treeDtoToBasicTree = (tree: EstimateTreeDto): BasicAssociatedTree => ({
    id: tree.id,
    localId: props.treeLocalIdMap[tree.id],
    species: tree.species ?? 'Unknown'
  })

  const navigate = useNavigate();
  const allBasicAssociatedTrees = props.estimateTrees.map(treeDtoToBasicTree).sort((a, b) => a.localId - b.localId);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [savedGate, setSavedGate] = useState<EstimateGateDetailsDto>();
  const [updatedGate, setUpdatedGate] = useState<UpdateEstimateGateDto>();
  const [gateImagePreviews, setGateImagePreviews] = useState<ImagePreview[]>([]);

  const [basicAssociatedTrees, setBasicAssociatedTrees] = useState<BasicAssociatedTree[]>();
  const [countTreeImagesLoading, setCountTreeImagesLoading] = useState<number>(0);
  const [imageDialogIsOpen, setImageDialogIsOpen] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<ImagePreview>();
  const [imageIsSaving, setImageIsSaving] = useState<boolean>(false);

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isFinishing, setIsFinishing] = useState<boolean>(false);

  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    getGate(navigate, props.estimateId, props.gateId)
      .then(gate => {
        setSavedGate(gate);
        setUpdatedGate(estimateGateToUpdatedGate(gate));
        setGateImagePreviews(gate.images.map(image => ({
          id: image.id,
          s3Url: image.imageUrl
        })));
        setBasicAssociatedTrees(gate.associatedTrees.map(treeDtoToBasicTree));
      })
      .catch(e => {
        logError('Error getting gate', {}, e);
        enqueueSnackbar(`Error getting gate: ${e}`, {variant: 'error', autoHideDuration: 5000});
      })
      .finally(() => setIsLoading(false));
  }, [props.gateId, props.estimateId]);

  const estimateGateToUpdatedGate = (gate: EstimateGateDto): UpdateEstimateGateDto => {
    return {
      ...gate,
      gateId: gate.id,
      imageIds: gate.images.map(image => image.id),
    }
  }

  const removeGateImage = (index: number) => {
    setGateImagePreviews((prevImages) => prevImages.filter((_, i) => i !== index));
    setUpdatedGate(prevTree => {
      return {
        ...prevTree!,
        imageIds: prevTree!.imageIds?.filter((_, i) => i !== index)
      }
    });
  };

  const openImageDialog = (imagePreview: ImagePreview) => {
    setSelectedImage(imagePreview);
    setImageDialogIsOpen(true);
  };

  const updateGateImagesInState = (newImagePreviews: ImagePreview[]) => {
    setGateImagePreviews((prevImages) => [
      ...prevImages.filter(image => !newImagePreviews.map(newPreview => newPreview.id).includes(image.id)),
      ...newImagePreviews]);
    setUpdatedGate(prevGate => {
      return {
        ...prevGate!,
        imageIds: [
          ...(prevGate!.imageIds ?? []).filter(id => !newImagePreviews.map(newPreview => newPreview.id).includes(id)),
          ...newImagePreviews.map(file => file.id)]
      }
    });
  }

  const saveGateAndExecute = async (loadingSetter: (isLoading: boolean) => void, action: (tree: EstimateGateDetailsDto) => void) => {
    loadingSetter(true);
    await updateGate(navigate, savedGate!.estimateId, savedGate!.id, updatedGate!).then(newGate => {
      setSavedGate(newGate);
      setUpdatedGate(estimateGateToUpdatedGate(newGate));
      action(newGate);
    })
    .catch(e => {
      logError('Error saving gate', {}, e);
      enqueueSnackbar(`Error saving gate: ${e}`, {variant: 'error', autoHideDuration: 5000});
    })
    .finally(() => loadingSetter(false));
  }

  const handleGateImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const uploadImagePromises = Array.from(event.target.files).map(async file => {
        const result = await uploadImage(file);
        return {
          id: result.id,
          s3Url: result.imageUrl,
        }
      });

      setCountTreeImagesLoading(uploadImagePromises.length)
      Promise.all(uploadImagePromises)
        .then(uploadedImages => {
          updateGateImagesInState(uploadedImages);
        })
        .catch(e => {
          logError('Error uploading images', {}, e);
          enqueueSnackbar(`Error uploading images: ${e}`, {variant: 'error', autoHideDuration: 5000});
        })
        .finally(() => setCountTreeImagesLoading(0));
    }
  };

  const handleGateWidthChange = (event: ChangeEvent<HTMLInputElement>) => {
    setUpdatedGate(updatedGate =>({...updatedGate!, width: event.target.value }));
  };

  const formatBasicTreeForDisplay = (tree: BasicAssociatedTree) => `#${tree.localId} -- ${tree.species}`;

  const handleAssociatedTreesChange = (event: SelectChangeEvent<number[]>) => {
    const {
      target: { value },
    } = event;
    const castValue = value as number[];
    const trees = allBasicAssociatedTrees.filter(tree => castValue.includes(tree.id));
    setBasicAssociatedTrees(trees);
    setUpdatedGate(updatedGate => ({
      ...updatedGate!,
      associatedTreeIds: trees.map(tree => tree.id)
    }))
  };

  const handleDeleteGateClick = () => {
    setDeleteDialogOpen(true);
  }

  const handleDeleteGateConfirm = () => {
    setDeleteDialogOpen(false);
    setIsSaving(true);
    deleteGate(navigate, savedGate!.estimateId, savedGate!.id)
      .then(() => {
        props.onGateDelete();
      })
      .catch(e => {
        enqueueSnackbar('Error deleting gate', {variant: 'error', autoHideDuration: 5000})
        logError('Error deleting gate', {}, e);
      })
      .finally(() => {
        setIsSaving(false);
      })
  }

  const handleDeleteGateClose = () => {
    setDeleteDialogOpen(false);
  }

  const handleNewTree = async () => {
    await saveGateAndExecute(setIsSaving, props.onNewTree)
  }

  const handleFinishEstimate = async () => {
    await saveGateAndExecute(setIsFinishing, props.onFinishEstimate)
  }

  const handleGateImageUpdated = async (id: number, file: File) => {
    setImageIsSaving(true);
    await updateImage(id, file)
      .then(result => {
        const newImagePreview = {id: result.id, s3Url: result.imageUrl}
        updateGateImagesInState([newImagePreview])
        setImageDialogIsOpen(false);
      })
      .catch(e => {
        logError('Error updating image', {}, e);
        enqueueSnackbar(`Error updating image: ${e}`, {variant: 'error', autoHideDuration: 5000});
      })
      .finally(() => setImageIsSaving(false));
  }

  const handleCloseImageDialog = () => {
    setImageDialogIsOpen(false);
  };

  return (
    <Card
      sx={{ maxWidth: 600, maxHeight: '90vh', minHeight: '20vh', mx: 'auto', my: 4, overflowY: 'auto' }}>
      {isLoading ? <CenteredCircularSpinner/> : <CardContent>
        <CardHeader title={`Gate ${props.gateLocalId}`}/>
        <Stack spacing={2}>
          <ImagePreviews images={gateImagePreviews} removeImage={removeGateImage} openImageDialog={openImageDialog} countTreeImagesLoading={countTreeImagesLoading} />
          <Button variant="contained" component="label">
            Add Picture
            <input type="file" hidden multiple onChange={handleGateImageChange} />
          </Button>
          <FormControl fullWidth>
            <InputLabel id="tree-height-select-label"></InputLabel>
            <TextField
              label="Width (inches)"
              variant="outlined"
              value={updatedGate?.width ?? ''}
              onChange={handleGateWidthChange}
            />
          </FormControl>
          <FormControl>
            <InputLabel>Associated Trees</InputLabel>
            <Select
              multiple
              label="Associated Trees"
              value={basicAssociatedTrees?.map(tree => tree.id) ?? []}
              onChange={handleAssociatedTreesChange}
              input={<OutlinedInput label="Associated Trees"/>}
              renderValue={(selected) => basicAssociatedTrees?.filter(tree => selected.includes(tree.id)).map(formatBasicTreeForDisplay).join(', ')}
            >
              {allBasicAssociatedTrees.map((tree) => (
                <MenuItem key={tree.id} value={tree.id}>
                  <Checkbox checked={!!basicAssociatedTrees?.find(bat => bat.id === tree.id)} />
                  <ListItemText primary={formatBasicTreeForDisplay(tree)} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button onClick={handleDeleteGateClick} variant="contained" color="error" style={{color: 'black'}} component="label">
            {isSaving ? <CircularProgress/> : 'Delete Gate'}
          </Button>
          <Button onClick={handleNewTree} variant="contained" component="label">
            {isSaving ? <CircularProgress/> : 'Save and Add New Tree'}
          </Button>
          <Button onClick={handleFinishEstimate} variant="outlined" component="label">
            {isFinishing ? <CircularProgress/> : 'Save and Return To Estimate'}
          </Button>
        </Stack>
      </CardContent>}
      <Dialog
        open={imageDialogIsOpen}
        onClose={handleCloseImageDialog}
        PaperProps={{
          style: { maxWidth: '100%', maxHeight: '100%' },
        }}
      >
        <DrawableImage image={selectedImage!} onSave={handleGateImageUpdated} isSaving={imageIsSaving} onClose={handleCloseImageDialog} />
      </Dialog>
      <Dialog open={deleteDialogOpen} onClose={handleDeleteGateClose}>
        <DialogTitle>Delete Gate?</DialogTitle>
        <DialogActions sx={{ justifyContent: 'space-around', padding: '8px' }}>
          <Button onClick={handleDeleteGateClose} variant="contained" color="primary" style={{color: 'black'}}>
            Cancel
          </Button>
          <Button onClick={handleDeleteGateConfirm} variant="contained" color="error" style={{color: 'black'}} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  )
});

export default EstimateGateDetail;