import React, {useEffect, useRef} from 'react';
import {AppBar, Toolbar, Typography, Box, IconButton, TextField, Button, Container, Grid, Paper, FormControl, Autocomplete} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import {useNavigate} from "react-router-dom";
import genericTreeLogo from '../../assets/generic_tree_logo.png'
import {Loader} from "@googlemaps/js-api-loader";
import {logError} from "../../shared/services/logger.service";
import {enqueueSnackbar} from "notistack";
import {useToolbar} from "../../components/ToolbarContext";
import AppointmentMapComponent from "../appointments-map/AppointmentMapComponent";

const HomePage: React.FC = () => {
  const navigate = useNavigate();
  const toolbarContext = useToolbar();
  const mapRef = useRef<HTMLDivElement>(null);

  const [searchValue, setSearchValue] = React.useState<string>('');

  useEffect(() => {
    toolbarContext.setTitle('Shippy\'s Tree Service');
    toolbarContext.setCenteredContent(
      <Box display="flex" width="100%" justifyContent="center" alignItems="center">
        {/*<FormControl sx={{maxWidth: '600px', width: '100%'}}>*/}
        {/*  <Autocomplete*/}
        {/*    disablePortal*/}
        {/*    freeSolo*/}
        {/*    filterOptions={o => o}*/}
        {/*    options={[]}*/}
        {/*    value={searchValue}*/}
        {/*    onInputChange={(_, a) => setSearchValue(a)}*/}
        {/*    renderInput={(params) => <TextField {...params} label="Live Search"/>}*/}
        {/*  />*/}
        {/*</FormControl>*/}
      </Box>
    );
  }, []);

  const handleAddAppointmentClick = () => {
    navigate('/appointments?appointmentId=new');
  }

  const handleMapViewClick = () => {
    navigate('/appointments/map');
  }

  const handleEstimateAppointmentsClick = () => {
    navigate('/appointments');
  }

  const handleWorkOrdersClick = () => {
    navigate('/work-orders');
  }

  const handleViewEstimatesClick = () => {
    navigate('/estimates');
  }

  const handleViewInvoicesClick = () => {
    navigate('/invoices');
  }

  return (
    <Paper sx={{height: '100%', overflow: 'hidden'}}>
      <Grid container style={{ height: '100%' }}>
        <Grid item xs={12} md={6}>
          <Box display="flex" width="100%" paddingTop={'30px'} justifyContent="center" alignItems="center">
            <Grid container width={'400px'}>
              <Grid item xs={6}>
                <Box display="flex" width="100%" height="100%" justifyContent="center" alignItems="center">
                  <Button variant="contained" sx={{ margin: 1 }} onClick={() => handleAddAppointmentClick()}>Add Appointment</Button>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box display="flex" width="100%" height="100%" justifyContent="center" alignItems="center">
                  <Button variant="contained" sx={{ margin: 1 }} onClick={() => handleMapViewClick()}>Map View</Button>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box display="flex" width="100%" height="100%" justifyContent="center" alignItems="center">
                  <Button variant="contained" sx={{ margin: 1 }} onClick={() => handleEstimateAppointmentsClick()}>Estimate Appointments</Button>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box display="flex" width="100%" height="100%" justifyContent="center" alignItems="center">
                  <Button variant="contained" sx={{ margin: 1 }} onClick={() => handleWorkOrdersClick()}>Work Orders</Button>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box display="flex" width="100%" height="100%" justifyContent="center" alignItems="center">
                  <Button variant="contained" sx={{ margin: 1 }} onClick={() => handleViewEstimatesClick()}>View Estimates</Button>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box display="flex" width="100%" height="100%" justifyContent="center" alignItems="center">
                  <Button variant="contained" sx={{ margin: 1 }} onClick={() => handleViewInvoicesClick()}>Invoices</Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} style={{height: '100%', boxSizing: 'border-box', overflow: 'hidden'}}>
          <AppointmentMapComponent/>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default HomePage;
