import React, {ChangeEvent, FormEvent, useEffect, useState} from 'react';
import {
  Box,
  Button,
  Modal,
  Paper,
} from "@mui/material";
import {Form, useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {getApiKeys, requireAuth} from "../../shared/services/auth.service";
import {enqueueSnackbar} from "notistack";
import { datadogLogs } from '@datadog/browser-logs'
import {logError} from "../../shared/services/logger.service";
import {DataGrid, gridClasses, GridColDef, GridRowParams, GridToolbar} from '@mui/x-data-grid';
import {useToolbar} from "../../components/ToolbarContext";
import ClientDetailComponent from "./ClientDetailComponent";
import {AddClientDto, ClientDto} from "./dtos/client.dto";
import {getClient, getClients} from "./clients.service";
import {UrlFilterAwareDataGrid} from "../../shared/components/UrlFilterAwareDataGrid";

const parseParamsClientId = (paramsClientId: string | null): number | 'NEW' | undefined => {
  if(!paramsClientId) return undefined;
  if(paramsClientId.toLowerCase() == "new") return 'NEW';
  const numericValue = parseInt(paramsClientId)
  return isNaN(numericValue) ? undefined : numericValue;
}

function ClientListPage() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams()
  const toolbarContext = useToolbar();
  const initialClientId = parseParamsClientId(searchParams.get("clientId"))

  const [clients, setClients] = useState<ClientDto[]>([]);
  const [selectedClientId, setSelectedClientId] = useState<number | 'NEW' | undefined>(initialClientId);
  const [clientsAreLoading, setClientsAreLoading] = useState(false);

  useEffect(() => {
    toolbarContext.initToolbar('ClientListPage', 'Clients');
    requireAuth(navigate).then(() => fetchClients())
  }, []);

  async function fetchClients() {
    setClientsAreLoading(true);
    await getClients(navigate)
      .then(clients => setClients(clients))
      .catch(e => {
        logError('Error fetching clients', {}, e)
        enqueueSnackbar(`Error fetching clients: ${e}`, {variant: 'error', autoHideDuration: 5000});
      })
      .finally(() =>
      {
        setClientsAreLoading(false)
      });
  }

  const clientColumns: GridColDef[] = [
    { field: 'id', headerName: 'Id', width: 100 },
    { field: 'name', headerName: 'Name', width: 200 },
    { field: 'address', headerName: 'Address', width: 400 },
    { field: 'city', headerName: 'City', width: 150 },
    { field: 'state', headerName: 'State', width: 100 },
    { field: 'email', headerName: 'Email', width: 250 },
    { field: 'phoneNumber', headerName: 'Phone Number', width: 200 },
    { field: 'createdAt', headerName: 'Created At', width: 200 },
  ];

  const clientRowBuilder = (client: ClientDto) => {
    return {
      id: client.id,
      name: client.name,
      address: client.address,
      city: client.city,
      state: client.state,
      email: client.email,
      phoneNumber: client.phoneNumber,
      createdAt: client.createdAt
    }
  };

  const handleAddNewClientClick = () => {
    setSelectedClientId('NEW');
  }

  const handleRowClick = (params: GridRowParams) => {
    setSelectedClientId(params.id as number);
  };

  const handleCloseClientDetail = () => {
    setSelectedClientId(undefined);
    searchParams.delete("clientId")
    setSearchParams(searchParams);
  }

  const handleClientDetailSaved = async () => {
    setSelectedClientId(undefined);
    await fetchClients();
  }

  const handleClientDeleted = async (clientId: number) => {
    setSelectedClientId(undefined);
    await fetchClients();
  }

  return (
    <div style={{height: '100%'}}>
      <Paper sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', padding: 3}}>
        <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBottom: 2}}>
          <Button variant="contained" onClick={handleAddNewClientClick}>Add New Client</Button>
        </Box>
        <UrlFilterAwareDataGrid
          columns={clientColumns}
          rowBuilder={clientRowBuilder}
          data={clients}
          onRowClick={handleRowClick}
          loading={clientsAreLoading}
        />
      </Paper>
      <Modal
        open={!!selectedClientId}
        onClose={() => handleCloseClientDetail()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ClientDetailComponent clientId={selectedClientId === 'NEW' ? undefined : selectedClientId} onClose={handleCloseClientDetail} onSave={handleClientDetailSaved} onDelete={handleClientDeleted}/>
      </Modal>
    </div>
  );
}

export default ClientListPage;
