import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  FormControl, InputAdornment,
  Stack,
  TextField
} from "@mui/material";
import React, {ChangeEvent, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {logError, logInfo, logWarn} from "../../shared/services/logger.service";
import {enqueueSnackbar} from "notistack";
import {Loader} from "@googlemaps/js-api-loader";
import {AppointmentSummaryDto, CreateAppointmentDto, UpdateAppointmentDto} from "./dto/appointment.dto";
import {createAppointment, deleteAppointment, getAppointment, updateAppointment} from "./appointment.service";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {DateTimePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import {getLocationFromAddress} from "../../shared/misc/location-helpers";
import {getClients} from "../client/clients.service";
import {ClientDto} from "../client/dtos/client.dto";
import {Phone} from "@mui/icons-material";
import {EstimateStatus, EstimateStatusType} from "../../shared/dtos/estimate.dto";


export interface AppointmentDetailProps {
  onClose: () => void;
  onAppointmentDelete: (appointmentId: number) => void
  onSave: (appointment: AppointmentSummaryDto) => void;
  appointmentId?: number | null;
}

interface BasicClientInfo {
  id: number;
  name: string;
  address: string;
  email?: string;
  phoneNumber?: string;
}


const MAX_NAME_SUGGESTIONS = 10;
const MAX_ADDRESS_SUGGESTIONS = 10;

const AppointmentDetailComponent: React.FC<AppointmentDetailProps> = React.forwardRef((props, ref) => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [autocompleteService, setAutocompleteService] = useState<google.maps.places.AutocompleteService | null>(null);
  const [addressSuggestions, setAddressSuggestions] = useState<string[]>([]);
  const [nameSuggestionClients, setNameSuggestionClients] = useState<BasicClientInfo[]>([]);
  const [savedAppointment, setSavedAppointment] = useState<AppointmentSummaryDto | null>(null);
  const [updatedAppointment, setUpdatedAppointment] = useState<Partial<CreateAppointmentDto> | AppointmentSummaryDto>({});
  const [isFinishing, setIsFinishing] = useState<boolean>(false);

  const [clientNameInput, setClientNameInput] = useState<string>(''); // The value the user is typing in the client name field
  const [selectedClient, setSelectedClient] = useState<BasicClientInfo | string | null>(null); // The client that is selected from the autocomplete

  const [phoneNumberError, setPhoneNumberError] = useState<boolean>(false);

  useEffect(() => {
    const googleMapsLoader = new Loader({
      apiKey: "AIzaSyBLw50vYSozTEviDIpu94K1KuwM4X_hDUM",
      version: "beta",
    });
    googleMapsLoader.importLibrary("places")
      .then(places => {
        setAutocompleteService(new (places as google.maps.PlacesLibrary).AutocompleteService());
        if(props.appointmentId) {
          getAppointment(navigate, props.appointmentId)
            .then(appointment => {
              setSavedAppointment(appointment);
              setUpdatedAppointment(appointment);
              setSelectedClient({
                id: appointment.clientId,
                name: appointment.clientName,
                address: appointment.clientAddress,
                email: appointment.clientEmail,
                phoneNumber: appointment.clientPhoneNumber
              });
              logInfo('Appointment detail loaded', {appointment});
            })
            .catch(e => {
              logError('Error getting appointment', {appointmentId: props.appointmentId}, e);
              enqueueSnackbar(`Error getting appointment: ${e}`, {variant: 'error', autoHideDuration: 5000});
            })
            .finally(() => setIsLoading(false));
        } else {
          setIsLoading(false);
        }
      })
      .catch(e => {
        logError('Error importing google places library', {}, e);
        enqueueSnackbar(`Error importing google places library: ${e}`, {variant: 'error', autoHideDuration: 5000});
      });

  }, []);

  useEffect(() => {
    if(!autocompleteService || !updatedAppointment?.estimateAddress) return;

    autocompleteService.getPlacePredictions({
      input: updatedAppointment?.estimateAddress,
      componentRestrictions: {country: 'us'},
      locationBias: "IP_BIAS",
      region: 'US'
    })
      .then(response => {
        setAddressSuggestions(response.predictions.map(p => p.description).slice(0, MAX_ADDRESS_SUGGESTIONS));
      })
      .catch(e => {
        logError('Error getting address suggestions', {address: updatedAppointment?.estimateAddress}, e);
        enqueueSnackbar(`Error getting address suggestions: ${e}`, {variant: 'error', autoHideDuration: 5000});
      });
  }, [updatedAppointment?.estimateAddress]);

  useEffect(() => {
    getClients(navigate, clientNameInput ?? '')
      .then(clients => {
        setNameSuggestionClients(clients.slice(0, MAX_NAME_SUGGESTIONS));
      })
      .catch(e => {
        logError('Error getting name suggestions', {name: clientNameInput}, e);
        enqueueSnackbar(`Error getting name suggestions: ${e}`, {variant: 'error', autoHideDuration: 5000});
      });
  }, [clientNameInput]);

  const saveAndClose = async (estimateStatus?: EstimateStatusType) => {
    if(!updatedAppointment.estimateAddress || !updatedAppointment.clientName || !updatedAppointment.clientPhoneNumber) {
      enqueueSnackbar('Address, Client Name, and Client Phone Number are required', {variant: 'error', autoHideDuration: 5000});
      logWarn('Time, Estimate, and Client Name, Email, and Phone Number are required', {appointment: updatedAppointment});
      return;
    }

    const location = await getLocationFromAddress(updatedAppointment.estimateAddress).catch(e => {
      logError('Error getting location from address', {address: updatedAppointment.estimateAddress}, e);
      enqueueSnackbar(`Error getting location from address: ${e}`, {variant: 'error', autoHideDuration: 5000});
      return null;
    });
    if(!location) return;

    const estimateLocation = {
      estimateLat: location.lat,
      estimateLng: location.lng,
      estimateCity: location.city,
      estimateState: location.state,
      estimatePostalCode: location.postalCode
    };
    const newAppointment = {...updatedAppointment, ...estimateLocation, estimateStatus};
    setUpdatedAppointment(newAppointment);

    setIsFinishing(true);
    try {
      const returnedAppointment = (savedAppointment as AppointmentSummaryDto)?.id
        ? await updateAppointment(navigate, (savedAppointment as AppointmentSummaryDto).id, newAppointment)
        : await createAppointment(navigate, newAppointment as CreateAppointmentDto);
      props.onSave(returnedAppointment);
      logInfo('Appointment saved', {appointment: returnedAppointment});
      return returnedAppointment;
    }
    catch {
      logError('Failed to save appointment', {appointment: newAppointment});
      enqueueSnackbar('Failed to save appointment', {variant: 'error', autoHideDuration: 5000});
    }
    finally {
      setIsFinishing(false);
      props.onClose();
    }
  }

  const isBasicClientInfo = (client: BasicClientInfo | string): client is BasicClientInfo => (client as BasicClientInfo).id !== undefined;
  const formatClientForAutoComplete = (input: BasicClientInfo| string) => isBasicClientInfo(input) ? `${input.name} (${input.email})` : input;
  const capitalizeName = (name: string) => name.split(' ').map(n => n.charAt(0).toUpperCase() + n.slice(1)).join(' ');

  const handleTimeChange = (time: dayjs.Dayjs | null) => {
    setUpdatedAppointment(client => ({...client!, time: time?.toDate()}));
  };

  // This is called ONLY when the user selects a client from the autocomplete
  const handleClientNameChange = (client: BasicClientInfo | string | null) => {
    setSelectedClient(client);
    if(client && isBasicClientInfo(client)) {
      setUpdatedAppointment(appointment => (
        {...appointment!,
          clientId: client.id,
          clientName: client.name,
          clientEmail: client.email,
          clientPhoneNumber: client.phoneNumber,
          estimateAddress: client.address
        }));
    } else {
      setUpdatedAppointment(appointment => ({...appointment!, clientId: undefined, clientName: client} as CreateAppointmentDto));
    }
  };

  const handleClientNameInputChange = (input: string) => {
    const capitalizedInput = capitalizeName(input);
    setClientNameInput(capitalizedInput);
    if(!isBasicClientInfo(selectedClient ?? '') || formatClientForAutoComplete((selectedClient as BasicClientInfo)) != capitalizedInput) {
      handleClientNameChange(capitalizedInput); // If the user didn't select a value from the auto complete, we need to set the selected client
    }
  };

  const handleClientEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setUpdatedAppointment(appointment => ({...appointment!, clientEmail: event.target.value ?? undefined}));
  };

  const formatPhoneNumber = (value: string) => {
    const cleaned = ('' + value).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
    if (match) {
      const part1 = match[1] ? `(${match[1]}` : '';
      const part2 = match[2] ? `) ${match[2]}` : '';
      const part3 = match[3] ? `-${match[3]}` : '';
      return `${part1}${part2}${part3}`;
    }
    return value;
  };

  const handleClientPhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const formattedPhoneNumber = formatPhoneNumber(value);
    setUpdatedAppointment(appointment => ({...appointment!, clientPhoneNumber: formattedPhoneNumber}));

    const cleaned = ('' + formattedPhoneNumber).replace(/\D/g, '');
    setPhoneNumberError(cleaned.length > 10);
  };

  const handleAddressChange = (address: string) => {
    setUpdatedAppointment(appointment => ({...appointment!, estimateAddress: address ?? undefined}));
  };

  const handleNotesChange = (event: ChangeEvent<HTMLInputElement>) => {
    setUpdatedAppointment(appointment => ({...appointment!, notes: event.target.value ?? undefined}));
  };

  const handleSaveClick = async () => {
    saveAndClose();
  }

  const handleStartEstimateClick = () => {
    const estimateId = (updatedAppointment as AppointmentSummaryDto)?.estimateId;
    if(!estimateId) return;

    saveAndClose(EstimateStatus.EstimateInProgress).then((res) => {
      if(!res) return;
      navigate(`/estimates/${estimateId}`)
    });
  }

  const handleDeleteAppointmentClick = async () => {
    if(!savedAppointment?.id) {
      props.onClose();
    }
    setIsFinishing(true);
    deleteAppointment(navigate, savedAppointment!.id)
      .then(() => {
        props.onAppointmentDelete(savedAppointment!.id);
        props.onClose();
        logInfo('Appointment deleted', {appointmentId: savedAppointment?.id});
      })
      .catch(e => {
        logError('Failed to delete appointment', {appointmentId: savedAppointment?.id}, e);
        enqueueSnackbar('Failed to delete appointment', {variant: 'error', autoHideDuration: 5000});
      })
      .finally(() => setIsFinishing(false));
  }

  return (
    isLoading ? <CircularProgress/> :
      <Card
        sx={{ maxWidth: 600, maxHeight: '90vh', mx: 'auto', my: 4, overflowY: 'auto' }}>
        <CardContent>
          <CardHeader title={savedAppointment?.id ? 'Appointment Details' : 'Add Appointment'}/>
          <Stack spacing={2}>
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker value={updatedAppointment?.time ? dayjs(updatedAppointment?.time) : null} onChange={(newValue) => handleTimeChange(newValue)} label="Appointment Date" />
              </LocalizationProvider>
            </FormControl>
            <FormControl fullWidth aria-autocomplete="none">
              <Autocomplete
                disablePortal
                freeSolo
                filterOptions={o => o}
                options={nameSuggestionClients}
                getOptionLabel={(option) => formatClientForAutoComplete(option)}
                getOptionKey={(option) => (option as ClientDto).id ?? option}
                value={selectedClient}
                onChange={(e, c) => handleClientNameChange(c)}
                onInputChange={(_, input) => handleClientNameInputChange(input)}
                renderInput={(params) =>{
                  params.inputProps.autoComplete = 'new-password';
                  return <TextField {...params} label="Name *"/>
                }}
              />
            </FormControl>
            <FormControl fullWidth aria-autocomplete="none">
              <TextField
                label="Client Email"
                variant="outlined"
                required={false}
                value={updatedAppointment?.clientEmail ?? ''}
                onChange={handleClientEmailChange}
                autoComplete={"new-password"}
              />
            </FormControl>
            <FormControl fullWidth aria-autocomplete="none">
              <TextField
                label="Client Phone Number"
                variant="outlined"
                required={true}
                value={updatedAppointment?.clientPhoneNumber ?? ''}
                onChange={handleClientPhoneChange}
                error={phoneNumberError}
                helperText={phoneNumberError ? 'Please enter a valid phone number' : ''}
                InputProps={{
                  inputMode: 'numeric'
                }}
                autoComplete={"new-password"}
              />
            </FormControl>
            <FormControl fullWidth aria-autocomplete="none">
              <Autocomplete
                disablePortal
                freeSolo
                filterOptions={o => o}
                options={addressSuggestions}
                value={updatedAppointment?.estimateAddress ?? ''}
                onInputChange={(_, a) => handleAddressChange(a)}
                renderInput={(params) =>{
                  params.inputProps.autoComplete = 'new-password';
                  return <TextField {...params} label="Address *"/>
                }}
              />
            </FormControl>
            <FormControl fullWidth aria-autocomplete="none">
              <TextField
                label="Office Notes"
                variant="outlined"
                multiline
                rows={4}
                value={updatedAppointment?.notes ?? ''}
                onChange={handleNotesChange}
                autoComplete={"new-password"}
              />
            </FormControl>
            <Button onClick={handleSaveClick} variant="contained" component="label">
              {isFinishing ? <CircularProgress/> : 'Save'}
            </Button>
            {(updatedAppointment as AppointmentSummaryDto).estimateId ?
            <Button onClick={handleStartEstimateClick} variant="outlined" component="label">
              {isFinishing ? <CircularProgress/> : 'Start Estimate'}
            </Button> : <></>}
            {(updatedAppointment as AppointmentSummaryDto).estimateId ?
              <Button onClick={handleDeleteAppointmentClick} variant="contained" color="error" style={{color: 'black'}} component="label">
                {isFinishing ? <CircularProgress/> : 'Delete Appointment'}
              </Button> : <></>}
          </Stack>
        </CardContent>
      </Card>
  )
});

export default AppointmentDetailComponent;