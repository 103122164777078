import {AddEstimateTreeDto, EstimateTreeDto, UpdateEstimateTreeDto} from "../../shared/dtos/estimate-tree.dto";
import {EstimateTreeOperationDto, InputEstimateTreeOperationDto, TreeOperationType} from "../../shared/dtos/estimate-tree-operation.dto";
import {NavigateFunction} from "react-router-dom";
import {getUserTokenOrRedirect} from "../../shared/services/auth.service";
import {InputDataType, InputSchema} from "../../shared/services/schema-based-inputs.service";
import {EstimateTreeEquipmentDto, UpdateEstimateTreeEquipmentDto} from "../../shared/dtos/estimate-tree-equipment.dto";

export async function createTree(navigate: NavigateFunction, estimateId: number, tree: AddEstimateTreeDto ) {
  const token = await getUserTokenOrRedirect(navigate)
  const createdTree = await fetch(
    `${process.env.REACT_APP_ACCUSITE_API_BASE_URL}/api/v1/estimates/${estimateId}/trees`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(tree),
    }).then(async r => {
      if (!r.ok) {
        throw new Error(`Failed to create tree: ${r.statusText}`);
      }
      return (await r.json()) as EstimateTreeDto
    });
  return createdTree;
}

export async function getTree(navigate: NavigateFunction, estimateId: number, treeId: number): Promise<EstimateTreeDto> {
  const token = await getUserTokenOrRedirect(navigate)
  const tree = await fetch(
    `${process.env.REACT_APP_ACCUSITE_API_BASE_URL}/api/v1/estimates/${estimateId}/trees/${treeId}`,
    {headers: {Authorization: `Bearer ${token}`}})
    .then(async r => r.ok ? (await r.json()) as EstimateTreeDto : Promise.reject(r));
  return tree;
}

export async function updateTree(navigate: NavigateFunction, estimateId: number, treeId: number, tree: UpdateEstimateTreeDto): Promise<EstimateTreeDto> {
  const token = await getUserTokenOrRedirect(navigate)
  const response = await fetch(
    `${process.env.REACT_APP_ACCUSITE_API_BASE_URL}/api/v1/estimates/${estimateId}/trees/${treeId}`,
    {
        method: 'PATCH',
        body: JSON.stringify(tree),
        headers:
        {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
    })
    .then(async r => r.ok ? (await r.json()) as EstimateTreeDto : Promise.reject(r));

  return response;
}

export async function deleteTree(navigate: NavigateFunction, estimateId: number, treeId: number): Promise<void> {
  const token = await getUserTokenOrRedirect(navigate)
  const response = await fetch(
    `${process.env.REACT_APP_ACCUSITE_API_BASE_URL}/api/v1/estimates/${estimateId}/trees/${treeId}`,
    {
      method: 'DELETE',
      headers:
        {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
    });
  if(!response.ok) {
    throw new Error(`Failed to delete tree: ${response.statusText}`);
  }
}

export async function getTreeOperations(navigate: NavigateFunction, estimateId: number, treeId: number): Promise<EstimateTreeOperationDto[]> {
  const token = await getUserTokenOrRedirect(navigate)
  const operations = await fetch(
    `${process.env.REACT_APP_ACCUSITE_API_BASE_URL}/api/v1/estimates/${estimateId}/trees/${treeId}/operations`,
    {headers: {Authorization: `Bearer ${token}`}})
    .then(async r => r.ok ? (await r.json()) as EstimateTreeOperationDto[] : Promise.reject(r));

  return operations;
}

export async function createTreeOperation(navigate: NavigateFunction, estimateId: number, treeId: number, operation: InputEstimateTreeOperationDto): Promise<EstimateTreeOperationDto> {
  const token = await getUserTokenOrRedirect(navigate)
  const createdOperation = await fetch(
    `${process.env.REACT_APP_ACCUSITE_API_BASE_URL}/api/v1/estimates/${estimateId}/trees/${treeId}/operations`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(operation),
  }).then(async r => r.ok ? (await r.json()) as EstimateTreeOperationDto : Promise.reject(r));

  return createdOperation;
}

export async function updateTreeOperation(navigate: NavigateFunction, estimateId: number, treeId: number, operationId: number,  operation: InputEstimateTreeOperationDto): Promise<EstimateTreeOperationDto> {
  const token = await getUserTokenOrRedirect(navigate)
  const createdOperation = await fetch(
    `${process.env.REACT_APP_ACCUSITE_API_BASE_URL}/api/v1/estimates/${estimateId}/trees/${treeId}/operations/${operationId}`,
    {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(operation),
    }).then(async r => r.ok ? (await r.json()) as EstimateTreeOperationDto : Promise.reject(r));
  return createdOperation;
}

export async function deleteTreeOperation(navigate: NavigateFunction, estimateId: number, treeId: number, operationId: number): Promise<void> {
  const token = await getUserTokenOrRedirect(navigate)
  const response = await fetch(
    `${process.env.REACT_APP_ACCUSITE_API_BASE_URL}/api/v1/estimates/${estimateId}/trees/${treeId}/operations/${operationId}`,
    {
      method: 'DELETE',
      headers:
        {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
    })
  if(!response.ok) {
    throw new Error(`Failed to delete operation: ${response.statusText}`);
  }
}

export async function getTreeEquipment(navigate: NavigateFunction, estimateId: number, treeId: number): Promise<EstimateTreeEquipmentDto[]> {
  const token = await getUserTokenOrRedirect(navigate)
  const equipment = await fetch(
    `${process.env.REACT_APP_ACCUSITE_API_BASE_URL}/api/v1/estimates/${estimateId}/trees/${treeId}/equipment`,
    {headers: {Authorization: `Bearer ${token}`}})
    .then(async r => r.ok ? (await r.json()) as EstimateTreeEquipmentDto[] : Promise.reject(r));

  return equipment;
}

export async function updateTreeEquipment(navigate: NavigateFunction, estimateId: number, treeId: number, equipment: UpdateEstimateTreeEquipmentDto[]): Promise<EstimateTreeEquipmentDto[]> {
  const token = await getUserTokenOrRedirect(navigate)
  const updatedEquipment = await fetch(
    `${process.env.REACT_APP_ACCUSITE_API_BASE_URL}/api/v1/estimates/${estimateId}/trees/${treeId}/equipment`,
    {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({equipment}),
    }).then(async r => r.ok ? (await r.json()) as EstimateTreeEquipmentDto[] : Promise.reject(r));

  return updatedEquipment;
}

export const TreeOperationSchema: Record<TreeOperationType, InputSchema[]> = {
  [TreeOperationType.Thinning]: [
    {name: 'thinningPercentage', displayName: 'Thinning Percentage', dataType: InputDataType.Percentage, required: true},
  ],
  [TreeOperationType.Deadwood_Removal]: [
    {name: 'deadwoodPercentage', displayName: 'Deadwood Percentage', dataType: InputDataType.Percentage, required: true},
  ],
  [TreeOperationType.Custom_Trim_Prune]: [
    {name: 'notes', displayName: 'Notes', dataType: InputDataType.LongText, required: false},
  ],
  [TreeOperationType.Crown_Reduction]: [
    {name: 'crownReductionFeet', displayName: 'Reduction', dataType: InputDataType.Number, required: true, unit: 'feet', options: Array.from(Array(100).keys()).slice(1, 100)},
  ],
  [TreeOperationType.Tree_Removal]: [],
  [TreeOperationType.Stump_Grinding]: [
    {name: 'grindingDepthInches', displayName: 'Grinding Depth', dataType: 'number', required: true, unit: 'inches', options: Array.from(Array(25).keys()).slice(0, 25)},
    {name: 'grindingRadius', displayName: 'Grinding Radius', dataType: InputDataType.ShortText, required: true, options: ['All Surface Roots', ...Array.from(Array(121).keys()).slice(1, 121).map(i => `${i} inches`)]},
  ],
  [TreeOperationType.Cabling]: [],
  [TreeOperationType.Debris_Management]: [
    {name: 'notes', displayName: 'Notes', dataType: InputDataType.LongText, required: false},
  ],
  [TreeOperationType.Ground_To_Canopy_Clearance]: [
    {name: 'groundToCrownClearanceFeet', displayName: 'Clearance', dataType: InputDataType.Number, required: true, unit: 'feet', options: Array.from(Array(100).keys()).slice(1, 100)},
  ]
}