import React, {ChangeEvent, FormEvent, useEffect, useState} from 'react';
import {
  Box,
  Button, Chip,
  Modal,
  Paper,
} from "@mui/material";
import {Form, useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {getApiKeys, requireAuth} from "../../shared/services/auth.service";
import {enqueueSnackbar} from "notistack";
import {logError} from "../../shared/services/logger.service";
import {
  GridColDef,
  GridRowParams,
} from '@mui/x-data-grid';
import {useToolbar} from "../../components/ToolbarContext";
import {AppointmentSummaryDto} from "./dto/appointment.dto";
import {getAppointments} from "./appointment.service";
import AppointmentDetailComponent from "./AppointmentDetailComponent";
import { format } from 'date-fns';
import {UrlFilterAwareDataGrid} from "../../shared/components/UrlFilterAwareDataGrid";
import {isDateInCurrentWeek} from "../../shared/misc/data-helpers";

export interface Filter<T> {
  displayName: string;
  urlParam: string;
  filterFunction: (values: T[]) => T[];
}

const todayFilter: Filter<AppointmentSummaryDto> = {
  displayName: 'Today',
  urlParam: 'today',
  filterFunction: (values: AppointmentSummaryDto[]) => {
    return values.filter(appointment => new Date(appointment.time).toDateString() == new Date().toDateString());
  }
}

const thisWeekFilter: Filter<AppointmentSummaryDto> = {
  displayName: 'This Week',
  urlParam: 'thisWeek',
  filterFunction: (values: AppointmentSummaryDto[]) => {
    return values.filter(appointment => isDateInCurrentWeek(appointment.time));
  }
}

const showAllFilter: Filter<AppointmentSummaryDto> = {
  displayName: 'All',
  urlParam: 'showAll',
  filterFunction: (values: AppointmentSummaryDto[]) => values
}

const allFilters = [showAllFilter, todayFilter, thisWeekFilter];

const defaultFilter = showAllFilter;

const parseParamsAppointmentId = (paramsAppointmentId: string | null): number | 'NEW' | undefined => {
  if(!paramsAppointmentId) return undefined;
  if(paramsAppointmentId.toLowerCase() == "new") return 'NEW';
  const numericValue = parseInt(paramsAppointmentId)
  return isNaN(numericValue) ? undefined : numericValue;
}

function AppointmentListPage() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams()
  const toolbarContext = useToolbar();
  const initialAppointmentId = parseParamsAppointmentId(searchParams.get("appointmentId"))

  const [allAppointments, setAllAppointments] = useState<AppointmentSummaryDto[]>([]);
  const [selectedAppointmentId, setSelectedAppointmentId] = useState<number | 'NEW' | undefined>(initialAppointmentId);
  const [appointmentsAreLoading, setAppointmentsAreLoading] = useState(false);
  const [filters, setFilters] = useState<Filter<AppointmentSummaryDto>[]>([]);

  useEffect(() => {
    toolbarContext.initToolbar('AppointmentListPage', 'Estimate Appointments');
    requireAuth(navigate)
      .then(() => fetchAppointments())
      .catch(e => {
        logError('Error requiring auth', {}, e)
        enqueueSnackbar(`Error requiring auth: ${e}`, {variant: 'error', autoHideDuration: 5000});
      });
  }, []);

  async function fetchAppointments() {
    setAppointmentsAreLoading(true);
    await getAppointments(navigate)
      .then(appointments => { setAllAppointments(appointments) })
      .catch(e => {
        logError('Error fetching allAppointments', {}, e)
        enqueueSnackbar(`Error fetching appointments: ${e}`, {variant: 'error', autoHideDuration: 5000});
      })
      .finally(() =>
      {
        setAppointmentsAreLoading(false)
      });
  }

  const appointmentColumns: GridColDef[] = [
    { field: 'id', headerName: 'Id', width: 100 },
    { field: 'clientName', headerName: 'Client', width: 200 },
    { field: 'clientEmail', headerName: 'Client Email', width: 250 },
    { field: 'clientPhoneNumber', headerName: 'Client Phone #', width: 150 },
    {
      field: 'time',
      headerName: 'Time',
      width: 300,
      valueGetter: (value): Date | null => value ? new Date(value) : null,
      renderCell: (params): React.ReactNode => (
        <span>{params.value ? format(new Date(params.value), 'PPpp') : ''}</span>
      ),
    },
    { field: 'address', headerName: 'Address', width: 300 },
    { field: 'city', headerName: 'City', width: 150 },
    { field: 'state', headerName: 'State', width: 100 },
    { field: 'notes', headerName: 'Notes', width: 250 },
    { field: 'createdAt', headerName: 'Created At', width: 300 },
  ];

  const appointmentRowBuilder = (appointment: AppointmentSummaryDto) => {
    return {
      id: appointment.id,
      clientName: appointment.clientName,
      clientPhoneNumber: appointment.clientPhoneNumber,
      clientEmail: appointment.clientEmail,
      time: appointment.time,
      address: appointment.estimateAddress,
      city: appointment.estimateCity,
      state: appointment.estimateState,
      notes: appointment.notes,
      createdAt: appointment.createdAt
    }
  };

  const handleAddNewAppointmentClick = () => {
    setSelectedAppointmentId('NEW');
  }

  const handleRowClick = (params: GridRowParams) => {
    setSelectedAppointmentId(params.id as number);
  };

  const handleCloseAppointmentDetail = () => {
    setSelectedAppointmentId(undefined);
    searchParams.delete("appointmentId")
    setSearchParams(searchParams);
  }

  const handleAppointmentDetailSaved = async () => {
    setSelectedAppointmentId(undefined);
    await fetchAppointments();
  }

  const handleAppointmentDeleted = async () => {
    setSelectedAppointmentId(undefined);
    await fetchAppointments();
  }

  return (
    <div style={{height: '100%'}}>
      <Paper sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', padding: 3}}>
        <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBottom: 2}}>
          <Button variant="contained" onClick={handleAddNewAppointmentClick}>Add New Appointment</Button>
        </Box>
        <UrlFilterAwareDataGrid
          columns={appointmentColumns}
          rowBuilder={appointmentRowBuilder}
          data={allAppointments}
          onRowClick={handleRowClick}
          allFilters={allFilters}
          defaultFilters={[defaultFilter]}
        />
      </Paper>
      <Modal
        open={!!selectedAppointmentId}
        onClose={() => handleCloseAppointmentDetail()}
      >
        <AppointmentDetailComponent
          appointmentId={selectedAppointmentId === 'NEW' ? undefined : selectedAppointmentId}
          onClose={() => handleCloseAppointmentDetail()}
          onSave={() => handleAppointmentDetailSaved()}
          onAppointmentDelete={() => handleAppointmentDeleted()}
        />
      </Modal>
    </div>
  );
}

export default AppointmentListPage;
