import {HttpTransportType, HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel} from "@microsoft/signalr";
import {getUserTokenOrThrow} from "./auth.service";

// This interface needs to be kept in sync with IClientSupportedInboundMessages in RealTimeHub.cs in the API
export interface SupportedInboundMessageRegistrations {
  onMeasuredTreeImageCreated(callback: (estimateId: number, treeId: number, storedImageId: number, s3Url: string, widthFeet: number, heightFeet: number) => void): void;
  onMeasuredTrunkImageCreated(callback: (estimateId: number, treeId: number, storedImageId: number, s3Url: string, diameterInches: number) => void): void;
}

class RealTimeService implements SupportedInboundMessageRegistrations {
  private static instance: RealTimeService;
  private connection: HubConnection | undefined;

  private onMeasuredTreeImageCreatedCallback: (estimateId: number, treeId: number, storedImageId: number, s3Url: string, widthFeet: number, heightFeet: number) => void = () => {};
  private onMeasuredTrunkImageCreatedCallback: (estimateId: number, treeId: number, storedImageId: number, s3Url: string, diameterInches: number) => void = () => {};

  private constructor() {
    try {
      console.log('Creating RealTimeService');
      const token = getUserTokenOrThrow();
      this.connection = new HubConnectionBuilder()
        .withUrl(process.env.REACT_APP_ACCUSITE_API_BASE_URL + '/real-time', {
          accessTokenFactory: () => token
        })
        .withAutomaticReconnect([0, 2000, 5000, 10000])
        .build();
      console.log('Created RealTimeService');


      this.connection.on('MeasuredTreeImageCreated', (estimateId: number, treeId: number, storedImageId: number, s3Url: string, widthFeet: number, heightFeet: number) => {
        console.log('MeasuredTreeImageCreated', estimateId, treeId, storedImageId, s3Url, widthFeet, heightFeet);
        this.onMeasuredTreeImageCreatedCallback(estimateId, treeId, storedImageId, s3Url, widthFeet, heightFeet);
      });

      this.connection.on('MeasuredTrunkImageCreated', (estimateId: number, treeId: number, storedImageId: number, s3Url: string, diameterInches: number) => {
        console.log('MeasuredTrunkImageCreated', estimateId, treeId, storedImageId, s3Url, diameterInches);
        this.onMeasuredTrunkImageCreatedCallback(estimateId, treeId, storedImageId, s3Url, diameterInches);
      });

      this.startConnection(this.connection);
    }
    catch (e) {
      console.error('Error creating RealTimeService', e);
    }
  }

  public static getInstance(): RealTimeService {
    if (!(RealTimeService.instance?.isConnected() ?? false)) {
      RealTimeService.instance = new RealTimeService();
    }
    return RealTimeService.instance;
  }

  private startConnection(connection: HubConnection) {
    if (connection.state === HubConnectionState.Disconnected) {
      connection.start()
        .then(() => {
          console.log(`Connection started, state: ${connection.state}`);
        })
        .catch(e => {
          console.log('Connection failed: ', e);
        });

      connection.onclose(error => {
        console.error("Connection lost:", error);
      });
    }
  }

  public isConnected(): boolean {
    return this.connection?.state === HubConnectionState.Connected;
  }

  public sendMessage(user: string, message: string) {
    if(!this.connection) {
      console.error('Connection not established');
      return;
    }
    this.connection.invoke('SendMessage', user, message)
      .then(() => console.log('Message sent'))
      .catch(err => console.error(err));
  }

  public onMeasuredTreeImageCreated(callback: (estimateId: number, treeId: number, storedImageId: number, s3Url: string, widthFeet: number, heightFeet: number) => void) {
    this.onMeasuredTreeImageCreatedCallback = callback;
  }

  public onMeasuredTrunkImageCreated(callback: (estimateId: number, treeId: number, storedImageId: number, s3Url: string, diameterInches: number) => void) {
    this.onMeasuredTrunkImageCreatedCallback = callback;
  }
}

export default RealTimeService;