// LocationPermissionsContext.tsx
import React, {createContext, useContext, useState, useEffect, ReactNode} from 'react';
import {enqueueSnackbar} from "notistack";
import {logError, logInfo} from "../shared/services/logger.service";

interface LocationPermissionsContextType {
  hasLocationPermission: boolean;
  locationPermissionLoading: boolean;
  checkLocationPermissions: () => Promise<void>;
  requestLocationPermissions: () => Promise<void>;
}

const LocationPermissionsContext = createContext<LocationPermissionsContextType | undefined>(undefined);

export const LocationPermissionsProvider: React.FC<{ children: ReactNode }> = ({children}) => {
  const [hasLocationPermission, setHasLocationPermission] = useState(false);
  const [locationPermissionLoading, setLocationPermissionLoading] = useState(true);

  const checkLocationPermissions = async () => {
    try {
      setLocationPermissionLoading(true);
      const status = await navigator.permissions.query({ name: 'geolocation' });
      setHasLocationPermission(status.state === 'granted');
      status.onchange = () => {
        logInfo('Location permission status changed:', {state: status.state});
        setHasLocationPermission(status.state === 'granted');
      };
    } catch (error) {
      logError('Error checking location permissions:', undefined, error as Error);
      enqueueSnackbar('Error checking location permissions', {variant: 'error', autoHideDuration: 5000});
    } finally {
      setLocationPermissionLoading(false);
    }
  };

  const requestLocationPermissions = async () => {
    try {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setHasLocationPermission(true);
        },
        (error) => {
          logError('Error requesting location permissions:',error);
          setHasLocationPermission(false);
        }
      );
    } catch (error) {
      logError('Error requesting location permissions:', undefined, error as Error);
    }
  };

  useEffect(() => {
    checkLocationPermissions();
  }, []);

  return (
    <LocationPermissionsContext.Provider value={{ hasLocationPermission, locationPermissionLoading, checkLocationPermissions, requestLocationPermissions }}>
      {children}
    </LocationPermissionsContext.Provider>
  );
};

export const useLocationPermissions = (): LocationPermissionsContextType => {
  const context = useContext(LocationPermissionsContext);
  if (!context) {
    throw new Error('useLocationPermissions must be used within a LocationPermissionsProvider');
  }
  return context;
};
