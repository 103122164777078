import React, {useEffect, useState} from 'react';
import {
  Modal,
  Box,
  Typography,
  Button,
  IconButton,
  Grid,
  MenuItem,
  TextField, Card, CardHeader, CardContent, Fab, CircularProgress,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import {getAllEquipment, getEquipment} from "../equipment/equipment.service";
import {useNavigate} from "react-router-dom";
import {EquipmentDto} from "../equipment/dtos/equipment.dto";
import {getTreeEquipment, updateTreeEquipment} from "./estimate-tree.service";
import {enqueueSnackbar} from "notistack";
import {CenteredCircularSpinner} from "../../shared/components/CenteredCircularSpinner";

interface BasicEquipmentWithDuration {
  equipmentId: number;
  duration: string;
}

interface EditTreeSpecialEquipmentProps {
  onClose: () => void;
  estimateId: number;
  treeId: number;
}

const EditTreeSpecialEquipment: React.FC<EditTreeSpecialEquipmentProps> = React.forwardRef(({onClose, estimateId, treeId}, ref) => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [equipment, setEquipment] = useState<BasicEquipmentWithDuration[]>([]);
  const [allEquipment, setAllEquipment] = useState<EquipmentDto[]>([]);

  const convertMinutesToDuration = (minutes: number): string => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours}:${remainingMinutes.toString().padStart(2, '0')}`;
  }

  const convertDurationToMinutes = (duration: string): number => {
    const [hours, minutes] = duration.split(':').map(Number);
    return hours * 60 + minutes;
  }

  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      getAllEquipment(navigate).then((equipment) => {
        setAllEquipment(equipment.sort((a, b) => a.id - b.id));
      }),
      getTreeEquipment(navigate, estimateId, treeId).then((e) => {
        setEquipment(e.sort((a, b) => a.id - b.id).map(e => ({equipmentId: e.equipmentId, duration: convertMinutesToDuration(e.durationMinutes)})));
      })
    ])
    .catch(e => {
      console.error('Error loading equipment', e);
      enqueueSnackbar(`Error loading equipment: ${e}`, {variant: 'error', autoHideDuration: 5000})
    })
    .finally(() => setIsLoading(false));
  }, []);

  const durationOptions = Array.from(
    { length: 36 },
    (_, i) => {
      return convertMinutesToDuration(i * 15);
    }
  );

  const formatEquipment = (equipment?: EquipmentDto): string => {
    if(!equipment) return '';
    const details = equipment.details as {make?: string, model?: string};
    const nickname = equipment?.nickname ?? `${equipment?.nickname} ` ?? '';
    const make = details?.make ?? `${details.make} ` ?? '';
    const model = details?.model ?? `${details.model} ` ?? '';
    const detailString = nickname || make || model ? `(${nickname}${make}${model})`: '';
    return `${equipment.type} ${detailString}`;
  }

  const handleAddEquipmentClick = () => {
    const nextEquipmentId = allEquipment.find(e => !equipment.find(e2 => e2.equipmentId === e.id))?.id ?? -1;
    setEquipment([
      ...equipment,
      { equipmentId: nextEquipmentId, duration: '0:00' },
    ]);
  };

  const handleDeleteEquipmentClick = (index: number) => {
    setEquipment(equipment.filter((equipment, i) => i !== index));
  };

  const getAvailableEquipment = (selectedEquipmentId?: number) => allEquipment.filter(ae => !equipment.find(e => e.equipmentId === ae.id) || ae.id === (selectedEquipmentId ?? -1));

  const handleEquipmentChange = (equipmentId: number, value: number) => {
    setEquipment(
      equipment.map((equipment) =>
        equipment.equipmentId === equipmentId
          ? { ...equipment, equipmentId: value }
          : equipment
      )
    );
  }

  const handleChange = (
    index: number,
    field: keyof BasicEquipmentWithDuration,
    value: string
  ) => {
    setEquipment(
      equipment.map((equipment, i) =>
        i === index
          ? { ...equipment, [field]: value }
          : equipment
      )
    );
  };

  const handleSave = () => {
    const equipmentData = equipment.map(e => ({equipmentId: e.equipmentId, durationMinutes: convertDurationToMinutes(e.duration)}));
    setIsSaving(true);
    updateTreeEquipment(navigate, estimateId, treeId, equipmentData)
      .then(() => onClose())
      .catch(e => {
        console.error('Error saving equipment', e);
        enqueueSnackbar(`Error saving equipment: ${e}`, {variant: 'error', autoHideDuration: 5000})
      })
      .finally(() => setIsSaving(false));
  };

  return (
    <Card sx={{ maxWidth: 600, maxHeight: '90vh', minHeight: '20vh', mx: 'auto', my: 4, overflowY: 'auto' }}>
      <CardContent>
        <CardHeader title={'Edit Special Equipment'}/>
        {isLoading ? <CenteredCircularSpinner/> :
        <Box>
          {equipment.map((equipment, rowIndex) => (
            <Grid container spacing={2} key={rowIndex} alignItems="center" style={{padding: '10px'}}>
              <Grid item xs={5}>
                <TextField
                  select
                  label="Equipment Name"
                  fullWidth
                  value={equipment.equipmentId}
                  onChange={(e) =>
                    handleEquipmentChange(equipment.equipmentId, parseInt(e.target.value))
                  }
                >
                  {getAvailableEquipment(equipment.equipmentId).map((equipment, equipmentSelectIndex) => (
                    <MenuItem key={equipmentSelectIndex} value={equipment.id}>
                      {formatEquipment(equipment)}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={5}>
                <TextField
                  select
                  label="Duration (H:MM)"
                  fullWidth
                  value={equipment.duration}
                  onChange={(e) =>
                    handleChange(rowIndex, 'duration', e.target.value)
                  }
                >
                  {durationOptions.map((duration) => (
                    <MenuItem key={duration} value={duration}>
                      {duration}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={2}>
                <IconButton
                  onClick={() => handleDeleteEquipmentClick(rowIndex)}
                  color="error"
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          ))}
          <Box style={{width: '100%', paddingTop: '20px'}}>
            <Button disabled={getAvailableEquipment().length === 0} onClick={handleAddEquipmentClick} style={{width: '100%', marginBottom: '10px'}} variant="contained" component="label">
              {isSaving ? <CenteredCircularSpinner/> : 'Add Equipment'}
            </Button>
            <Button onClick={handleSave} style={{width: '100%'}} variant="contained" component="label">
              {isSaving ? <CenteredCircularSpinner/> : 'Save'}
            </Button>
          </Box>
        </Box>}
      </CardContent>
    </Card>
  );
});

export default EditTreeSpecialEquipment;
