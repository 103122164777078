import {DataGrid, gridClasses, GridColDef, GridRowParams, GridToolbarContainer, GridToolbarQuickFilter} from "@mui/x-data-grid";
import {Box, Chip} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Filter} from "../../pages/appointment/AppointmentListPage";
import {useSearchParams} from "react-router-dom";

export interface DataGridFilter<T> {
  displayName: string;
  urlParam: string;
  filterFunction: (values: T[]) => T[];
}

const FILTERS_PARAM = 'filters';

export interface UrlParamAwareDataGrid<T> {
  columns: GridColDef[];
  rowBuilder: (data: T) => any;
  data: T[]
  loading?: boolean;
  onRowClick?: (params: GridRowParams) => void;
  allFilters?: DataGridFilter<T>[];
  defaultFilters?: DataGridFilter<T>[];
  mutuallyExclusive?: boolean;
  showCheckboxes?: boolean;
  isRowCheckboxEnabled?: ((row: T) => boolean);
  onCheckboxSelectionChange?: (selectedRowIds: (number | string)[]) => void;
  disableMultipleRowSelection?: boolean;
}

export const UrlFilterAwareDataGrid = <T,>(props: UrlParamAwareDataGrid<T>) => {
  const columns = props.columns;
  const rowBuilder = props.rowBuilder;
  const initialData = props.data;
  const loading = props.loading ?? false;
  const onRowClick = props.onRowClick ?? (() => {});
  const allFilters = props.allFilters ?? [];
  const defaultFilters = props.defaultFilters ?? [];
  const mutuallyExclusiveFilters = props.mutuallyExclusive ?? true;
  const showCheckBoxes = props.showCheckboxes ?? false;
  const isRowCheckboxEnabled = props.isRowCheckboxEnabled ?? (() => true);
  const onCheckboxSelectionChange = props.onCheckboxSelectionChange ?? (() => {});

  const [searchParams, setSearchParams] = useSearchParams()
  const [allData, setAllData] = useState<T[]>(initialData)
  const [filteredData, setFilteredData] = useState<T[]>(initialData)
  const [filters, setFilters] = useState<Filter<T>[]>(defaultFilters);

  const rows = filteredData.map(rowBuilder);

  const getFiltersFromParam = (filterParam: string | null): Filter<T>[] => {
    const paramFilters = allFilters.filter(f => filterParam?.toLowerCase().split(',')?.includes(f.urlParam.toLowerCase()))
    return paramFilters.length > 0 ? paramFilters : defaultFilters;
  }

  useEffect(() => {
    const newFilters = getFiltersFromParam(searchParams.get("filters"))
    setFilters(newFilters);
  }, [searchParams]);

  useEffect(() => {
    setAllData(props.data);
  }, [props.data]);

  useEffect(() => {
    const newFilteredData = filters.reduce((appointments, filter) => filter.filterFunction(appointments), allData);
    setFilteredData(newFilteredData);
  }, [allData, filters]);

  const filterSelect = (filter: Filter<T>) => () => {
    if(!mutuallyExclusiveFilters) {
      throw new Error('Non-mutually exclusive filters not yet implemented');
    }
    setFilters([filter]); // Filters are mutually exclusive
    searchParams.delete(FILTERS_PARAM)
    searchParams.set(FILTERS_PARAM, filter.urlParam)
    setSearchParams(searchParams);
  }

  const UrlParamAwareDataGridToolbar = () =>
    <GridToolbarContainer>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <Box sx={{ display: 'flex', gap: 1 }}>
          {allFilters.map(filter => (
            <Chip
              key={filter.displayName}
              label={filter.displayName}
              color={filters.find(f => f.displayName == filter.displayName) ? 'primary' : 'default'}
              onClick={filterSelect(filter)}
            />
          ))}
        </Box>
        <div style={{flexGrow: 1}}></div>
        <GridToolbarQuickFilter />
      </Box>
    </GridToolbarContainer>

  return (
    <div style={{height: '95%', width: '100%', cursor: "pointer"}}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {page: 0, pageSize: 50},
          },
        }}
        sx={{
          [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
            outline: 'none',
          },
          [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
            {
              outline: 'none',
            },
        }}
        slots={{
          toolbar: () => <UrlParamAwareDataGridToolbar/>,
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            printOptions: {disableToolbarButton: true},
            csvOptions: {disableToolbarButton: true},
          },
        }}
        loading={loading}
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        pageSizeOptions={[10, 25, 50, 100]}
        disableRowSelectionOnClick
        onRowClick={onRowClick}
        checkboxSelection={showCheckBoxes}
        onRowSelectionModelChange={onCheckboxSelectionChange}
        isRowSelectable={(params) => isRowCheckboxEnabled(params.row as T)}
        disableMultipleRowSelection={props.disableMultipleRowSelection ?? false}
      />
    </div>
  )

}
