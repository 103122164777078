import {Card, CardActions, CardContent, CardMedia, IconButton, Stack} from "@mui/material";
import {Delete} from "@mui/icons-material";
import {CenteredCircularSpinner} from "./CenteredCircularSpinner";
import React from "react";
import {ImagePreview} from "../../pages/estimate/EstimateTreeDetailComponent";

export interface ImagePreviewsProps {
  images: ImagePreview[],
  removeImage: (index: number) => void,
  openImageDialog: (imagePreview: ImagePreview) => void,
  countTreeImagesLoading: number,
}

export const ImagePreviews = (props: ImagePreviewsProps) => {
  return(
    <Stack direction="row" spacing={1}>
      {props.images.map((imagePreview, index) => (
        <Card key={index} sx={{ maxWidth: 100 }}>
          <CardMedia
            component="img"
            height="100"
            image={imagePreview.s3Url}
            alt="Tree preview"
            onClick={() => props.openImageDialog(imagePreview)}
            sx={{ cursor: 'pointer' }}
          />
          <CardActions>
            <IconButton size="small" onClick={() => props.removeImage(index)}>
              <Delete fontSize="inherit" />
            </IconButton>
          </CardActions>
        </Card>))}
      {[...Array(props.countTreeImagesLoading)].map((_, index) => (
        <Card key={index+100} sx={{ maxWidth: 100 }}>
          <CardContent sx={{ width: 100, height: 100 }}>
            <CenteredCircularSpinner/>
          </CardContent>
          <CardActions>
            <IconButton size="small">
              <Delete fontSize="inherit" />
            </IconButton>
          </CardActions>
        </Card>
      ))}
    </Stack>)
}