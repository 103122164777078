import React, { useEffect } from "react";

export interface RedirectProps {
  url: string;
}

const Redirect = ( props: RedirectProps ) => {
  const { url } = props
  useEffect(() => {
    console.log('Redirecting to: ', url);
    window.location.replace(url);
  }, [url]);

  return <h5>Redirecting...</h5>;
};

export default Redirect;