import React, {useEffect, useState} from "react";
import './AppointmentMapPage.css';

import {useToolbar} from "../../components/ToolbarContext";
import AppointmentMapComponent from "./AppointmentMapComponent";

const AppointmentMapPage = () => {
  const toolbarContext = useToolbar();

  useEffect(() => {
    toolbarContext.initToolbar('AppointmentMapPage', 'Appointments Map');
  }, []);

  return (
    <div className="full-page">
      <AppointmentMapComponent/>
    </div>
  );
}

export default AppointmentMapPage;