import {NavigateFunction} from "react-router-dom";
import {getUserTokenOrRedirect} from "../../shared/services/auth.service";
import {
  AddEstimateAdditionalComponentDto,
  EstimateAdditionalComponentDto,
  UpdateEstimateAdditionalComponentDto
} from "../../shared/dtos/estimate-additional-component.dto";

export async function createAdditionalComponent(navigate: NavigateFunction, estimateId: number, additionalComponent: AddEstimateAdditionalComponentDto) {
  const token = await getUserTokenOrRedirect(navigate)
  const createdAdditionalComponent = await fetch(
    `${process.env.REACT_APP_ACCUSITE_API_BASE_URL}/api/v1/estimates/${estimateId}/additional_components`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(additionalComponent),
    }).then(async r => {
      if (!r.ok) {
        throw new Error(`Failed to create estimate additional component: ${r.statusText}`);
      }
      return (await r.json()) as EstimateAdditionalComponentDto
  });
  return createdAdditionalComponent;
}

export async function updateAdditionalComponent(navigate: NavigateFunction, estimateId: number, additionalComponentId: number, additionalComponent: UpdateEstimateAdditionalComponentDto): Promise<EstimateAdditionalComponentDto> {
  const token = await getUserTokenOrRedirect(navigate)
  const response = await fetch(
    `${process.env.REACT_APP_ACCUSITE_API_BASE_URL}/api/v1/estimates/${estimateId}/additional_components/${additionalComponentId}`,
    {
      method: 'PATCH',
      body: JSON.stringify(additionalComponent),
      headers:
        {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
    })
    .then(async r => r.ok ? (await r.json()) as EstimateAdditionalComponentDto : Promise.reject(r));

  return response;
}

export async function deleteAdditionalComponent(navigate: NavigateFunction, estimateId: number, additionalComponentId: number): Promise<void> {
  const token = await getUserTokenOrRedirect(navigate)
  const response = await fetch(
    `${process.env.REACT_APP_ACCUSITE_API_BASE_URL}/api/v1/estimates/${estimateId}/additional_components/${additionalComponentId}`,
    {
      method: 'DELETE',
      headers:
        {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
    })
  if(!response.ok) {
    throw new Error(`Failed to delete additional component: ${response.statusText}`);
  }
}