import {NavigateFunction} from "react-router-dom";
import {getUserTokenOrRedirect} from "../../shared/services/auth.service";
import {AddEstimateDragPathDto, EstimateDragPathDto, UpdateEstimateDragPathDto} from "../../shared/dtos/estimate-drag-path.dto";

export async function createDragPath(navigate: NavigateFunction, estimateId: number, dragPath: AddEstimateDragPathDto) {
  const token = await getUserTokenOrRedirect(navigate)
  const createdDragPath = await fetch(
    `${process.env.REACT_APP_ACCUSITE_API_BASE_URL}/api/v1/estimates/${estimateId}/drag_paths`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(dragPath),
    }).then(async r => r.ok ? (await r.json()) as EstimateDragPathDto : Promise.reject(r));
  return createdDragPath;
}

export async function updateDragPath(navigate: NavigateFunction, estimateId: number, dragPathId: number, dragPath: UpdateEstimateDragPathDto) {
  const token = await getUserTokenOrRedirect(navigate)
  const response = await fetch(
    `${process.env.REACT_APP_ACCUSITE_API_BASE_URL}/api/v1/estimates/${estimateId}/drag_paths/${dragPathId}`,
    {
      method: 'PATCH',
      body: JSON.stringify(dragPath),
      headers:
        {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
    })
    .then(async r => r.ok ? (await r.json()) as EstimateDragPathDto : Promise.reject(r));

  return response;
}

export async function deleteDragPath(navigate: NavigateFunction, estimateId: number, dragPathId: number) {
  const token = await getUserTokenOrRedirect(navigate)
  const response = await fetch(
    `${process.env.REACT_APP_ACCUSITE_API_BASE_URL}/api/v1/estimates/${estimateId}/drag_paths/${dragPathId}`,
    {
      method: 'DELETE',
      headers:
        {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
    });

  if(!response.ok) {
    throw new Error(`Failed to delete drag path: ${response.statusText}`);
  }
}