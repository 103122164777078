import React, {LegacyRef, useEffect, useRef, useState} from "react";
import {logError} from "../../shared/services/logger.service";
import {enqueueSnackbar} from "notistack";
import {
  Box,
  Button,
  CircularProgress,
  Collapse, Dialog,
  DialogActions,
  DialogContent, DialogContentText, DialogTitle,
  IconButton,
  Paper,
  Slider,
  TextField,
  Tooltip,
  Typography,
  useTheme
} from "@mui/material";
import {ImagePreview} from "./EstimateTreeDetailComponent";
import {ArrowBackIos, ArrowForwardIos, Circle, Redo, RestartAlt, Undo} from "@mui/icons-material";
import './DrawableImage.css';
import { alpha } from "@mui/material";
import {CenteredCircularSpinner} from "../../shared/components/CenteredCircularSpinner";
import {resetImage} from "../../shared/services/image-service";

export interface DrawableImageProps {
  image: ImagePreview,
  onClose: () => void,
  onSave?: (id: number, file: File) => void,
  isSaving?: boolean,
  readonly?: boolean
}

const colors = ['blue', 'black', 'red', 'green', 'purple'];
const strokeMarks = [
  {value: 5, label: 'Thin'},
  {value: 15, label: ''},
  {value: 25, label: ''},
  {value: 35, label: ''},
  {value: 45, label: 'Thick'},
];

interface TextBox {
  id: number;
  x: number;
  y: number;
  text: string;
  isEditing: boolean;
}

export const DrawableImage = (props: DrawableImageProps) => {
  const onSave = props.onSave ?? (() => {});
  const isSaving = props.isSaving ?? false;
  const readonly = props.readonly ?? false;

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isDrawing, setIsDrawing] = useState(false);
  const [isDrawingMenuOpen, setIsDrawingMenuOpen] = useState(true);
  const [displayOpenDrawingMenuButton, setDisplayOpenDrawingMenuButton] = useState(false);
  const [selectedStrokeColor, setSelectedStrokeColor] = useState<string>(colors[0]);
  const [selectedStrokeWidth, setSelectedStrokeWidth] = useState<number>(strokeMarks[2].value);
  const [imageDataHistoryStack, setImageDataHistoryStack] = useState<ImageData[]>([]);
  const [imageDataRedoStack, setImageDataRedoStack] = useState<ImageData[]>([]);
  const [confirmResetDialogOpen, setConfirmResetDialogOpen] = useState<boolean>(false);
  const theme = useTheme();

  useEffect(() => {
    const canvas = getCanvasOrThrow();
    const context = get2dContextOrThrow();
    const image = new Image();
    image.src = props.image.s3Url + '?t=' + new Date().getTime(); // Add query param to prevent caching, see: https://stackoverflow.com/questions/55158189/cross-origin-requests-ajax-requests-to-aws-s3-sometimes-result-in-cors-error
    image.crossOrigin = 'anonymous';
    image.onload = () => {
      canvas.width = image.width;
      canvas.height = image.height;
      context.drawImage(image, 0, 0);
      setImageDataHistoryStack([canvasToImageData(canvas)]);
      setIsLoading(false);
    };
    image.onerror = (error) => {
      logError('Error loading image', {image: props.image, error});
      enqueueSnackbar('Error loading image', {variant: 'error', autoHideDuration: 5000});
    }
  }, []);

  const scaleCoordinates = (x: number, y: number) => {
    const canvas = getCanvasOrThrow();
    const rect = canvas.getBoundingClientRect();

    const scaleX = canvas.width / rect.width; // Scale factor: width
    const scaleY = canvas.height / rect.height; // Scale factor: height

    return {
      x: (x  - rect.left) * scaleX,
      y: (y - rect.top) * scaleY,
    };
  }

  const getMousePosition = (e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
    return scaleCoordinates(e.clientX, e.clientY);
  };

  const getTouchPosition = (e: React.TouchEvent<HTMLCanvasElement>) => {
    return scaleCoordinates(e.touches[0].clientX, e.touches[0].clientY);
  };

  const getCanvasOrThrow = (): HTMLCanvasElement => {
    if(!canvasRef.current) {
      logError('Canvas not found');
      throw new Error('Canvas not found');
    }
    return canvasRef.current
  }

  const get2dContextOrThrow = (): CanvasRenderingContext2D => {
    const canvas = getCanvasOrThrow();
    const context = canvas.getContext('2d')!;
    if(!context) {
      logError('Canvas context not found');
      throw new Error('Canvas context not found');
    }
    return context;
  }

  const normalizeStrokeWidth = (width: number) => {
    const normalAverageDimensionPx = 1500;
    const canvas = getCanvasOrThrow();
    const averageDimensionPx = (canvas.width + canvas.height) / 2;
    return (width / normalAverageDimensionPx) * averageDimensionPx;
  };

  const startDrawing = (x: number, y: number) => {
    const context = get2dContextOrThrow();

    context.strokeStyle = selectedStrokeColor;
    context.lineWidth = normalizeStrokeWidth(selectedStrokeWidth);
    context.beginPath(); // Begin the drawing path
    context.moveTo(x, y);
    setIsDrawing(true);
  };

  const draw = (x: number, y: number) => {
    if (!isDrawing) return;
    const context = get2dContextOrThrow();

    context.lineTo(x , y);
    context.stroke(); // Render the line
  }

  const canvasToImageData = (canvas: HTMLCanvasElement): ImageData => {
      return canvas.getContext('2d')!.getImageData(0, 0, canvas.width, canvas.height);
  }

  const stopDrawing = () => {
    if(!isDrawing) return;
    const canvas = getCanvasOrThrow();
    setImageDataHistoryStack([...imageDataHistoryStack, canvasToImageData(canvas)]);
    setImageDataRedoStack([]);

    const context = get2dContextOrThrow();
    context.closePath(); // Close the current path
    setIsDrawing(false);
  };

  const startMouseDrawing = (e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
    const {x, y} = getMousePosition(e);
    startDrawing(x, y);
  }

  const startTouchDrawing = (e: React.TouchEvent<HTMLCanvasElement>) => {
    const {x, y} = getTouchPosition(e);
    startDrawing(x, y);
  }

  const mouseDraw = (e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
    const {x, y} = getMousePosition(e);
    draw(x, y);
  };

  const touchDraw = (e: React.TouchEvent<HTMLCanvasElement>) => {
    const {x, y} = getTouchPosition(e);
    draw(x, y);
  };

  //TODO: This async approach is a hack I threw together in 15 minutes to offload the perf problems from creating a data URL, we should at minimum more robustly handle the errors
  const undoCanvas = async () => {
    const currentImageData = imageDataHistoryStack.pop();
    const previousImageData = imageDataHistoryStack[imageDataHistoryStack.length - 1];
    try {
      if(!currentImageData || !previousImageData) {
        logError('Attempted to undo with no previous image data')
        enqueueSnackbar('Nothing to undo', {variant: 'info', autoHideDuration: 5000})
        return;
      }
      loadImage(previousImageData);
      setImageDataRedoStack([...imageDataRedoStack, currentImageData]);
    } catch (e: unknown) {
      logError('Error undoing canvas', {}, e as Error);
      enqueueSnackbar('Error undoing canvas', {variant: 'error', autoHideDuration: 5000});
    }
  }

  const redoCanvas = () => {
    const imageData = imageDataRedoStack.pop();
    if(!imageData) {
      logError('Attempted to redo when redo stack is empty')
      enqueueSnackbar('Nothing to redo', {variant: 'info', autoHideDuration: 5000})
      return;
    }
    loadImage(imageData)
    setImageDataHistoryStack([...imageDataHistoryStack, imageData]);
  }

  const loadFromUrl = (url: string, onLoad?: () => void) => {
    if(!url) {
      logError('Attempted to load an empty url')
      return;
    }
    const canvas = getCanvasOrThrow();
    const context = get2dContextOrThrow();
    const image = new Image();
    image.src = url;
    image.crossOrigin = 'anonymous';
    image.onload = () => {
      context.clearRect(0, 0, canvas.width, canvas.height);
      context.drawImage(image, 0, 0);
      onLoad?.apply(null);
    };
    image.onerror = (error) => {
      logError('Error loading image', {image: props.image, error});
      enqueueSnackbar('Error loading image', {variant: 'error', autoHideDuration: 5000});
    }
  }

  const loadImage = (image: ImageData) => {
    if(!image) {
      logError('Attempted to load an empty url')
      return;
    }
    const context = get2dContextOrThrow();
    context.putImageData(image, 0, 0);
  }

  const handleCloseDrawingMenu = () => setIsDrawingMenuOpen(false);

  const handleDrawingWindowClosed = () => setDisplayOpenDrawingMenuButton(true);

  const handleOpenDrawingMenu = () => {
    setDisplayOpenDrawingMenuButton(false);
    setIsDrawingMenuOpen(true);
  }

  const handleColorSelect = (color: string) => {
    setSelectedStrokeColor(color);
  };

  const handleStrokeWidthChange = (event: Event, newValue: number | number[]) => {
    if(typeof newValue !== 'number') return;
    setSelectedStrokeWidth(newValue);
  };

  const canUndo = () => imageDataHistoryStack.length > 1;

  const handleUndo = () => {
    undoCanvas();
  }

  const canRedo = () => imageDataRedoStack.length > 0;

  const handleRedo = () => {
    redoCanvas();
  }

  const handleSave = () => {
    const canvas = getCanvasOrThrow();
    try {
      canvas.toBlob(blob => {
        if(!blob) {
          logError('No blob found saving image');
          return;
        }
        const file = new File([blob], 'drawn-image.jpg', {type: 'image/jpeg'});
        onSave(props.image.id, file);
      }, 'image/jpeg');
    } catch (e) {
      logError(`Error saving image: ${e}`);
      enqueueSnackbar(`Error saving image: ${e}`, {variant: 'error', autoHideDuration: 5000})
    }
  };

  const handleClose = () => {
    props.onClose();
  }

  const [text, setText] = useState('');
  const [textPosition, setTextPosition] = useState<{ x: number; y: number } | null>(null);

  const handleCanvasClick = (event: React.MouseEvent) => {
    const rect = (event.target as HTMLCanvasElement).getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    setTextPosition({ x, y });
  };

  const handleTextSubmit = () => {
    const canvas = getCanvasOrThrow();
    if (textPosition) {
      const context = canvas.getContext('2d');
      if (context) {
        context.font = '16px Roboto, sans-serif';
        context.fillStyle = '#000';
        context.fillText(text, textPosition.x, textPosition.y);
        setText('');
        setTextPosition(null);
      }
    }
  };

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value);
  };

  const resetImageAndLoad = async () => {
    await resetImage(props.image.id)
      .then(image => loadFromUrl(image.imageUrl))
      .catch(e => {
        logError('Error resetting image', {image: props.image}, e);
        enqueueSnackbar('Error resetting image', {variant: 'error', autoHideDuration: 5000});
      });
  }

  const handleResetImage = () => {
    setConfirmResetDialogOpen(true);
  }

  const handleCloseConfirmImageReset = () => {
    setConfirmResetDialogOpen(false);
  }

  const handleConfirmImageResetDialogCancel = () => {
    setConfirmResetDialogOpen(false);
  }

  const handleConfirmImageResetDialogConfirm = async () => {
    await resetImageAndLoad();
    setConfirmResetDialogOpen(false);
  }

  // We really shouldn't have the dialog components here, but this is the easy way to make the buttons work
  return (
    <>
      <Dialog
        open={confirmResetDialogOpen}
        onClose={handleCloseConfirmImageReset}
      >
        <DialogTitle id="confirmation-dialog-title">Reset Image?</DialogTitle>
        <DialogContent>
          <DialogContentText id="confirmation-dialog-description">
            Do you want to reset the image to its original state? This will delete all drawings.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmImageResetDialogCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmImageResetDialogConfirm} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <DialogContent className="drawable-image-dialog">
        <div className="canvas-container">
          {/*{textPosition && (*/}
          {/*  <TextField*/}
          {/*    className="text-field"*/}
          {/*    style={{ left: textPosition.x, top: textPosition.y }}*/}
          {/*    value={text}*/}
          {/*    onChange={handleTextChange}*/}
          {/*    onBlur={handleTextSubmit}*/}
          {/*    autoFocus*/}
          {/*  />*/}
          {/*)}*/}
          <canvas
            id='drawable-image-canvas'
            className={"canvas"}
            ref={canvasRef}
            onMouseDown={startMouseDrawing}
            onMouseMove={mouseDraw}
            onMouseUp={stopDrawing}
            onMouseOut={stopDrawing}
            onTouchMove={touchDraw}
            onTouchStart={startTouchDrawing}
            onTouchEnd={stopDrawing}
            onClick={handleCanvasClick}
          ></canvas>
        </div>
        {isLoading ? <CenteredCircularSpinner className="loader-overlay"/> :
          readonly ? <></> :
          <div className='drawing-menu-overlay'>
            <Collapse orientation="horizontal" in={isDrawingMenuOpen} onExited={handleDrawingWindowClosed}>
              <div>

                <Paper square elevation={3} className="undo-redo-overlay" sx={{
                  p: 1,
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 1,
                  alignItems: 'center',
                  width: 'fit-content',
                  backgroundColor: alpha(theme.palette.background.paper, 0.6)
                }}>
                  <IconButton disabled={!canUndo()} onClick={handleUndo} className="overlaid-control">
                    <Undo fontSize="large"/>
                  </IconButton>
                  <IconButton disabled={!canRedo()} onClick={handleRedo} className="overlaid-control">
                    <Redo fontSize="large"/>
                  </IconButton>
                  <IconButton onClick={handleResetImage} className="overlaid-control">
                    <RestartAlt fontSize="large"/>
                  </IconButton>
                </Paper>
              </div>
              <div className="drawing-palette-overlay">
                <Paper square elevation={3} sx={{
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                  alignItems: 'center',
                  width: 'fit-content',
                  padding: '5px',
                  backgroundColor: alpha(theme.palette.background.paper, 0.6)
                }}>
                  <Box sx={{display: 'flex', justifyContent: 'space-around', gap: 1, alignItems: 'center' }}>
                    <Box sx={{display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'center'}}>
                      <Slider
                        step={null}
                        marks={strokeMarks}
                        min={strokeMarks[0].value}
                        max={strokeMarks[strokeMarks.length - 1].value}
                        valueLabelDisplay="auto"
                        onChange={handleStrokeWidthChange}
                        value={selectedStrokeWidth}
                        sx={{width: 200}}
                        className="overlaid-control"
                      />
                      <Box sx={{display: 'flex', gap: 1}}>
                        {colors.map((color) => (
                          <Tooltip title={color} key={color}>
                            <IconButton
                              onClick={() => handleColorSelect(color)}
                              sx={{bgcolor: color, '&:hover': {bgcolor: color}, ...(selectedStrokeColor === color && {border: '2px solid black'})}}
                              className="overlaid-control">
                              <Circle sx={{color: color === selectedStrokeColor ? 'white' : color}}/>
                            </IconButton>
                          </Tooltip>
                        ))}
                      </Box>
                    </Box>
                    <Box sx={{display: 'flex', direction: 'column', alignItems: 'flex-end', height: '102px' }}>
                      <div>
                        <IconButton onClick={handleCloseDrawingMenu} className="overlaid-control">
                          <ArrowBackIos fontSize="small"/>
                        </IconButton>
                      </div>
                    </Box>
                  </Box>
                </Paper>
              </div>

            </Collapse>
            {displayOpenDrawingMenuButton && <IconButton onClick={handleOpenDrawingMenu} className="overlaid-control">
              <ArrowForwardIos fontSize="small"/>
            </IconButton>}
          </div>
        }
      </DialogContent>
      <DialogActions>
        {readonly ? <></> : <Button onClick={handleSave}>{isSaving ? <CircularProgress size={25}/> : 'Save'}</Button>}
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </>)
    ;
}