import {logError} from "../services/logger.service";
import {enqueueSnackbar} from "notistack";

export interface LocationInfo {
  lat: number;
  lng: number;
  city: string;
  state: string;
  postalCode: string;
}

interface AddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}

export const getLocationFromAddress = async (address: string): Promise<LocationInfo> => {
  try {
    let res = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=AIzaSyBLw50vYSozTEviDIpu94K1KuwM4X_hDUM`);
    let data: any = await res.json();
    const firstResult = data.results[0];
    const location = firstResult.geometry.location;
    const city = firstResult.address_components.find((c: AddressComponent) => c.types.includes('locality'))?.long_name ?? 'UNKNOWN';
    const state = firstResult.address_components.find((c: AddressComponent) => c.types.includes('administrative_area_level_1'))?.short_name ?? 'UNKNOWN';
    const postalCode = firstResult.address_components.find((c: AddressComponent) => c.types.includes('postal_code'))?.long_name;
    const postalCodeSuffix = firstResult.address_components.find((c: AddressComponent) => c.types.includes('postal_code_suffix'))?.long_name;
    const fullPostalCode = (postalCodeSuffix ? `${postalCode}-${postalCodeSuffix}` : postalCode) ?? 'UNKNOWN';
    return {
      lat: location.lat as number,
      lng: location.lng as number,
      city,
      state,
      postalCode: fullPostalCode
    };
  } catch (e: any) {
    logError('Error getting location from address', {}, e)
    enqueueSnackbar(`Error getting location from address: ${e}`, {variant: 'error', autoHideDuration: 5000});
    throw e;
  }
}