import {StoredImageDto} from "./stored-image.dto";

export interface EstimateTreeOperationDto {
  id: number;
  treeId: number;
  operationType: TreeOperationType;
  thinningPercentage?: number;
  deadwoodPercentage?: number;
  stumpGrindingDepthInches?: number;
  estimatedCost?: number;
  details: {[key: string]: string | number};
  notes?: undefined;
  images: StoredImageDto[];
  createdBy: number;
  createdAt: string;
  updatedAt: string;
}

export interface InputEstimateTreeOperationDto {
  id?: number;
  treeId: number;
  operationType: TreeOperationType;
  thinningPercentage?: number;
  deadwoodPercentage?: number;
  stumpGrindingDepthInches?: number;
  estimatedCost?: number;
  details: {[key: string]: string | number | undefined};
  notes?: string | undefined;
  imageIds?: number[];
}

export const TreeOperationType = {
  Thinning: 'Thinning',
  Deadwood_Removal: 'Deadwood Removal',
  Custom_Trim_Prune: 'Custom Trim/Prune',
  Crown_Reduction: 'Crown Reduction',
  Tree_Removal: 'Tree Removal',
  Stump_Grinding: 'Stump Grinding',
  Cabling: 'Cabling',
  Debris_Management: 'Debris Management',
  Ground_To_Canopy_Clearance: 'Ground to Canopy Clearance',
} as const;
export type TreeOperationType = typeof TreeOperationType[keyof typeof TreeOperationType];
