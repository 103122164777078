import React, {ReactNode} from 'react';
import {Navigate, Route} from 'react-router-dom';
import {logInfo} from "../shared/services/logger.service";

interface AuthGuardProps {
  children: React.ReactNode;
}

const AuthGuard: React.FC<AuthGuardProps> = ({ children }) => {
  const isAuthenticated = () => {
    const isAuthenticated = !!localStorage.getItem('idToken');
    return isAuthenticated;
  };

  if (!isAuthenticated()) {
    logInfo('User is not authenticated, redirecting to login');
    return <Navigate to="/login" replace/>;
  }

  return <>{children}</>;
};

export default AuthGuard;