import {Box, CircularProgress, Theme} from "@mui/material";
import React from "react";
import {SxProps} from "@mui/system/styleFunctionSx";

export interface CenteredCircularSpinnerProps {
  className?: string;
  sx?: SxProps<Theme>;
  color?: "inherit" | "primary" | "secondary" | "error" | "info" | "success" | "warning" | undefined;
  size?: number | string;
}

export const CenteredCircularSpinner = (props: CenteredCircularSpinnerProps) => {
  const defaultSx = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%'
  };

  return (
  <Box className={props.className} sx={{...defaultSx, ...props.sx}}>
    <CircularProgress size={props.size} color={props.color}/>
  </Box>)
}