import {EstimateWithComponentsDto} from "../../shared/dtos/estimate-with-components.dto";
import React, {useEffect, useState} from "react";
import {
  Box, Button,
  Card,
  CardContent,
  CardHeader, CircularProgress,
  FormControl,
  InputLabel, MenuItem, Select, SelectChangeEvent,
  Stack,
  TextField,
} from "@mui/material";
import {updateEstimate} from "./estimate.service";
import {useNavigate} from "react-router-dom";
import {logError} from "../../shared/services/logger.service";
import {enqueueSnackbar} from "notistack";
import {EstimateStatus, EstimateStatusType} from "../../shared/dtos/estimate.dto";
import {getEstimateTime, startNewEstimateTime, updateEstimateTime} from "./estimate-time.service";


export interface EstimateDetailProps {
  estimate: EstimateWithComponentsDto;
  onEstimateSaved: (estimate: EstimateWithComponentsDto) => void;
}

const EstimateDetail: React.FC<EstimateDetailProps> = React.forwardRef((props, ref) => {
  const navigate = useNavigate();

  const [isSaving, setIsSaving] = React.useState(false);
  const [changedEstimate, setChangedEstimate] = React.useState<EstimateWithComponentsDto>(props.estimate);
  const [savedTimeId, setSavedTimeId] = React.useState<number | undefined>(undefined);
  const [durationString, setDurationString] = useState('');

  useEffect(() => {
    getEstimateTime(navigate, props.estimate.id)
      .then((estimateTime) => {
        setSavedTimeId(estimateTime?.id);
        setDurationString(formatElapsedTime(estimateTime?.elapsedSeconds ?? 0).split(':').slice(0, 2).join(':'));
      })
      .catch(e => {
        logError('Error getting estimate time', {estimateId: props.estimate.id}, e);
        enqueueSnackbar(`Error getting estimate time: ${e}`, {variant: 'error', autoHideDuration: 5000});
      })
  }, []);

  const formatElapsedTime = (totalElapsedSeconds: number) => {
    const seconds = Math.floor(totalElapsedSeconds % 60);
    const minutes = Math.floor((totalElapsedSeconds / 60) % 60);
    const hours = Math.floor((totalElapsedSeconds / (60 * 60)) % 24);

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  const saveDuration = () => {
    const splitTime = durationString.split(':');
    if(splitTime.length !== 2) {
      enqueueSnackbar('Invalid time format', {variant: 'error'});
      return;
    }
    const hours = parseInt(durationString.split(':')[0]);
    const minutes = parseInt(durationString.split(':')[1]);
    if(isNaN(hours) || isNaN(minutes)) {
      enqueueSnackbar('Invalid time format', {variant: 'error'});
      return;
    }
    const timeIdPromise = savedTimeId != null ? Promise.resolve(savedTimeId) : startNewEstimateTime(navigate, props.estimate.id, {type: 'job_length'}).then(time => time.id);
    return timeIdPromise
      .then(timeId => updateEstimateTime(navigate, props.estimate.id, timeId, {isStart: false, elapsedSeconds: (hours * 60 * 60) + (minutes * 60)}))
      .catch(e => {
        logError('Error saving estimate time', {estimateId: props.estimate.id, durationString}, e);
        enqueueSnackbar(`Error saving estimate time: ${e}`, {variant: 'error', autoHideDuration: 5000});
      })
  }

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChangedEstimate({...changedEstimate, name: e.target.value});
  }

  const handleStatusChange = (e: SelectChangeEvent) => {
    setChangedEstimate({...changedEstimate, status: e.target.value as EstimateStatusType});
  }

  const handleCrewSizeChange = (e: SelectChangeEvent) => {
    setChangedEstimate({...changedEstimate, crewSize: (e.target.value == null || e.target.value === '') ? undefined : parseInt(e.target.value)});
  }

  const handleTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDurationString(event.target.value);
  };

  const handleNotesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChangedEstimate({...changedEstimate, notes: e.target.value});
  }

  const handleSave = () => {
    setIsSaving(true);
    const updatePromises = [
      saveDuration(),
      updateEstimate(navigate, props.estimate.id, changedEstimate)
        .then(estimate => {
          props.onEstimateSaved(estimate);
        })
    ];
    Promise.all(updatePromises)
      .catch(e => {
        logError('Error updating estimate', {estimateId: props.estimate.id, estimate: changedEstimate}, e);
        enqueueSnackbar(`Error updating estimate: ${e}`, {variant: 'error', autoHideDuration: 5000});
      })
      .finally(() => setIsSaving(false));
  }

  return (
    <Card
      sx={{ maxWidth: 600, maxHeight: '90vh', mx: 'auto', my: 4, overflowY: 'auto' }}>
      <CardContent>
        <CardHeader title={`Job Details`}/>
        <Stack spacing={2}>
          <FormControl fullWidth>
            <InputLabel id="estimate-detail-name"></InputLabel>
            <TextField
              label="Name"
              variant="outlined"
              value={changedEstimate.name}
              onChange={handleNameChange}
            />
          </FormControl>
          <FormControl fullWidth>
            <InputLabel>Status</InputLabel>
            <Select
              value={changedEstimate.status}
              label="Status"
              onChange={handleStatusChange}
            >
              {Object.values(EstimateStatus).map((status) =>
                <MenuItem value={status} key={status}>{status}</MenuItem>)
              }
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel>Crew Size</InputLabel>
            <Select
              value={changedEstimate.crewSize?.toString() ?? ''}
              label="Crew Size"
              onChange={handleCrewSizeChange}
            >
              <MenuItem value={''} key={''}>{''}</MenuItem>
              {Array.from(Array(11).keys()).map((crewSize) =>
                <MenuItem value={crewSize} key={crewSize}>{crewSize}</MenuItem>)
              }
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <TextField
              label="Duration (HH:MM)"
              value={durationString}
              onChange={handleTimeChange}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 1,
              }}
              fullWidth
            />
          </FormControl>
          <FormControl fullWidth>
            <TextField
              label="Job Notes"
              variant="outlined"
              multiline
              rows={2}
              value={changedEstimate?.notes ?? ''}
              onChange={handleNotesChange}
            />
          </FormControl>
          <Button onClick={handleSave} variant="contained" component="label">
            {isSaving ? <CircularProgress/> : 'Save'}
          </Button>
        </Stack>
      </CardContent>
    </Card>
  )
}
);

export default EstimateDetail;