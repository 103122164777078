import React, {useEffect, useState} from "react";
import {Menu, MenuItem, PopoverVirtualElement} from "@mui/material";
import {EstimateStatus, EstimateStatusType} from "../dtos/estimate.dto";
import {updateEstimate} from "../../pages/estimate/estimate.service";
import {useNavigate} from "react-router-dom";
import {logError} from "../services/logger.service";
import {enqueueSnackbar} from "notistack";

export interface EstimateStatusUpdateMenuProps {
  estimateId: number;
  // anchorElement: Element | (() => Element) | PopoverVirtualElement | (() => PopoverVirtualElement) | null | undefined;
  // open: boolean;
  lastButtonClickEvent: React.MouseEvent<HTMLButtonElement> | null;
  onClose?: () => void;
  onStatusChanged?: (status: EstimateStatusType) => void,
  statuses?: EstimateStatusType[] | undefined;
}

export const EstimateStatusUpdateMenu = (props: EstimateStatusUpdateMenuProps) => {
  const navigate = useNavigate();

  const onClose = props.onClose ?? (() => {});
  const onStatusChanged = props.onStatusChanged ?? (() => {});

  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [statuses, setStatuses] = useState<EstimateStatusType[]>(props.statuses ?? Object.values(EstimateStatus));

  useEffect(() => {
    setAnchorEl(props.lastButtonClickEvent?.target as unknown as HTMLButtonElement ?? null);
    setIsOpen(!!props.lastButtonClickEvent?.target);
  }, [props.lastButtonClickEvent]);

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  const handleStatusClick = async (status: EstimateStatusType) => {
    await updateEstimate(navigate, props.estimateId, {status}).catch(e => {
      logError('Error updating status', {}, e)
      enqueueSnackbar(`Error updating status: ${e}`, {variant: 'error', autoHideDuration: 5000});
    });
    onStatusChanged(status);
    setIsOpen(false);
    onClose();
  }

  return (
    <Menu
      id="status-menu"
      anchorEl={anchorEl}
      keepMounted
      open={isOpen}
      onClose={handleClose}
    >
      {statuses.map((status: EstimateStatusType) => (
        <MenuItem
          key={status}
          onClick={() => handleStatusClick(status)}
        >
          {status}
        </MenuItem>
      ))}
    </Menu>
  )
}