import React, { createContext, useContext } from 'react';
import RealTimeService from "../services/real-time.service";

export interface RealTimeProviderProps {
  children?: React.ReactNode;
}

const RealTimeContext = createContext<RealTimeService | null>(null);

export const RealTimeProvider: React.FC<RealTimeProviderProps> = ({ children }) => {
  const realTimeService = RealTimeService.getInstance();

  return (
    <RealTimeContext.Provider value={realTimeService}>
      {children}
    </RealTimeContext.Provider>
  );
};

export const useRealTime = () => {
  return useContext(RealTimeContext)!;
};