import React, {ChangeEvent, FormEvent, useEffect, useRef, useState} from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader, Checkbox, CircularProgress,
  CssBaseline, FormControl, FormControlLabel, FormGroup, InputLabel,
  Modal,
  Paper,
  Stack,
  TextField,
} from "@mui/material";
import {Form, useLocation, useNavigate} from "react-router-dom";
import {EstimateDto, EstimateStatus, EstimateWithClientAndCostDto} from "../../shared/dtos/estimate.dto";
import {getApiKeys, requireAuth} from "../../shared/services/auth.service";
import {enqueueSnackbar} from "notistack";
import { datadogLogs } from '@datadog/browser-logs'
import {logError, logInfo} from "../../shared/services/logger.service";
import {DataGrid, gridClasses, GridColDef, GridRowParams, GridToolbar} from '@mui/x-data-grid';
import {useToolbar} from "../../components/ToolbarContext";
import {getWorkOrders} from "./work-order.service";
import {Filter} from "../appointment/AppointmentListPage";
import {AppointmentSummaryDto} from "../appointment/dto/appointment.dto";
import {UrlFilterAwareDataGrid} from "../../shared/components/UrlFilterAwareDataGrid";
import {EstimateItemizedStateless, EstimateItemizedStatelessProps} from "../estimate-itemized/EstimateItemizedStateless";
import {getEstimate} from "../estimate/estimate.service";
import {getCompany} from "../../shared/services/company.service";
import {getTreeOperations} from "../estimate/estimate-tree.service";
import {CenteredCircularSpinner} from "../../shared/components/CenteredCircularSpinner";
import generatePDF from "react-to-pdf";

const approvedFilter: Filter<EstimateWithClientAndCostDto> = {
  displayName: 'Approved',
  urlParam: 'approved',
  filterFunction: (values: EstimateWithClientAndCostDto[]) => {
    return values.filter(estimate => estimate.status === EstimateStatus.EstimateApproved);
  }
}

const scheduledFilter: Filter<EstimateWithClientAndCostDto> = {
  displayName: 'Scheduled',
  urlParam: 'scheduled',
  filterFunction: (values: EstimateWithClientAndCostDto[]) => {
    return values.filter(estimate => estimate.status === EstimateStatus.WorkOrderPending);
  }
}

const inProgressFilter: Filter<EstimateWithClientAndCostDto> = {
  displayName: 'In Progress',
  urlParam: 'inProgress',
  filterFunction: (values: EstimateWithClientAndCostDto[]) => {
    return values.filter(estimate => estimate.status === EstimateStatus.WorkOrderInProgress);
  }
}

const cancelledFilter: Filter<EstimateWithClientAndCostDto> = {
  displayName: 'Cancelled',
  urlParam: 'cancelled',
  filterFunction: (values: EstimateWithClientAndCostDto[]) => {
    return values.filter(estimate => estimate.status === EstimateStatus.WorkOrderCancelled);
  }
}

const showAllFilter: Filter<EstimateWithClientAndCostDto> = {
  displayName: 'All',
  urlParam: 'all',
  filterFunction: (values: EstimateWithClientAndCostDto[]) => values
}

const allFilters = [showAllFilter, approvedFilter, scheduledFilter, inProgressFilter, cancelledFilter];

function WorkOrderListPage() {
  const navigate = useNavigate();
  const toolbarContext = useToolbar();

  const [workOrders, setWorkOrders] = useState<EstimateWithClientAndCostDto[]>([]);
  const [workOrdersAreLoading, setWorkOrdersAreLoading] = useState(false);
  const [downloadingWorkOrders, setDownloadingWorkOrders] = useState(false);
  const [selectedWorkOrderIds, setSelectedWorkOrderIds] = useState<number[]>([]);
  const [activeWorkOrderToDownloadData, setActiveWorkOrderToDownloadData] = useState<(EstimateItemizedStatelessProps & {workOrder: EstimateWithClientAndCostDto}) | undefined>(undefined);
  const activeWorkOrderToDownloadContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    toolbarContext.initToolbar('WorkOrderListPage', 'Work Orders');
    requireAuth(navigate).then(() => {
      datadogLogs.logger.info('User navigated to work orders');
      fetchWorkOrders();
    })
  }, []);

  async function fetchWorkOrders() {
    setWorkOrdersAreLoading(true);
    await getWorkOrders(navigate)
      .then(workOrders => setWorkOrders(workOrders))
      .catch(e => {
        logError('Error fetching work orders', {}, e)
        enqueueSnackbar(`Error fetching work orders: ${e}`, {variant: 'error', autoHideDuration: 5000});
      })
      .finally(() =>
      {
        setWorkOrdersAreLoading(false)
      });
  }

  const workOrderColumns: GridColDef[] = [
    { field: 'clientName', headerName: 'Client', width: 200 },
    { field: 'clientEmail', headerName: 'Client Email', width: 250 },
    { field: 'clientPhoneNumber', headerName: 'Client Phone #', width: 150 },
    { field: 'address', headerName: 'Address', width: 300 },
    { field: 'city', headerName: 'City', width: 150 },
    { field: 'state', headerName: 'State', width: 100 },
    { field: 'status', headerName: 'Status', width: 150 },
    { field: 'amount', headerName: 'Amount', width: 100 },
    { field: 'updatedAt', headerName: 'Updated At', width: 200 },
  ];

  const rowBuilder = (estimate: EstimateWithClientAndCostDto) => {
    return {
      id: estimate.id,
      clientName: estimate.clientName,
      clientEmail: estimate.email,
      clientPhoneNumber: estimate.phoneNumber,
      address: estimate.estimateAddress,
      city: estimate.estimateCity,
      state: estimate.estimateState,
      status: estimate.status,
      amount: `$${estimate.estimatedCost}`,
      updatedAt: estimate.updatedAt,
    }
  };

  const handleSelectedRowsChange = (selectedRowIds: number[]) => {
    setSelectedWorkOrderIds(selectedRowIds);
  }

  useEffect(() => {
    if(!activeWorkOrderToDownloadContainerRef.current || !activeWorkOrderToDownloadData) {
      return;
    }
    logInfo('Generating PDF for work order', {workOrderId: activeWorkOrderToDownloadData.workOrder.id, displayMode: activeWorkOrderToDownloadData.displayMode});
    const {workOrder, client, displayMode, ...ignore} = activeWorkOrderToDownloadData!;
    generatePDF(activeWorkOrderToDownloadContainerRef, {
      overrides: {
        canvas: {
          allowTaint: true,
          onclone: function (clonedDoc: any) {
            clonedDoc.getElementById('active-work-order-to-download-container').style.display = 'block';
          }
        }
      },
      filename: `${workOrder.clientName} Work Order #${workOrder.id} (${displayMode}).pdf`,
    })
      .catch(e => {
        logError('Error generating PDF for work order', {workOrderId: activeWorkOrderToDownloadData.workOrder.id, displayMode: activeWorkOrderToDownloadData.displayMode}, e);
        enqueueSnackbar(`Error generating PDF for work order: ${e}`, {variant: 'error', autoHideDuration: 5000});
      })
      .finally(() => {
        logInfo('Finished generating PDF for work order', {workOrderId: activeWorkOrderToDownloadData.workOrder.id, displayMode: activeWorkOrderToDownloadData.displayMode});
        setActiveWorkOrderToDownloadData(undefined);
        setDownloadingWorkOrders(false);
      });
  }, [activeWorkOrderToDownloadContainerRef.current, activeWorkOrderToDownloadData]);

  const handleDownloadPdfClick = async (mode: 'customer' | 'contractor') => {
    if(selectedWorkOrderIds.length == 0) {
      return;
    }

    setDownloadingWorkOrders(true);

    const downloadPromises = selectedWorkOrderIds.map(async id => {
      const workOrder = workOrders.find(wo => wo.id === id);
      if(!workOrder) {
        enqueueSnackbar(`Work order with id ${id} not found`, {variant: 'error', autoHideDuration: 5000});
        throw new Error(`Work order with id ${id} not found`);
      };
      const [estimate, company] = await Promise.all([getEstimate(navigate, id), getCompany(navigate)])
      const treesWithOperationsPromises = estimate.trees.map(async tree => {
        const operations = await getTreeOperations(navigate, estimate.id, tree.id);
        return {...tree, operations};
      });
      const treesWithOperations = (await Promise.all(treesWithOperationsPromises)).sort((a, b) => a.id - b.id);
      const downloadData = {
        workOrder,
        company,
        estimate,
        client: {name: workOrder.clientName, address: workOrder.clientAddress, email: workOrder.email, phoneNumber: workOrder.phoneNumber},
        treesWithOperations,
        displayType: 'estimate' as const,
        displayMode: mode,
      }
      setActiveWorkOrderToDownloadData(downloadData);
    })

    try {
      for(let promise of downloadPromises) {
        await promise;
      }
    } catch (e) {
      setDownloadingWorkOrders(false); // In the happy path we do this in the generatePDF finally block
      logError('Error downloading work orders')
      enqueueSnackbar(`Error downloading work orders: ${e}`, {variant: 'error', autoHideDuration: 5000});
    }
  }

  const handleRowClick = (params: GridRowParams) => {
    navigate(`/estimates/${params.id}`)
  };

  return (
    <div style={{height: '100%'}}>
      <Paper sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', padding: 3}}>
        <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBottom: 2, gap: 2}}>
          <Button variant="contained" disabled={selectedWorkOrderIds.length == 0} onClick={() => handleDownloadPdfClick('customer')} style={{width: '250px'}}>
            {downloadingWorkOrders ? <CenteredCircularSpinner color={'secondary'} size={'24px'}/> : 'Download Customer PDF'}
          </Button>
          <Button variant="contained" disabled={selectedWorkOrderIds.length == 0} onClick={() => handleDownloadPdfClick('contractor')} style={{width: '250px'}}>
            {downloadingWorkOrders ? <CenteredCircularSpinner color={'secondary'} size={'24px'}/> : 'Download Contractor PDF'}
          </Button>
        </Box>
        <UrlFilterAwareDataGrid
          columns={workOrderColumns}
          rowBuilder={rowBuilder}
          data={workOrders}
          loading={workOrdersAreLoading}
          onRowClick={handleRowClick}
          allFilters={allFilters}
          defaultFilters={[showAllFilter]}
          showCheckboxes={true}
          onCheckboxSelectionChange={ids => handleSelectedRowsChange(ids as number[])}
          disableMultipleRowSelection={true}
        />
        <div ref={activeWorkOrderToDownloadContainerRef} id={'active-work-order-to-download-container'} style={{display: 'none'}}>
          {activeWorkOrderToDownloadData && <EstimateItemizedStateless  {...activeWorkOrderToDownloadData}/>}
        </div>
      </Paper>
    </div>
  );
}

export default WorkOrderListPage;
