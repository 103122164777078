import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';


const LoginPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const redirectUri = encodeURIComponent(process.env.REACT_APP_LOGIN_CALLBACK_URL!);
    window.location.href = `https://auth.savaquan.com/login?client_id=7u4nrbq69kilch6ahqu1ru41e5&response_type=code&scope=email+openid+phone&redirect_uri=${redirectUri}`;
    return;
  }, [navigate]);

  return (
    <div>
      Redirecting to login...
    </div>
  );
};

export default LoginPage;