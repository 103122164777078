import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  Stack,
  TextField
} from "@mui/material";
import React, {ChangeEvent, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {logError} from "../../shared/services/logger.service";
import {enqueueSnackbar} from "notistack";
import {createEstimate, getEstimate, updateEstimate} from "../estimate/estimate.service";
import {Loader} from "@googlemaps/js-api-loader";
import {getLocationFromAddress} from "../../shared/misc/location-helpers";
import {EstimateWithComponentsDto} from "../../shared/dtos/estimate-with-components.dto";
import {getClients} from "../client/clients.service";
import {ClientDto} from "../client/dtos/client.dto";
import {AddEstimateDto} from "../../shared/dtos/add-estimate.dto";

export interface EstimateInfoProps {
  onClose: () => void;
  onSave: (estimate: EstimateWithComponentsDto) => void;
  estimateId?: number;
}

const MAX_ADDRESS_SUGGESTIONS = 10;

const EstimateInfoComponent: React.FC<EstimateInfoProps> = React.forwardRef((props, ref) => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [autocompleteService, setAutocompleteService] = useState<google.maps.places.AutocompleteService | null>(null);
  const [addressSuggestions, setAddressSuggestions] = useState<string[]>([]);
  const [savedEstimate, setSavedEstimate] = useState<EstimateWithComponentsDto | null>(null);
  const [updatedEstimate, setUpdatedEstimate] = useState<Partial<AddEstimateDto> | EstimateWithComponentsDto>({});
  const [allClients, setAllClients] = useState<ClientDto[]>([]);
  const [clientSuggestions, setClientSuggestions] = useState<string[]>([]);
  const [clientSearchText, setClientSearchText] = useState<string>();
  const [isFinishing, setIsFinishing] = useState<boolean>(false);

  useEffect(() => {
    const googleMapsLoader = new Loader({
      apiKey: "AIzaSyBLw50vYSozTEviDIpu94K1KuwM4X_hDUM",
      version: "beta",
    });
    googleMapsLoader.importLibrary("places")
      .then(places => {
        setAutocompleteService(new (places as google.maps.PlacesLibrary).AutocompleteService());
        if(props.estimateId) {
          getEstimate(navigate, props.estimateId)
            .then(estimate => {
              setSavedEstimate(estimate);
              setUpdatedEstimate(estimate);
            })
            .catch(e => {
              logError('Error getting estimate', {clientId: props.estimateId}, e);
              enqueueSnackbar(`Error getting estimate: ${e}`, {variant: 'error', autoHideDuration: 5000});
            })
            .finally(() => setIsLoading(false));
        } else {
          setIsLoading(false);
        }
      })
      .catch(e => {
        logError('Error importing google places library', {}, e);
        enqueueSnackbar(`Error importing google places library: ${e}`, {variant: 'error', autoHideDuration: 5000});
      });

    getClients(navigate)
      .then(clients => setAllClients(clients))
      .catch(e => {
        logError('Error getting clients for autocomplete', {}, e);
        enqueueSnackbar(`Error getting clients for autocomplete`, {variant: 'error', autoHideDuration: 5000});
      });

  }, []);

  useEffect(() => {
    if(!autocompleteService || !updatedEstimate?.address) return;

    autocompleteService.getPlacePredictions({
      input: updatedEstimate.address,
      componentRestrictions: {country: 'us'},
      locationBias: "IP_BIAS",
      region: 'US'
    })
      .then(response => {
        setAddressSuggestions(response.predictions.map(p => p.description).slice(0, MAX_ADDRESS_SUGGESTIONS));
      })
      .catch(e => {
        logError('Error getting address suggestions', {address: updatedEstimate.address}, e);
        enqueueSnackbar(`Error getting address suggestions: ${e}`, {variant: 'error', autoHideDuration: 5000});
      });
  }, [updatedEstimate?.address]);

  useEffect(() => {
    const clientSuggestions = allClients
      .map(c => `${c.name} - ${c.email} (${c.id})`)
      .filter(c => c.toLowerCase().includes(clientSearchText?.toLowerCase() ?? ''))
    setClientSuggestions([...clientSuggestions, '']);
  }, [allClients, clientSearchText]);

  const saveAndClose = async () => {
    if(!updatedEstimate?.clientId || !updatedEstimate.address) {
      enqueueSnackbar('Client Id and address are required', {variant: 'error', autoHideDuration: 5000});
      return;
    }

    const location = await getLocationFromAddress(updatedEstimate.address).catch(e => {
      logError('Failed to get location from address', {address: updatedEstimate.address}, e);
      enqueueSnackbar('Failed to get location from address', {variant: 'error', autoHideDuration: 5000});
      return null;
    });
    if(!location) return;

    const newEstimate = {...updatedEstimate, ...location, name: updatedEstimate.name ?? `Estimate for ${updatedEstimate.clientId}`};
    setUpdatedEstimate(newEstimate);

    setIsFinishing(true);
    try {
      const returnedEstimate = (savedEstimate as EstimateWithComponentsDto)?.id
        ? await updateEstimate(navigate, (savedEstimate as EstimateWithComponentsDto).id, newEstimate)
        : await createEstimate(navigate, newEstimate as AddEstimateDto);
      props.onSave(returnedEstimate);
      return returnedEstimate;
    }
    catch {
      logError('Failed to modify estimate', {newLead: newEstimate});
      enqueueSnackbar('Failed to modify estimate', {variant: 'error', autoHideDuration: 5000});
    }
    finally {
      setIsFinishing(false);
      props.onClose();
    }
  }

  const handleClientChange = (client: string) => {
    setClientSearchText(client);
    const idStartIndex = client.lastIndexOf('(') + 1;
    const idEndIndex = client.lastIndexOf(')') - 1;
    if(idStartIndex < 0 || idEndIndex < 0) return;

    const id = parseInt(client.substring(idStartIndex, idEndIndex + 1));
    setUpdatedEstimate(estimate => ({...estimate!, clientId: id}));
  };

  const handleAddressChange = (address: string) => {
    setUpdatedEstimate(estimate => ({...estimate!, address: address ?? undefined}));
  };

  const handleNotesChange = (event: ChangeEvent<HTMLInputElement>) => {
    setUpdatedEstimate(estimate => ({...estimate!, notes: event.target.value ?? undefined}));
  };

  const handleSave = async () => {
    saveAndClose();
  }

  return (
    isLoading ? <CircularProgress/> :
      <Card
        sx={{ maxWidth: 600, maxHeight: '90vh', mx: 'auto', my: 4, overflowY: 'auto' }}>
        <CardContent>
          <CardHeader title={savedEstimate?.id ? 'Estimate Details' : 'Add Estimate'}/>
          <Stack spacing={2}>
            <FormControl fullWidth>
              <Autocomplete
                disablePortal
                freeSolo
                filterOptions={o => o}
                options={clientSuggestions}
                value={clientSearchText ?? ''}
                onInputChange={(_, a) => handleClientChange(a)}
                renderInput={(params) => <TextField {...params} label="Client *"/>}
              />
            </FormControl>
            <FormControl fullWidth>
              <Autocomplete
                disablePortal
                freeSolo
                filterOptions={o => o}
                options={addressSuggestions}
                value={updatedEstimate?.address ?? ''}
                onInputChange={(_, a) => handleAddressChange(a)}
                renderInput={(params) => <TextField {...params} label="Address *"/>}
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                label="Notes"
                variant="outlined"
                multiline
                rows={4}
                value={updatedEstimate?.notes ?? ''}
                onChange={handleNotesChange}
              />
            </FormControl>
            <Button onClick={handleSave} variant="contained" component="label">
              {isFinishing ? <CircularProgress/> : 'Save'}
            </Button>
          </Stack>
        </CardContent>
      </Card>
  )
});

export default EstimateInfoComponent;