import React, {useEffect, useState} from "react";
import {
  Box, Button, Collapse,
  Divider,
  Drawer,
  IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Theme,
  Toolbar,
  Typography, useMediaQuery,
  useTheme
} from "@mui/material";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MuiAppBar from '@mui/material/AppBar';
import MenuIcon from "@mui/icons-material/Menu";
import './Layout.css';
import {useNavigate} from "react-router-dom";
import {Assignment, Build, Event, Home, PersonSearch, Receipt, RequestQuote} from "@mui/icons-material";
import {useToolbar} from "./ToolbarContext";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import {useLocationPermissions} from "./LocationPermissionsContext";
import PermissionsMissingComponent from "./PermissionsMissingComponent";

export interface LayoutProps {
  children?: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const navigate = useNavigate();
  const { hasLocationPermission, locationPermissionLoading, checkLocationPermissions } = useLocationPermissions();
  const toolbarContext = useToolbar();

  const [drawerOpenMobile, setDrawerOpenMobile] = React.useState(false);
  const [subMenuOpen, setSubMenuOpen] = useState<{ [key: string]: boolean }>({});

  const handleMenuClick = (title: string) => {
    setSubMenuOpen((prev) => ({ [title]: !prev[title] }));
  };

  const drawerWidth = 240;
  const theme = useTheme();
  const isMediumDisplayOrAbove = useMediaQuery(theme.breakpoints.up('md'));

  const handleHomeClick = () => {
    navigate('/');
  }

  const handleClientsViewAllClick = () => {
    navigate('/clients');
    setDrawerOpenMobile(false);
  }

  const handleEstimateAppointmentsTodayClick = () => {
    navigate('/appointments?filters=today');
    setDrawerOpenMobile(false);
  }

  const handleEstimateAppointmentsThisWeekClick = () => {
    navigate('/appointments?filters=thisWeek');
    setDrawerOpenMobile(false);
  }

  const handleEstimateAppointmentsViewAllClick = () => {
    navigate('/appointments');
    setDrawerOpenMobile(false);
  }

  const handleEstimateAppointmentsMapViewClick = () => {
    navigate('/appointments/map');
    setDrawerOpenMobile(false);
  }

  const handleEstimatesViewAllClick = () => {
    navigate('/estimates');
    setDrawerOpenMobile(false);
  }

  const handleEstimatesInProgressClick = () => {
    navigate('/estimates?filters=inProgress');
    setDrawerOpenMobile(false);
  }

  const handleEstimatesCompletedClick = () => {
    navigate('/estimates?filters=completed');
    setDrawerOpenMobile(false);
  }

  const handleEstimatesPendingApprovalClick = () => {
    navigate('/estimates?filters=pendingApproval');
    setDrawerOpenMobile(false);
  }

  const handleEstimatesAutomaticFollowsUpsClick = () => {
    navigate('/estimates/automatic-follow-ups');
    setDrawerOpenMobile(false);
  }

  const handleWorkOrdersViewAllClick = () => {
    navigate('/work-orders');
    setDrawerOpenMobile(false);
  }

  const handleWorkOrdersApprovedClick = () => {
    navigate('/work-orders?filters=approved');
    setDrawerOpenMobile(false);
  }

  const handleWorkOrdersScheduledClick = () => {
    navigate('/work-orders?filters=scheduled');
    setDrawerOpenMobile(false);
  }

  const handleWorkOrdersCancelledClick = () => {
    navigate('/work-orders?filters=cancelled');
    setDrawerOpenMobile(false);
  }

  const handleInvoicesViewAllClick = () => {
    navigate('/invoices?filters=all');
    setDrawerOpenMobile(false);
  }

  const handleInvoicesCreateNewClick = () => {
    navigate('/invoices/create-new');
    setDrawerOpenMobile(false);
  }

  const handleInvoicesViewSentClick = () => {
    navigate('/invoices?filters=sent');
    setDrawerOpenMobile(false);
  }

  const handleInvoicesViewPaidClick = () => {
    navigate('/invoices?filters=paid');
    setDrawerOpenMobile(false);
  }

  const handleInvoicesViewOverdueClick = () => {
    navigate('/invoices?filters=overdue');
    setDrawerOpenMobile(false);
  }
  const handleInvoicesViewReceiptSentClick = () => {
    navigate('/invoices?filters=receiptSent');
    setDrawerOpenMobile(false);
  }


  const handleEquipmentListClick = () => {
    navigate('/equipment');
    setDrawerOpenMobile(false);
  }

  const handleSettingsClick = () => {
    navigate('/settings');
  }

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', height: '100%'}}>
      <MuiAppBar position='static' sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar >
          {!isMediumDisplayOrAbove && <IconButton color="inherit" aria-label="open drawer" onClick={() => {
            setDrawerOpenMobile(true)
          }} edge="start" sx={{mr: 2}}>
            <MenuIcon/>
          </IconButton>}
          <Box sx={{flexGrow: 0, display: {xs: 'flex'}}}>
            <IconButton onClick={() => handleHomeClick()} ><Home/></IconButton>
          </Box>
          <Box sx={{pl: 2, flexGrow: 0, display: {xs: 'none', sm: 'flex'}}}>
            <Typography variant="h6" noWrap component="div">
              {toolbarContext.title}
            </Typography>
          </Box>
          <Box sx={{pl: 2, flexGrow: 1, display: {xs: 'none', sm: 'flex'}}}>
            {toolbarContext.centeredContent}
          </Box>
          <Box sx={{pl: 2, flexGrow: 1, display: {xs: 'flex', sm: 'none'}}}/> {/* Spacer for xs screens when there is no centered content */}
          {toolbarContext.actions && <Box sx={{pl: 2, flexGrow: 0}}>
            {toolbarContext.actions.call(this, toolbarContext.toolbarData)}
          </Box>}
          <Typography sx={{pl: 2}} variant="body1">
            User Name
          </Typography>
          <IconButton color="inherit" aria-label="settings" onClick={() => {handleSettingsClick()}}>
            <SettingsIcon/>
          </IconButton>
          <IconButton color="inherit" aria-label="logout">
            <LogoutIcon/>
          </IconButton>
        </Toolbar>
      </MuiAppBar>
      <Box sx={{display: 'flex', width: '100%', height: 'calc(100% - 64px)'}}>
        <Drawer
          sx={{
            width: isMediumDisplayOrAbove ? drawerWidth : 0,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: (isMediumDisplayOrAbove || drawerOpenMobile) ? drawerWidth : 0,
              boxSizing: 'border-box',
            },
            zIndex: (theme) => drawerOpenMobile ? theme.zIndex.drawer + 2 : theme.zIndex.drawer
          }}
          variant={isMediumDisplayOrAbove ? "permanent" : "persistent"}
          anchor="left"
          open={isMediumDisplayOrAbove || drawerOpenMobile}
        >
          {isMediumDisplayOrAbove && <Toolbar />}
          {!isMediumDisplayOrAbove && <IconButton onClick={() => {setDrawerOpenMobile(false)}}>
            <ChevronLeftIcon />
          </IconButton>}
          <Divider />
        <Box>
          <List>
            <ListItem key={'clients'} disablePadding>
              <ListItemButton onClick={() => handleMenuClick('clients')}>
                <ListItemIcon><PersonSearch/></ListItemIcon>
                <ListItemText primary={'Clients'} />
              </ListItemButton>
            </ListItem>
            <Collapse in={subMenuOpen['clients']} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem key={'view_all'} onClick={() => handleClientsViewAllClick()} sx={{ pl: 2 }} disablePadding>
                  <ListItemButton>
                    <ListItemText inset primary={'View all'} />
                  </ListItemButton>
                </ListItem>
              </List>
            </Collapse>
            <ListItem key={'estimate_appointments'} disablePadding>
              <ListItemButton onClick={() => handleMenuClick('estimate_appointments')}>
                <ListItemIcon><Event/></ListItemIcon>
                <ListItemText primary={'Estimate Appointments'} />
              </ListItemButton>
            </ListItem>
            <Collapse in={subMenuOpen['estimate_appointments']} timeout="auto">
              <List component="div" disablePadding>
                <ListItem key={'view_all'} onClick={() => handleEstimateAppointmentsViewAllClick()} sx={{ pl: 2 }} disablePadding>
                  <ListItemButton>
                    <ListItemText inset primary={'View all'} />
                  </ListItemButton>
                </ListItem>
                <ListItem key={'today'} onClick={() => handleEstimateAppointmentsTodayClick()} sx={{ pl: 2 }} disablePadding>
                  <ListItemButton>
                    <ListItemText inset primary={'Today'} />
                  </ListItemButton>
                </ListItem>
                <ListItem key={'thisWeek'} onClick={() => handleEstimateAppointmentsThisWeekClick()} sx={{ pl: 2 }} disablePadding>
                  <ListItemButton>
                    <ListItemText inset primary={'This Week'} />
                  </ListItemButton>
                </ListItem>
                <ListItem key={'map_view'} onClick={() => handleEstimateAppointmentsMapViewClick()} sx={{ pl: 2 }} disablePadding>
                  <ListItemButton>
                    <ListItemText inset primary={'Map view'} />
                  </ListItemButton>
                </ListItem>
              </List>
            </Collapse>
            <ListItem key={'estimates'} disablePadding>
              <ListItemButton onClick={() => handleMenuClick('estimates')}>
                <ListItemIcon><RequestQuote/></ListItemIcon>
                <ListItemText primary={'Estimates'} />
              </ListItemButton>
            </ListItem>
            <Collapse in={subMenuOpen['estimates']} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem key={'view_all'} onClick={() => handleEstimatesViewAllClick()} sx={{ pl: 2 }} disablePadding>
                  <ListItemButton>
                    <ListItemText inset primary={'View all'} />
                  </ListItemButton>
                </ListItem>
                <ListItem key={'in_progress'} onClick={() => handleEstimatesInProgressClick()} sx={{ pl: 2 }} disablePadding>
                  <ListItemButton>
                    <ListItemText inset primary={'In Progress'} />
                  </ListItemButton>
                </ListItem>
                <ListItem key={'completed'} onClick={() => handleEstimatesCompletedClick()} sx={{ pl: 2 }} disablePadding>
                  <ListItemButton>
                    <ListItemText inset primary={'Completed'} />
                  </ListItemButton>
                </ListItem>
                <ListItem key={'pending_approval'} onClick={() => handleEstimatesPendingApprovalClick()} sx={{ pl: 2 }} disablePadding>
                  <ListItemButton>
                    <ListItemText inset primary={'Pending Approval'} />
                  </ListItemButton>
                </ListItem>
                <ListItem key={'set_automatic_follow_ups'} onClick={() => handleEstimatesAutomaticFollowsUpsClick()} sx={{ pl: 2 }} disablePadding>
                  <ListItemButton>
                    <ListItemText inset primary={'Set automatic estimate follow ups'} />
                  </ListItemButton>
                </ListItem>
              </List>
            </Collapse>
            <ListItem key={'work_orders'} disablePadding>
              <ListItemButton onClick={() => handleMenuClick('work_orders')}>
                <ListItemIcon><Assignment/></ListItemIcon>
                <ListItemText primary={'Work Orders'} />
              </ListItemButton>
            </ListItem>
            <Collapse in={subMenuOpen['work_orders']} timeout="auto">
              <List component="div" disablePadding>
                <ListItem key={'view_all'} onClick={() => {handleWorkOrdersViewAllClick()}} sx={{ pl: 2 }} disablePadding>
                  <ListItemButton>
                    <ListItemText inset primary={'View all'} />
                  </ListItemButton>
                </ListItem>
                <ListItem key={'approved'} onClick={() => {handleWorkOrdersApprovedClick()}} sx={{ pl: 2 }} disablePadding>
                  <ListItemButton>
                    <ListItemText inset primary={'Approved'} />
                  </ListItemButton>
                </ListItem>
                <ListItem key={'scheduled'} onClick={() => {handleWorkOrdersScheduledClick()}} sx={{ pl: 2 }} disablePadding>
                  <ListItemButton>
                    <ListItemText inset primary={'Scheduled'} />
                  </ListItemButton>
                </ListItem>
                <ListItem key={'canceled'} onClick={() => {handleWorkOrdersCancelledClick()}} sx={{ pl: 2 }} disablePadding>
                  <ListItemButton>
                    <ListItemText inset primary={'Canceled'} />
                  </ListItemButton>
                </ListItem>
              </List>
            </Collapse>
            <ListItem key={'invoices'} disablePadding>
              <ListItemButton onClick={() => handleMenuClick('invoices')}>
                <ListItemIcon><Receipt/></ListItemIcon>
                <ListItemText primary={'Invoices'} />
              </ListItemButton>
            </ListItem>
            <Collapse in={subMenuOpen['invoices']} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem key={'view_all'} onClick={() => {handleInvoicesViewAllClick()}} sx={{ pl: 2 }} disablePadding>
                  <ListItemButton>
                    <ListItemText inset primary={'View all'} />
                  </ListItemButton>
                </ListItem>
                <ListItem key={'create_new'} onClick={() => {handleInvoicesCreateNewClick()}} sx={{ pl: 2 }} disablePadding>
                  <ListItemButton>
                    <ListItemText inset primary={'Create new'} />
                  </ListItemButton>
                </ListItem>
                <ListItem key={'sent'} onClick={() => {handleInvoicesViewSentClick()}} sx={{ pl: 2 }} disablePadding>
                  <ListItemButton>
                    <ListItemText inset primary={'Sent'} />
                  </ListItemButton>
                </ListItem>
                <ListItem key={'paid'} onClick={() => {handleInvoicesViewPaidClick()}} sx={{ pl: 2 }} disablePadding>
                  <ListItemButton>
                    <ListItemText inset primary={'Paid'} />
                  </ListItemButton>
                </ListItem>
                <ListItem key={'overdue'} onClick={() => {handleInvoicesViewOverdueClick()}} sx={{ pl: 2 }} disablePadding>
                  <ListItemButton>
                    <ListItemText inset primary={'Overdue'} />
                  </ListItemButton>
                </ListItem>
                <ListItem key={'receiptSent'} onClick={() => {handleInvoicesViewReceiptSentClick()}} sx={{ pl: 2 }} disablePadding>
                  <ListItemButton>
                    <ListItemText inset primary={'Receipt Sent'} />
                  </ListItemButton>
                </ListItem>
              </List>
            </Collapse>
            <ListItem key={'equipment'} disablePadding>
              <ListItemButton onClick={() => handleMenuClick('equipment')}>
                <ListItemIcon><Build/></ListItemIcon>
                <ListItemText primary={'Equipment'} />
              </ListItemButton>
            </ListItem>
            <Collapse in={subMenuOpen['equipment']} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem key={'list'} onClick={() => {handleEquipmentListClick()}} sx={{ pl: 2 }} disablePadding>
                  <ListItemButton>
                    <ListItemText inset primary={'List'} />
                  </ListItemButton>
                </ListItem>
              </List>
            </Collapse>
          </List>
        </Box>
        </Drawer>

        <Box sx={{flexGrow: 1, overflow: 'auto', height: '100%', width: '100%'}}>
          {!locationPermissionLoading && !hasLocationPermission ? <PermissionsMissingComponent/> : children}
        </Box>
      </Box>
    </Box>
  )
}

export default Layout;