import {NavigateFunction} from "react-router-dom";
import {getUserTokenOrRedirect} from "./auth.service";
import {CompanySettingDto, EstimateFollowUpSchedule} from "../dtos/company-settings.dto";

export async function getEstimateFollowUpSchedule(navigate: NavigateFunction): Promise<CompanySettingDto<EstimateFollowUpSchedule> | null> {
  const token = await getUserTokenOrRedirect(navigate)
  const schedule = await fetch(
    `${process.env.REACT_APP_ACCUSITE_API_BASE_URL}/api/v1/company-settings/estimate-follow-up-schedule`,
    {headers: {Authorization: `Bearer ${token}`}})
    .then(async response => {
      if (!response.ok) {
        throw new Error(`Failed to fetch estimate follow up schedule: ${response.statusText}`);
      }
      return response.status === 204 ? null : await response.json() as CompanySettingDto<EstimateFollowUpSchedule>;
    });
  return schedule;
}

export async function saveEstimateFollowUpSchedule(navigate: NavigateFunction, schedule: EstimateFollowUpSchedule): Promise<CompanySettingDto<EstimateFollowUpSchedule>> {
  const token = await getUserTokenOrRedirect(navigate)
  const savedSchedule = await fetch(
    `${process.env.REACT_APP_ACCUSITE_API_BASE_URL}/api/v1/company-settings/estimate-follow-up-schedule`,
    {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(schedule)
    }).then(async response => {
      if (!response.ok) {
        throw new Error(`Failed to save estimate follow up schedule: ${response.statusText}`);
      }
      return await response.json() as CompanySettingDto<EstimateFollowUpSchedule>
    });
  return savedSchedule;
}