import React, {useEffect, useRef} from "react";
import {Box, Button, Card, CardContent, Modal, Typography} from "@mui/material";
import SignaturePad from "signature_pad";
import {logError} from "../../shared/services/logger.service";
import './ConfirmeEstimate.css';
import {enqueueSnackbar} from "notistack";

export interface EstimateItemizedPageProps {
  estimateId: number
  onConfirm: (signature: File) => void,
  onCancel: () => void,
}

const ConfirmEstimate = React.forwardRef((props: EstimateItemizedPageProps, ref) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [signaturePad, setSignaturePad] = React.useState<SignaturePad | null>(null);

  useEffect(() => {
    if(canvasRef.current) {
      const context = canvasRef.current.getContext('2d')!;
      const sigPad = new SignaturePad(canvasRef.current, {
        backgroundColor: '#222222'
      });
      setSignaturePad(sigPad);
      resizeCanvas();
    }
  }, [canvasRef]);

  const resizeCanvas = () => {
    const ratio =  Math.max(window.devicePixelRatio || 1, 1);
    const canvas = getCanvasOrThrow();
    const context = get2dContextOrThrow();
    canvas.width = canvas.offsetWidth * ratio;
    canvas.height = canvas.offsetHeight * ratio;
    context.scale(ratio, ratio);
  }

  const getCanvasOrThrow = (): HTMLCanvasElement => {
    if(!canvasRef.current) {
      logError('Canvas not found');
      throw new Error('Canvas not found');
    }
    return canvasRef.current
  }

  const get2dContextOrThrow = (): CanvasRenderingContext2D => {
    const canvas = getCanvasOrThrow();
    const context = canvas.getContext('2d')!;
    if(!context) {
      logError('Canvas context not found');
      throw new Error('Canvas context not found');
    }
    return context;
  }

  const handleCancel = () => {
    props.onCancel();
  }

  const handleConfirm = () => {
    if(!signaturePad) {
      logError('Signature Pad not found');
      return;
    }

    const canvas = getCanvasOrThrow();
    try {
      canvas.toBlob(blob => {
        if(!blob) {
          logError('No blob found saving signature');
          return;
        }
        const file = new File([blob], 'drawn-image.png', {type: 'image/png'});
        props.onConfirm(file);
      }, 'image/png');
    } catch (e) {
      logError(`Error saving signature: ${e}`);
      enqueueSnackbar(`Error saving signature: ${e}`, {variant: 'error', autoHideDuration: 5000})
    }
  }

  return (
    <Card sx={{ maxWidth: 600, maxHeight: '90vh', mx: 'auto', my: 4, overflowY: 'auto' }}>
      <CardContent>
        <div className="signature-canvas-container">
          <canvas
            id='signature-canvas'
            className={"signature-canvas"}
            ref={canvasRef}
            style={{backgroundColor: 'white'}}
          ></canvas>
          <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Typography>Draw your signature</Typography>
          </div>
          {/*<button onClick={() => dumpSignatureData()}>Dump Data</button>*/}
        </div>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <Button variant="outlined" onClick={handleCancel}>Cancel</Button>
          <Button variant="contained" color="primary" onClick={handleConfirm}>Confirm Estimate</Button>
        </div>
      </CardContent>
    </Card>
  )
});

export default ConfirmEstimate;