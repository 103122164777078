import React, {MouseEventHandler, useEffect} from "react";
import {
  Box, Button,
  Card, CardContent,
  CardHeader,
  Divider,
  Drawer,
  IconButton, Modal, Paper, styled,
  Toolbar,
  Typography,
  useTheme
} from "@mui/material";

import {EstimateWithComponentsDto} from "../../shared/dtos/estimate-with-components.dto";
import JobTimer from "../../shared/components/JobTimer";
import './EstimateInfoSidebar.css';
import {ChevronLeft, ChevronRight, Edit} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import {getTreeOperations} from "./estimate-tree.service";
import {EstimateTreeOperationDto} from "../../shared/dtos/estimate-tree-operation.dto";
import {EstimateTreeDto} from "../../shared/dtos/estimate-tree.dto";
import {logError} from "../../shared/services/logger.service";
import {enqueueSnackbar} from "notistack";
import {CenteredCircularSpinner} from "../../shared/components/CenteredCircularSpinner";
import {formatTreeOperationOneLine} from "../../shared/services/tree-helpers.service";
import EstimateTree from "./EstimateTreeDetailComponent";
import EditTreeSpecialEquipment from "./EditTreeSpecialEquipment";

export interface EstimateInfoSidebarProps {
  estimate: EstimateWithComponentsDto | undefined;
  treeIdToLocalIdMap: {[key: number]: number};
  onTreeClick: (treeId: number) => void;
  onEditDetailsClick: () => void;
  className?: string;
  startOpen?: boolean;
}

const EstimateInfoSidebar = (props: EstimateInfoSidebarProps) => {
  const navigate = useNavigate();

  const [drawerOpen, setDrawerOpen] = React.useState(props.startOpen ?? false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [treesWithOperations, setTreesWithOperations] = React.useState<{tree: EstimateTreeDto, operations: EstimateTreeOperationDto[]}[]>([]);
  const [editingEquipmentTreeId, setEditingEquipmentTreeId] = React.useState<number>();

  const drawerWidth = 300;
  const theme = useTheme();

  const handleEditSpecialEquipmentClick = (treeId: number) => {
    setEditingEquipmentTreeId(treeId);
  }

  const handleCloseSpecialEquipment = () => {
    setEditingEquipmentTreeId(undefined);
  }

  useEffect(() => {
    if(!props.estimate) return;
    setIsLoading(true);

    const treesWithOperationsPromises = props.estimate.trees.map(async tree => ({tree, operations: await getTreeOperations(navigate, props.estimate!.id, tree.id)}));
    //TODO: We should only reload data for the specific tree that was changed, not all of them
    Promise.all(treesWithOperationsPromises).then(two => {
      setTreesWithOperations(two);
    })
    .catch(e => {
      logError('Error getting tree operations', {estimateId: props.estimate?.id}, e);
      enqueueSnackbar(`Error getting tree operations: ${e}`, {variant: 'error', autoHideDuration: 5000})
    })
    .finally(() => setIsLoading(false));
}, [props.estimate?.trees]);

  return (
    !props.estimate ? <></> :
    // TODO We should make this animate properly rather than teleporting
    <div className={props.className} style={{height: '100%', width: drawerWidth + 20, position: 'relative', display: 'flex', flexDirection: 'column', alignItems: drawerOpen ? 'flex-end' : 'flex-start', justifyContent: 'center'}}>
      <Paper sx={{ zIndex: 9, height: '50px', width: '25px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <IconButton onClick={() => setDrawerOpen(!drawerOpen)} className={'overlaid-control'}>
          {drawerOpen ? <ChevronLeft /> : <ChevronRight/>}
        </IconButton>
      </Paper>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
          zIndex: 10,
        }}
        variant="persistent"
        anchor="left"
        open={drawerOpen}
        PaperProps={{
          sx: {
            position: 'absolute'
          },
        }}
      >
        {/*{isLoading ? <CenteredCircularSpinner/> :*/} {/*We reload data everytime a tree moves, so we really don't to be showing the loading circle every time that happens*/}
        <div className={'overlaid-control'}>
          <Divider />
          <Box>
            <Button variant="contained" onClick={props.onEditDetailsClick} fullWidth>
              Edit Details
            </Button>
          </Box>
          <Box>
            <JobTimer estimateId={props.estimate?.id} />
            <Divider/>
            <Typography style={{margin: '10px'}} variant="h5">Trees</Typography>
            <Box style={{margin: '5px'}}>
              {treesWithOperations
                .sort((a, b) => props.treeIdToLocalIdMap[a.tree.id] - props.treeIdToLocalIdMap[b.tree.id])
                .map((treeWithOperations, index) => (
                  <Card key={treeWithOperations.tree.id} variant="outlined" className="tree-summary-card" onClick={() => props.onTreeClick(treeWithOperations.tree.id)}>
                    <CardContent>
                      <Typography variant="h6">Info:</Typography>
                      <Typography variant="subtitle2">Id: {props.treeIdToLocalIdMap[treeWithOperations.tree.id]}</Typography>
                      <Typography variant="subtitle2">Species: {treeWithOperations.tree.species}</Typography>
                      <Typography variant="subtitle2">Height: {treeWithOperations.tree.height}</Typography>
                      <Typography variant="subtitle2">Width: {treeWithOperations.tree.width}</Typography>
                      <Typography variant="subtitle2">Diameter: {treeWithOperations.tree.trunkDiameter}</Typography>
                      <Typography variant="subtitle2">Notes: {treeWithOperations.tree.notes}</Typography>
                      <Typography variant="subtitle2">Crew Notes: {treeWithOperations.tree.crewNotes}</Typography>
                      <Typography variant="subtitle2">Drag Distance: {Math.round(props.estimate?.dragPaths.find(dp => dp.treeId == treeWithOperations.tree.id)?.distanceFeet ?? 0)}</Typography>
                      <Divider style={{paddingTop: '10px'}} />
                      <Typography variant="h6" style={{paddingTop: '10px'}}>Operations:</Typography>
                      {treeWithOperations.operations.map(op => (<Typography key={op.id} variant="subtitle1">{formatTreeOperationOneLine(op)}</Typography>))}
                      <Divider style={{paddingTop: '10px'}} />
                      <Box style={{paddingTop: '10px'}}>
                        <Button variant="contained" startIcon={<Edit/>} onClick={e => {
                          e.stopPropagation(); // We don't want this to register on the card click
                          handleEditSpecialEquipmentClick(treeWithOperations.tree.id);
                        }}>
                          Edit Special Equipment
                        </Button>
                      </Box>
                    </CardContent>
                  </Card>
                ))
              }
            </Box>
          </Box>
        </div>
      </Drawer>
      <Modal open={!!editingEquipmentTreeId} onClose={() => handleCloseSpecialEquipment()}>
        <EditTreeSpecialEquipment onClose={() => handleCloseSpecialEquipment()} estimateId={props.estimate.id} treeId={editingEquipmentTreeId!}/>
      </Modal>
    </div>
  )
}

export default EstimateInfoSidebar;