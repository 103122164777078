import React, { useState, useEffect } from 'react';
import {Box, Button, Modal, TextField, Typography} from '@mui/material';
import {getEstimateTime, startNewEstimateTime, updateEstimateTime} from "../../pages/estimate/estimate-time.service";
import {useNavigate} from "react-router-dom";
import {enqueueSnackbar} from "notistack";
import {logError} from "../services/logger.service";
import {EstimateTimeDto} from "../dtos/estimate-time.dto";

interface TimerProps {
  estimateId: number;
}

const JobTimer: React.FC<TimerProps> = (props: TimerProps) => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [isRunning, setIsRunning] = useState(false);
  const [savedEstimateTime, setSavedEstimateTime] = useState<EstimateTimeDto | null>(null);
  const [currentStartTime, setCurrentStartTime] = useState<Date | null>(null);
  const [totalElapsedSeconds, setTotalElapsedSeconds] = useState<number>(0);
  const [editorOpen, setEditorOpen] = useState(false);
  const [timeString, setTimeString] = useState('');

  useEffect(() => {
    getEstimateTime(navigate, props.estimateId).then((estimateTime) => {
      if(estimateTime) {
        setSavedEstimateTime(estimateTime);
        setIsRunning(estimateTime.isRunning);
        if(estimateTime.isRunning) {
          setCurrentStartTime(new Date());
        }
        setTotalElapsedSeconds(estimateTime.elapsedSeconds);
      }
    })
      .catch(e => {
        enqueueSnackbar('Failed to load estimate time', {variant: 'error'});
        logError(`Failed to load estimate time: ${e}`)
      })
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;

    if (isRunning && currentStartTime) {
      interval = setInterval(() => {
        const elapsedMs = new Date().getTime() - currentStartTime.getTime();
        const elapsedSeconds = Math.floor(elapsedMs / 1000);
        setTotalElapsedSeconds((savedEstimateTime?.elapsedSeconds ?? 0) + elapsedSeconds);
      }, 1000);
    } else {
      if(interval) {
        clearInterval(interval);
      }
    }

    return () => {
      interval && clearInterval(interval);
    };
  }, [isRunning]);

  const startTimer = async () => {
    if (!isRunning) {
      const promise = savedEstimateTime
        ? updateEstimateTime(navigate, props.estimateId, savedEstimateTime.id, {isStart: true})
        : startNewEstimateTime(navigate, props.estimateId, {type: 'job_length'});
      await promise
        .then((response) => {
          setSavedEstimateTime(response);
        })
        .catch(e => {
          enqueueSnackbar('Failed to start timer', {variant: 'error', autoHideDuration: 5000});
          logError(`Failed to start timer: ${e}`)
        });

      const start = new Date();
      setCurrentStartTime(start);
      setIsRunning(true);
    }
  };

  const stopTimer = async () => {
    if(savedEstimateTime != null) {
      await updateEstimateTime(navigate, props.estimateId, savedEstimateTime.id, {isStart: false})
        .then((response) => {
          setSavedEstimateTime(response);
        })
        .catch(e => {
          enqueueSnackbar('Failed to start timer', {variant: 'error', autoHideDuration: 5000});
          logError(`Failed to start timer: ${e}`)
        });
    }
    setIsRunning(false);
  };

  const formatElapsedTime = () => {
    const seconds = Math.floor(totalElapsedSeconds % 60);
    const minutes = Math.floor((totalElapsedSeconds / 60) % 60);
    const hours = Math.floor((totalElapsedSeconds / (60 * 60)) % 24);

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  const handleEditClick = () => {
    setIsRunning(false);
    setTimeString(formatElapsedTime().split(':').slice(0, 2).join(':'));
    setEditorOpen(true);
  }

  const handleEditorClose = () => {
    setEditorOpen(false);
  }

  const handleTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTimeString(event.target.value);
  };

  const handleTimeSave = () => {
    const splitTime = timeString.split(':');
    if(splitTime.length !== 2) {
      enqueueSnackbar('Invalid time format', {variant: 'error', autoHideDuration: 5000});
      return;
    }
    const hours = parseInt(timeString.split(':')[0]);
    const minutes = parseInt(timeString.split(':')[1]);
    if(isNaN(hours) || isNaN(minutes)) {
      enqueueSnackbar('Invalid time format', {variant: 'error', autoHideDuration: 5000});
      return;
    }
    setTotalElapsedSeconds((hours * 60 * 60) + (minutes * 60));
    // Quick hack to make sure the time is saved before we try to update it
    const savedEstimatePromise = savedEstimateTime != null ? Promise.resolve(savedEstimateTime) : startNewEstimateTime(navigate, props.estimateId, {type: 'job_length'});
    savedEstimatePromise
      .then(estimateTime => updateEstimateTime(navigate, props.estimateId, estimateTime.id, {isStart: false, elapsedSeconds: (hours * 60 * 60) + (minutes * 60)}))
      .then((response) => {
        setSavedEstimateTime(response);
        handleEditorClose();
      })
      .catch(e => {
        logError('Failed to save time', {estimateId: props.estimateId}, e);
        enqueueSnackbar('Failed to save time', {variant: 'error', autoHideDuration: 5000});
      })
  }

  return (
    <div>
      <Modal open={editorOpen} onClose={handleEditorClose}>
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
          <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
            Enter Time Duration (HH:MM)
          </Typography>
          <TextField
            label="Time"
            value={timeString}
            onChange={handleTimeChange}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 1,
            }}
            sx={{ mb: 2 }}
            fullWidth
          />
          <Button variant="contained" onClick={handleTimeSave} fullWidth>
            Save
          </Button>
        </Box>
      </Modal>
      <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
        <Typography variant="h5">Job Timer</Typography>
        <Typography variant="h4">{formatElapsedTime()}</Typography>
        <div style={{display: "flex"}} >
          <Button variant="contained" color="primary" onClick={isRunning ? stopTimer : startTimer} style={{margin: '10px'}}>
            {isRunning ? 'Stop' : 'Start'}
          </Button>
          <Button variant="contained" color="primary" onClick={handleEditClick} style={{margin: '10px'}}>
            Edit
          </Button>
        </div>
      </div>
    </div>
  );
};

export default JobTimer;
