import {StoredImageDto} from "../dtos/stored-image.dto";
import {logInfo} from "./logger.service";

export const uploadImage = async (file: File): Promise<StoredImageDto> => {
  if(!file) throw new Error('No file provided')

  const formData = new FormData();
  formData.append('file', file);

  const token = localStorage.getItem('idToken');
  const image = await fetch(
    `${process.env.REACT_APP_ACCUSITE_API_BASE_URL}/api/v1/images`,
    {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(async response => {
      if(!response.ok) {
        throw new Error(`Failed to upload image: ${response.statusText}`);
      }
      return await response.json() as StoredImageDto
  });

  logInfo(`Image uploaded: ${image.id}`);
  return image;
};

export const updateImage = async (id: number, file: File): Promise<StoredImageDto> => {
  if(!file) throw new Error('No file provided')

  const formData = new FormData();
  formData.append('file', file);

  const token = localStorage.getItem('idToken');
  const image = await fetch(
    `${process.env.REACT_APP_ACCUSITE_API_BASE_URL}/api/v1/images/${id}`,
    {
      method: 'PATCH',
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(async response => {
      if(!response.ok) {
          throw new Error(`Failed to update image: ${response.statusText}`);
      }
      return await response.json() as StoredImageDto
  });

  logInfo(`Image updated: ${image.id}`);
  return image;
};

export const resetImage = async (id: number): Promise<StoredImageDto> => {
  const token = localStorage.getItem('idToken');
  const image = await fetch(
    `${process.env.REACT_APP_ACCUSITE_API_BASE_URL}/api/v1/images/${id}/reset`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(async response => {
      if(!response.ok) {
          throw new Error(`Failed to reset image: ${response.statusText}`);
      }
      return await response.json() as StoredImageDto
  });

  logInfo(`Image reset: ${image.id}`);
  return image;
};