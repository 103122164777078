import React, {useEffect, useRef, useState} from 'react';
import {
  Box, Button,
  Paper, Typography,
} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {EstimateDto, EstimateStatus, EstimateWithClientAndCostDto} from "../../shared/dtos/estimate.dto";
import {requireAuth} from "../../shared/services/auth.service";
import {enqueueSnackbar} from "notistack";
import { datadogLogs } from '@datadog/browser-logs'
import {logError, logInfo} from "../../shared/services/logger.service";
import {GridColDef, GridRowParams} from '@mui/x-data-grid';
import {useToolbar} from "../../components/ToolbarContext";
import {getInvoices} from "./invoice.service";
import {UrlFilterAwareDataGrid} from "../../shared/components/UrlFilterAwareDataGrid";
import {Filter} from "../appointment/AppointmentListPage";
import generatePDF from "react-to-pdf";
import html2canvas from 'html2canvas';
import {emailEstimateToCustomer, updateEstimate} from "../estimate/estimate.service";
import {emailReceiptToCustomer} from "../receipt/receipt.service";

const sentFilter: Filter<EstimateWithClientAndCostDto> = {
  displayName: 'Sent',
  urlParam: 'sent',
  filterFunction: (values: EstimateWithClientAndCostDto[]) => {
    return values.filter(estimate => estimate.status === EstimateStatus.InvoiceSent);
  }
}

const paidFilter: Filter<EstimateWithClientAndCostDto> = {
  displayName: 'Paid',
  urlParam: 'paid',
  filterFunction: (values: EstimateWithClientAndCostDto[]) => {
    return values.filter(estimate => estimate.status === EstimateStatus.InvoicePaid);
  }
}

const overdueFilter: Filter<EstimateWithClientAndCostDto> = {
  displayName: 'Overdue',
  urlParam: 'overdue',
  filterFunction: (values: EstimateWithClientAndCostDto[]) => {
    return values.filter(estimate => estimate.status === EstimateStatus.InvoiceOverdue);
  }
}

const receiptSentFilter: Filter<EstimateWithClientAndCostDto> = {
  displayName: 'Receipt Sent',
  urlParam: 'receiptSent',
  filterFunction: (values: EstimateWithClientAndCostDto[]) => {
    return values.filter(estimate => estimate.status === EstimateStatus.ReceiptSent);
  }
}

const showAllFilter: Filter<EstimateWithClientAndCostDto> = {
  displayName: 'All',
  urlParam: 'all',
  filterFunction: (values: EstimateWithClientAndCostDto[]) => values
}

const allFilters = [showAllFilter, sentFilter, paidFilter, overdueFilter, receiptSentFilter];

function InvoiceListPage() {
  const navigate = useNavigate();
  const toolbarContext = useToolbar();

  const [invoices, setInvoices] = useState<EstimateWithClientAndCostDto[]>([]);
  const [checkBoxSelectedInvoiceIds, setCheckBoxSelectedInvoiceIds] = useState<number[]>([]);

  const [invoicesAreLoading, setInvoicesAreLoading] = useState(false);
  const invoiceRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    toolbarContext.initToolbar('InvoiceListPage', 'Invoices');
    requireAuth(navigate)
      .then(() => {
        logInfo('User navigated to invoice list page');
        return fetchWorkOrders();
      })
      .catch(e => {
        logError('Error navigating to invoice list page', {}, e)
        enqueueSnackbar(`Error navigating to invoice list page: ${e}`, {variant: 'error', autoHideDuration: 5000});
      })
  }, []);

  async function fetchWorkOrders() {
    setInvoicesAreLoading(true);
    await getInvoices(navigate)
      .then(invoices => setInvoices(invoices))
      .catch(e => {
        logError('Error fetching invoices', {}, e)
        enqueueSnackbar(`Error fetching invoices: ${e}`, {variant: 'error', autoHideDuration: 5000});
      })
      .finally(() =>
      {
        setInvoicesAreLoading(false)
      });
  }

  const invoiceColumns: GridColDef[] = [
    { field: 'clientName', headerName: 'Client', width: 200 },
    { field: 'clientEmail', headerName: 'Client Email', width: 250 },
    { field: 'clientPhoneNumber', headerName: 'Client Phone #', width: 150 },
    { field: 'address', headerName: 'Address', width: 300 },
    { field: 'city', headerName: 'City', width: 150 },
    { field: 'state', headerName: 'State', width: 100 },
    { field: 'status', headerName: 'Status', width: 150 },
    { field: 'amount', headerName: 'Amount', width: 100 },
    { field: 'notes', headerName: 'Notes', width: 200 },
    { field: 'updatedAt', headerName: 'Updated At', width: 200 },
  ];

  const rowGenerator = (estimate: EstimateWithClientAndCostDto) => {
    return {
      id: estimate.id,
      clientName: estimate.clientName,
      clientEmail: estimate.email,
      clientPhoneNumber: estimate.phoneNumber,
      address: estimate.estimateAddress,
      city: estimate.estimateCity,
      state: estimate.estimateState,
      status: estimate.status,
      amount: `$${estimate.estimatedCost}`,
      notes: estimate.estimateNotes,
      updatedAt: estimate.updatedAt,
    }
  };

  const sendSelectedEnabled = () => checkBoxSelectedInvoiceIds.length > 0;

  const rowIsSelectable = (row: EstimateWithClientAndCostDto) => {
    return row.status === EstimateStatus.InvoicePaid;
  }

  const handleSelectedRowsChange = (selectedRowIds: number[]) => {
    setCheckBoxSelectedInvoiceIds(selectedRowIds);
  }

  const handleSendSelectedClick = async () => {
    const promises = checkBoxSelectedInvoiceIds.map(async id => {
      const matchingInvoice = invoices.find(e => e.id === id);
      if(matchingInvoice?.status === EstimateStatus.InvoicePaid) {
        await emailReceiptToCustomer(navigate, id);
      }
    })
    await Promise.allSettled(promises).then(() => fetchWorkOrders());
    enqueueSnackbar('Receipts sent to customers', {variant: 'success', autoHideDuration: 5000})
    setCheckBoxSelectedInvoiceIds([]);
  }

  const handleRowClick = (params: GridRowParams) => {
    navigate(`/invoices/${params.id}`)
  };

  return (
    <div style={{height: '100%'}}>
      <Paper sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: 3}}>
        <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBottom: 2, gap: 2}}>
          <Button variant="contained" disabled={!sendSelectedEnabled()} onClick={handleSendSelectedClick}>Send Selected Receipts</Button>
        </Box>
        <UrlFilterAwareDataGrid
          columns={invoiceColumns}
          rowBuilder={rowGenerator}
          data={invoices}
          loading={invoicesAreLoading}
          onRowClick={handleRowClick}
          allFilters={allFilters}
          defaultFilters={[showAllFilter]}
          showCheckboxes={true}
          onCheckboxSelectionChange={ids => handleSelectedRowsChange(ids as number[])}
          isRowCheckboxEnabled={rowIsSelectable}
        />
        <div id={'test-container'}></div>
        <div id={'test-clone-div'} ref={invoiceRef} style={{backgroundColor: 'red', display: 'none'}}>
          This is a div with some text on it
          <Typography>
            And some typography test
          </Typography>
          <div>
            And an image
          </div>
        </div>
      </Paper>
    </div>
  );
}

export default InvoiceListPage;
