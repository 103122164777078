import React, {useEffect, useState} from 'react';
import {
  Box,
  Button,
  Modal,
  Paper,
} from "@mui/material";
import {Form, useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {getApiKeys, requireAuth} from "../../shared/services/auth.service";
import {enqueueSnackbar} from "notistack";
import {logError} from "../../shared/services/logger.service";
import {GridColDef, GridRowParams, GridToolbar} from '@mui/x-data-grid';
import {useToolbar} from "../../components/ToolbarContext";
import {UrlFilterAwareDataGrid} from "../../shared/components/UrlFilterAwareDataGrid";
import {EquipmentDto} from "./dtos/equipment.dto";
import {getAllEquipment, getEquipment} from "./equipment.service";
import EquipmentDetailComponent from "./EquipmentDetailComponent";


const parseParamsEquipmentId = (paramsEquipmentId: string | null): number | 'NEW' | undefined => {
  if(!paramsEquipmentId) return undefined;
  if(paramsEquipmentId.toLowerCase() == "new") return 'NEW';
  const numericValue = parseInt(paramsEquipmentId)
  return isNaN(numericValue) ? undefined : numericValue;
}

function EquipmentListPage() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams()
  const toolbarContext = useToolbar();
  const initialEquipmentId = parseParamsEquipmentId(searchParams.get("equipmentId"))

  const [allEquipment, setAllEquipment] = useState<EquipmentDto[]>([]);
  const [selectedEquipmentId, setSelectedEquipmentId] = useState<number | 'NEW' | undefined>(initialEquipmentId);
  const [equipmentIsLoading, setEquipmentIsLoading] = useState(false);

  useEffect(() => {
    toolbarContext.initToolbar('EquipmentListPage', 'Equipment');
    requireAuth(navigate)
      .then(() => fetchAllEquipment())
      .catch(e => {
        logError('Error requiring auth', {}, e)
        enqueueSnackbar(`Error requiring auth: ${e}`, {variant: 'error', autoHideDuration: 5000});
      });
  }, []);

  async function fetchAllEquipment() {
    setEquipmentIsLoading(true);
    await getAllEquipment(navigate)
      .then(equipment => setAllEquipment(equipment))
      .catch(e => {
        logError('Error fetching equipment', {}, e)
        enqueueSnackbar(`Error fetching equipment: ${e}`, {variant: 'error', autoHideDuration: 5000});
      })
      .finally(() =>
      {
        setEquipmentIsLoading(false)
      });
  }

  const equipmentColumns: GridColDef[] = [
    { field: 'id', headerName: 'Id', width: 100 },
    { field: 'nickname', headerName: 'Name', width: 200 },
    { field: 'type', headerName: 'Type', width: 200 },
    { field: 'make', headerName: 'Make', width: 150 },
    { field: 'model', headerName: 'Model', width: 100 },
    { field: 'year', headerName: 'Year', width: 250 },
    { field: 'vin', headerName: 'Vin', width: 200 },
    { field: 'createdAt', headerName: 'Created At', width: 200 },
  ];

  const equipmentRowBuilder = (equipment: EquipmentDto) => {
    return {
      id: equipment.id,
      nickname: equipment.nickname,
      type: equipment.type,
      make: equipment.details["make"],
      model: equipment.details["model"],
      year: equipment.details["year"],
      vin: equipment.details["vin"],
      createdAt: equipment.createdAt
    }
  };

  const handleAddNewEquipmentClick = () => {
    setSelectedEquipmentId('NEW');
  }

  const handleRowClick = (params: GridRowParams) => {
    setSelectedEquipmentId(params.id as number);
  };

  const handleCloseEquipmentDetail = () => {
    setSelectedEquipmentId(undefined);
    searchParams.delete("equipmentId")
    setSearchParams(searchParams);
  }

  const handleEquipmentDetailSaved = async () => {
    setSelectedEquipmentId(undefined);
    await fetchAllEquipment();
  }

  const handleEquipmentDeleted = async () => {
    setSelectedEquipmentId(undefined);
    await fetchAllEquipment();
  }

  return (
    <div style={{height: '100%'}}>
      <Paper sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', padding: 3}}>
        <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBottom: 2}}>
          <Button variant="contained" onClick={handleAddNewEquipmentClick}>Add New Equipment</Button>
        </Box>
        <UrlFilterAwareDataGrid
          columns={equipmentColumns}
          rowBuilder={equipmentRowBuilder}
          data={allEquipment}
          onRowClick={handleRowClick}
          loading={equipmentIsLoading}
        />
      </Paper>
      <Modal
        open={!!selectedEquipmentId}
        onClose={() => handleCloseEquipmentDetail()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <EquipmentDetailComponent
          equipmentId={selectedEquipmentId === 'NEW' ? undefined : selectedEquipmentId}
          onClose={() => handleCloseEquipmentDetail()}
          onSave={() => handleEquipmentDetailSaved()}
          onDelete={() => handleEquipmentDeleted()}
        />
      </Modal>
    </div>
  );
}

export default EquipmentListPage;
