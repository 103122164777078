import {Typography} from "@mui/material";

interface AccusiteMapIconProps {
  src: string;
  size: number;
  centerImage?: boolean;
  label?: string;
  scale?: number;
  id?: string;
}

const MINIMUM_SCALED_SIZE = 16;
const MapImageIcon = (props: AccusiteMapIconProps) => {
  let scaledSize = props.size * (props.scale ?? 1);
  scaledSize = Math.max(scaledSize, MINIMUM_SCALED_SIZE);
  return (
    <div style={{marginBottom: props.centerImage ? -scaledSize/2 : 0}} id={props.id}>
      <div style={{position: "absolute", zIndex: 100, height: scaledSize, width: scaledSize, display: "flex", justifyContent: "center", alignItems: "center"}}>
        <Typography variant={'h5'} fontStyle={{color: 'black'}}>{props.label}</Typography>
      </div>
      <img src={props.src} style={{width: scaledSize, height: scaledSize}} />
    </div>
  )
}

export default MapImageIcon;
