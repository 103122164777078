import {EstimateTreeOperationDto, TreeOperationType} from "../dtos/estimate-tree-operation.dto";
import {TreeOperationSchema} from "../../pages/estimate/estimate-tree.service";
import {InputDataType, InputSchema} from "./schema-based-inputs.service";
import {logError} from "./logger.service";

const getUnitString = (schema: InputSchema) => {
  if(schema.dataType === InputDataType.Percentage) {
    return '%';
  } else if(schema.unit) {
    return ` ${schema.unit}`;
  }
  return '';
}

export const formatTreeOperationOneLine = (operation: EstimateTreeOperationDto) => {
  const details = Object.keys(operation.details).map(key => {
    const schema = TreeOperationSchema[operation.operationType].find(schema => schema.name === key);
    if(!schema) {
      logError('Unknown operation detail when formatting tree operation', {operationType: operation.operationType, detailKey: key});
      return `${key}: ${operation.details[key]}`;
    }

    const displayName = schema?.displayName ?? key;
    const unitString = getUnitString(schema);
    return `${displayName}: ${operation.details[key]}${unitString}`
  }).join(', ');
  const detailString = Object.keys(operation.details).length > 0 ? ` (${details})` : '';
  return `${operation.operationType} ${detailString}`;
}