import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  FormControl,
  InputLabel, MenuItem, Select,
  SelectChangeEvent,
  Stack,
  TextField
} from "@mui/material";
import React, {ChangeEvent, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {logError} from "../../shared/services/logger.service";
import {enqueueSnackbar} from "notistack";
import {AddEquipmentDto, EquipmentDto, EquipmentType} from "./dtos/equipment.dto";
import {createEquipment, deleteEquipment, EquipmentTypeSchemas, getEquipment, updateEquipment} from "./equipment.service";
import {filterObject} from "../../shared/misc/ts-utils";

export interface EquipmentDetailProps {
  equipmentId?: number | null;
  onClose: () => void;
  onSave: (client: EquipmentDto) => void;
  onDelete: (equipmentId: number) => void;
}

const EquipmentDetailComponent: React.FC<EquipmentDetailProps> = React.forwardRef((props, ref) => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [savedEquipment, setSavedEquipment] = useState<EquipmentDto | null>(null);
  const [updatedEquipment, setUpdatedEquipment] = useState<Partial<AddEquipmentDto> | EquipmentDto>({});
  const [isFinishing, setIsFinishing] = useState<boolean>(false);

  useEffect(() => {
    if(props.equipmentId) {
      getEquipment(navigate, props.equipmentId)
        .then(equipment => {
          setSavedEquipment(equipment);
          setUpdatedEquipment(equipment);
        })
        .catch(e => {
          logError('Error getting equipment', {equipmentId: props.equipmentId}, e);
          enqueueSnackbar(`Error getting equipment: ${e}`, {variant: 'error', autoHideDuration: 5000});
        })
        .finally(() => setIsLoading(false));
    } else {
      setIsLoading(false);
      setUpdatedEquipment({type: 'Bucket Truck', details: {}});
    }
  }, []);

  const saveAndClose = async () => {
    if(!updatedEquipment.type || !updatedEquipment.details) {
      enqueueSnackbar('Equipment type and details required', {variant: 'error', autoHideDuration: 5000});
      return;
    }
    const schemas = EquipmentTypeSchemas[updatedEquipment.type];
    const requiredFields = schemas.filter(schema => schema.required).map(schema => schema.name);
    const emptyRequiredFields = requiredFields.filter(field => !updatedEquipment.details![field]);
    if(emptyRequiredFields.length > 0) {
      enqueueSnackbar('Some required fields are empty', {variant: 'error', autoHideDuration: 5000});
      return;
    }

    const allSchemaFields = schemas.map(schema => schema.name);
    const filteredDetails = filterObject(updatedEquipment.details!, allSchemaFields);
    const equipmentData = {...updatedEquipment, details: filteredDetails};

    setIsFinishing(true);
    try {
      const returnedEquipment = (savedEquipment as EquipmentDto)?.id
        ? await updateEquipment(navigate, (savedEquipment as EquipmentDto).id, equipmentData)
        : await createEquipment(navigate, equipmentData as AddEquipmentDto);
      props.onSave(returnedEquipment);
      return returnedEquipment;
    }
    catch {
      logError('Failed to create equipment', {equipment: updatedEquipment});
      enqueueSnackbar('Failed to create equipment', {variant: 'error', autoHideDuration: 5000});
    }
    finally {
      setIsFinishing(false);
      props.onClose();
    }
  }

  const generateEquipmentTypes = () => {
    const allTypes = Object.keys(EquipmentTypeSchemas)
    return allTypes.map(type => {
      return (<MenuItem value={type} key={type}>{type}</MenuItem>)
    });
  }

  const generateEquipmentDetailFormControls = (type: EquipmentType) => {
    const schemas = EquipmentTypeSchemas[type];
    return schemas.map(schema => {
      return (
        <FormControl key={schema.name} fullWidth>
          <TextField
            label={schema.displayName ?? schema.name}
            variant="outlined"
            value={updatedEquipment.details![schema.name] ?? ''} // If we have an equipment type then we will at least have an empty details object
            onChange={(e) => {
              handleEquipmentDetailChange(schema.name, e.target.value)
            }}
            required={schema.required}
          />
        </FormControl>)
    })
  }

  const handleEquipmentNicknameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setUpdatedEquipment(equipment => ({...equipment!, nickname: event.target.value ?? undefined}));
  };

  const handleEquipmentTypeChange = (event: SelectChangeEvent) => {
    setUpdatedEquipment(equipment => ({...equipment, type: event.target.value as EquipmentType}));
  }

  const handleEquipmentDetailChange = (fieldName: string, value: string) => {
    setUpdatedEquipment(equipment => ({...equipment, details: {...equipment.details, [fieldName]: value}}));
  }

  const handleSaveClick = async () => {
    return saveAndClose();
  }

  const handleDeleteEquipmentClick = async () => {
    if(!savedEquipment?.id) {
      props.onClose();
    }
    setIsFinishing(true);
    deleteEquipment(navigate, savedEquipment!.id)
      .then(() => {
        props.onDelete(savedEquipment!.id);
        props.onClose();
      })
      .catch(e => {
        logError('Failed to delete equipment', {clientId: savedEquipment?.id}, e);
        enqueueSnackbar('Failed to delete equipment', {variant: 'error', autoHideDuration: 5000});
      })
      .finally(() => setIsFinishing(false));
  }

  return (
    isLoading ? <CircularProgress/> :
      <Card
        sx={{ maxWidth: 600, maxHeight: '90vh', mx: 'auto', my: 4, overflowY: 'auto' }}>
        <CardContent>
          <CardHeader title={savedEquipment?.id ? 'Equipment Details' : 'Add Equipment'}/>
          <Stack spacing={2}>
            <FormControl fullWidth>
              <InputLabel>Type</InputLabel>
              <Select
                labelId="equipment-type"
                label="Type"
                id="equipment-type-select"
                value={updatedEquipment.type}
                onChange={handleEquipmentTypeChange}
              >
                {generateEquipmentTypes()}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <TextField
                label="Nickname"
                variant="outlined"
                value={updatedEquipment.nickname ?? ''}
                onChange={handleEquipmentNicknameChange}
              />
            </FormControl>
            {updatedEquipment.type ? generateEquipmentDetailFormControls(updatedEquipment.type as EquipmentType) : <></>}
            <Button onClick={handleSaveClick} variant="contained" component="label">
              {isFinishing ? <CircularProgress/> : 'Save'}
            </Button>
            {savedEquipment?.id &&
            <Button onClick={handleDeleteEquipmentClick} variant="contained" color="error" style={{color: 'black'}} component="label">
              {isFinishing ? <CircularProgress/> : 'Delete'}
            </Button>}
          </Stack>
        </CardContent>
      </Card>
  )
});

export default EquipmentDetailComponent;