import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  Stack,
  TextField
} from "@mui/material";
import React, {ChangeEvent, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {logError} from "../../shared/services/logger.service";
import {enqueueSnackbar} from "notistack";
import {createClient, deleteClient, getClient, updateClient} from "./clients.service";
import {AddClientDto, ClientDto, UpdateClientDto} from "./dtos/client.dto";
import {Loader} from "@googlemaps/js-api-loader";
import {getLocationFromAddress} from "../../shared/misc/location-helpers";

export interface ClientDetailProps {
  onClose: () => void;
  onSave: (client: ClientDto) => void;
  onDelete: (clientId: number) => void;
  clientId?: number | null;
}

const MAX_ADDRESS_SUGGESTIONS = 10;

const ClientDetailComponent: React.FC<ClientDetailProps> = React.forwardRef((props, ref) => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [autocompleteService, setAutocompleteService] = useState<google.maps.places.AutocompleteService | null>(null);
  const [addressSuggestions, setAddressSuggestions] = useState<string[]>([]);
  const [savedClient, setSavedClient] = useState<ClientDto | null>(null);
  const [updatedClient, setUpdatedClient] = useState<Partial<AddClientDto> | ClientDto>({});
  const [isFinishing, setIsFinishing] = useState<boolean>(false);

  useEffect(() => {
    const googleMapsLoader = new Loader({
      apiKey: "AIzaSyBLw50vYSozTEviDIpu94K1KuwM4X_hDUM",
      version: "beta",
    });
    googleMapsLoader.importLibrary("places")
      .then(places => {
        setAutocompleteService(new (places as google.maps.PlacesLibrary).AutocompleteService());
        if(props.clientId) {
          getClient(navigate, props.clientId)
            .then(client => {
              setSavedClient(client);
              setUpdatedClient(client);
            })
            .catch(e => {
              logError('Error getting client', {clientId: props.clientId}, e);
              enqueueSnackbar(`Error getting client: ${e}`, {variant: 'error', autoHideDuration: 5000});
            })
            .finally(() => setIsLoading(false));
        } else {
          setIsLoading(false);
        }
      })
      .catch(e => {
        logError('Error importing google places library', {}, e);
        enqueueSnackbar(`Error importing google places library: ${e}`, {variant: 'error', autoHideDuration: 5000});
      });

  }, []);

  useEffect(() => {
    if(!autocompleteService || !updatedClient?.address) return;

    autocompleteService.getPlacePredictions({
      input: updatedClient.address,
      componentRestrictions: {country: 'us'},
      locationBias: "IP_BIAS",
      region: 'US'
    })
      .then(response => {
        setAddressSuggestions(response.predictions.map(p => p.description).slice(0, MAX_ADDRESS_SUGGESTIONS));
      })
      .catch(e => {
        logError('Error getting address suggestions', {address: updatedClient.address}, e);
        enqueueSnackbar(`Error getting address suggestions: ${e}`, {variant: 'error', autoHideDuration: 5000});
      });
  }, [updatedClient?.address]);

  const saveAndClose = async () => {
    if(!updatedClient?.name || !updatedClient.address) {
      enqueueSnackbar('Name and address are required', {variant: 'error', autoHideDuration: 5000});
      return;
    }

    const location = await getLocationFromAddress(updatedClient.address).catch(e => {
      logError('Failed to get location from address', {address: updatedClient.address}, e);
      enqueueSnackbar('Failed to get location from address', {variant: 'error', autoHideDuration: 5000});
      return null;
    });
    if(!location) return;

    const newClient = {...updatedClient, ...location};
    setUpdatedClient(newClient);

    setIsFinishing(true);
    try {
      const returnedClient = (savedClient as ClientDto)?.id
        ? await updateClient(navigate, (savedClient as ClientDto).id, newClient)
        : await createClient(navigate, newClient as AddClientDto);
      props.onSave(returnedClient);
      return returnedClient;
    }
    catch {
      logError('Failed to create client', {newLead: newClient});
      enqueueSnackbar('Failed to create client', {variant: 'error', autoHideDuration: 5000});
    }
    finally {
      setIsFinishing(false);
      props.onClose();
    }
  }

  const capitalizeName = (name: string) => name.split(' ').map(n => n.charAt(0).toUpperCase() + n.slice(1)).join(' ');

  const formatPhoneNumber = (value: string) => {
    const cleaned = ('' + value).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
    if (match) {
      const part1 = match[1] ? `(${match[1]}` : '';
      const part2 = match[2] ? `) ${match[2]}` : '';
      const part3 = match[3] ? `-${match[3]}` : '';
      return `${part1}${part2}${part3}`;
    }
    return value;
  };

  const handleClientNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = capitalizeName(event.target.value);
    setUpdatedClient(client => ({...client!, name: name}));
  };

  const handleClientEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setUpdatedClient(client => ({...client!, email: event.target.value ?? undefined}));
  };

  const handleClientPhoneChange = (event: ChangeEvent<HTMLInputElement>) => {
    const phoneNumber = formatPhoneNumber(event.target.value);
    setUpdatedClient(client => ({...client!, phoneNumber: phoneNumber}));
  };

  const handleClientAddressChange = (address: string) => {
    setUpdatedClient(client => ({...client!, address: address ?? undefined}));
  };

  const handleSaveClick = async () => {
    saveAndClose();
  }

  const handleDeleteClientClick = async () => {
    if(!savedClient?.id) {
      props.onClose();
    }
    setIsFinishing(true);
    deleteClient(navigate, savedClient!.id)
      .then(() => {
        props.onDelete(savedClient!.id);
        props.onClose();
      })
      .catch(e => {
        logError('Failed to delete client', {clientId: savedClient?.id}, e);
        enqueueSnackbar('Failed to delete client, does it have any associated appointments or estimates?', {variant: 'error', autoHideDuration: 5000});
      })
      .finally(() => setIsFinishing(false));
  }

  return (
    isLoading ? <CircularProgress/> :
      <Card
        sx={{ maxWidth: 600, maxHeight: '90vh', mx: 'auto', my: 4, overflowY: 'auto' }}>
        <CardContent>
          <CardHeader title={savedClient?.id ? 'Client Details' : 'Add Client'}/>
          <Stack spacing={2}>
            <FormControl fullWidth>
              <TextField
                label="Name"
                variant="outlined"
                value={updatedClient?.name ?? ''}
                onChange={handleClientNameChange}
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                label="Email"
                variant="outlined"
                value={updatedClient?.email ?? ''}
                onChange={handleClientEmailChange}
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                label="Phone Number"
                variant="outlined"
                value={updatedClient?.phoneNumber ?? ''}
                onChange={handleClientPhoneChange}
              />
            </FormControl>
            <FormControl fullWidth>
              <Autocomplete
                disablePortal
                freeSolo
                filterOptions={o => o}
                options={addressSuggestions}
                value={updatedClient?.address ?? ''}
                onInputChange={(_, a) => handleClientAddressChange(a)}
                renderInput={(params) => <TextField {...params} label="Address"/>}
              />
            </FormControl>
            <Button onClick={handleSaveClick} variant="contained" component="label">
              {isFinishing ? <CircularProgress/> : 'Save'}
            </Button>
            {savedClient?.id &&
            <Button onClick={handleDeleteClientClick} variant="contained" color="error" style={{color: 'black'}} component="label">
              {isFinishing ? <CircularProgress/> : 'Delete'}
            </Button>}
          </Stack>
        </CardContent>
      </Card>
  )
});

export default ClientDetailComponent;