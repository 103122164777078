import React from "react";
import {logError} from "../services/logger.service";
import {uploadImage} from "../services/image-service";
import {enqueueSnackbar} from "notistack";
import {ImagePreview} from "../../pages/estimate/EstimateTreeDetailComponent";

export const preventCacheImageSrc = (url: string) => {
  // Add query param to prevent caching, see: https://stackoverflow.com/questions/55158189/cross-origin-requests-ajax-requests-to-aws-s3-sometimes-result-in-cors-error
  return `${url}?t=${new Date().getTime()}`;
}

export const convertPngToJpeg = async (file: File): Promise<File> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    const reader = new FileReader();

    reader.onload = (e) => {
      if (e.target && e.target.result) {
        img.src = e.target.result as string;
      }
    };

    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;

      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.drawImage(img, 0, 0);
        canvas.toBlob(
          (blob) => {
            if (blob) {
              const jpegFile = new File([blob], file.name.replace(/\.png$/, '.jpg'), {
                type: 'image/jpeg',
                lastModified: Date.now(),
              });
              resolve(jpegFile);
            } else {
              reject(new Error('Conversion to JPEG failed'));
            }
          },
          'image/jpeg',
          .8 // Quality setting for JPEG (0.0 - 1.0)
        );
      } else {
        reject(new Error('Could not get canvas context'));
      }
    };

    img.onerror = (e) => {
      reject(new Error('Error loading image for conversion'));
    };

    reader.readAsDataURL(file);
  });
};

export const uploadImagesFromEvent = async (
  event: React.ChangeEvent<HTMLInputElement>,
  countImagesLoadingSetter: (count: number) => void,
  onUploadComplete: (previews: ImagePreview[]) => void
) => {
  if (event.target.files) {
    const uploadImagePromises = Array.from(event.target.files).map(async (file) => {
      let imageFile = file;
      if (file.type === 'image/png') {
        const convertToJpeg = window.confirm('You uploaded a PNG image. Would you like to convert it to JPEG for a faster upload?');
        if (convertToJpeg) {
          try {
            imageFile = await convertPngToJpeg(file);
          } catch (e) {
            logError('Error converting PNG to JPEG, using original PNG', {e});
            imageFile = file;
          }
        }
      }
      const result = await uploadImage(imageFile);
      return {
        id: result.id,
        s3Url: result.imageUrl,
      };
    });

    countImagesLoadingSetter(uploadImagePromises.length);
    Promise.all(uploadImagePromises)
      .then((uploadedImages) => {
        onUploadComplete(uploadedImages);
      })
      .catch((e) => {
        logError('Error uploading images', {}, e);
        enqueueSnackbar(`Error uploading images: ${e}`, {
          variant: 'error',
          autoHideDuration: 5000,
        });
      })
      .finally(() => countImagesLoadingSetter(0));
  }
};