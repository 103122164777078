import React, {useEffect} from "react";
import {Box, Card, CardContent, Typography} from "@mui/material";
import {ExternalCompanyDto} from "../../shared/dtos/company.dto";
import {getCustomerFacingCompany} from "../../shared/services/company.service";
import {CenteredCircularSpinner} from "../../shared/components/CenteredCircularSpinner";
import {enqueueSnackbar} from "notistack";

export interface EstimateItemizedPageProps {
  companyId: number
}

const PaymentInformation = React.forwardRef((props: EstimateItemizedPageProps, ref) => {
  const [company, setCompany] = React.useState<ExternalCompanyDto | null>(null);

  useEffect(() => {
    getCustomerFacingCompany(props.companyId)
      .then(setCompany)
      .catch(e => {
        console.error('Error getting company', {companyId: props.companyId}, e);
        enqueueSnackbar(`Error getting company: ${e}`, {variant: 'error', autoHideDuration: 5000});
      })
  }, []);

  return (
    <Card sx={{ maxWidth: 600, maxHeight: '90vh', mx: 'auto', my: 4, overflowY: 'auto' }}>
      <CardContent>
        <Typography variant="h5" component="div" gutterBottom>
          Payment Information
        </Typography>
        {company == null ? <CenteredCircularSpinner/> :
        <>
          <Box mt={2}>
            <Typography variant="body1" component="div">
              <strong>Mailing Address:</strong>
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {company.billingStreetAddress1},<br />
              {company.billingStreetAddress2 ?? ''},<br />
              {company.billingCity}, {company.billingState} {company.billingPostalCode}
            </Typography>
          </Box>
          <Box mt={2}>
            <Typography variant="body1" component="div">
              <strong>Make Checks Payable To:</strong>
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {company.name}
            </Typography>
          </Box>
          <Box mt={2}>
            <Typography variant="body1" component="div">
              <strong>For Additional Questions, Please Contact Us:</strong>
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {company.phoneNumber}<br/>
              {company.email}
            </Typography>
          </Box>
        </>}
      </CardContent>
    </Card>
  )
});

export default PaymentInformation;