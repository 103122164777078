import React, {useEffect, useRef} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import queryString from 'query-string';
import {getLoggedInUser} from "../../shared/services/auth.service";
import {logError, logInfo, setLogContext} from "../../shared/services/logger.service";

const LoginCallbackPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const initialized = useRef(false)

  useEffect(() => {
    if(initialized.current) {
      return;
    } else {
      initialized.current = true;
    }
    const { code } = queryString.parse(location.search);
    if (!code) {
      navigate('/login');
      return;
    }

    const params = new URLSearchParams();
    params.append('client_id', '7u4nrbq69kilch6ahqu1ru41e5');
    params.append('grant_type', 'authorization_code');
    params.append('code', code.toString());
    params.append('scope', 'Email');
    params.append('redirect_uri', process.env.REACT_APP_LOGIN_CALLBACK_URL!);

    fetch('https://auth.savaquan.com/oauth2/token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: params.toString(),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Token exchange failed:');
        }
      })
      .then((data) => {
        const idToken = data.id_token;

        localStorage.setItem('idToken', idToken);
        const user = getLoggedInUser();
        setLogContext({user})
        logInfo('Login successful', {user});
        navigate('/'); // Redirect to home after processing
      })
      .catch((error) => {
        logError('Login Error:', {}, error);
      });
  }, [navigate]);

  return (
    <div>
      Processing your login...
    </div>
  );
};

export default LoginCallbackPage;